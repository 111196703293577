import { useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useMutation } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import { Button, Text } from "../../../components/MUI";
import { Box } from "@mui/system";
import classes from "./index.module.scss";
import { useTheme } from "@emotion/react";
import { useOutletContext } from "react-router-dom";
import errorToast from "../../../components/toasts/errorToast";
import successToast from "../../../components/toasts/successToast";

const ResendPassword = () => {
  const { user } = useContext(AuthContext);
  const theme = useTheme();
  const {
    setLink,
    isLoginVisible,
    setIsLoginVisible,
    isRegistrationLinkActive,
    setIsRegistrationLinkActive,
  } = useOutletContext();

  const { mutate, isLoading } = useMutation(
    (data) => api.post("/auth/confirmRegistration", data),
    {
      onSuccess: ({ data }) => {
        successToast(data.message);
      },
      onError: (error) => {
        errorToast(error.response.data.message);
      },
    }
  );

  const onClick = () => {
    const newUser = user || JSON.parse(localStorage.getItem("user"));
    newUser && mutate(newUser);
  };

  useEffect(() => {
    setLink("/registration");
    !isLoginVisible && setIsLoginVisible(true);
    !isRegistrationLinkActive && setIsRegistrationLinkActive(true);
  }, [
    isLoginVisible,
    isRegistrationLinkActive,
    setIsLoginVisible,
    setIsRegistrationLinkActive,
    setLink,
  ]);

  return (
    <Box className={classes.wrapper}>
      <Box>
        <Text variant="text-16" sx={{ color: theme.palette["dark-60"] }} mb={3}>
          You’re almost there!
        </Text>
        <Text variant="text-16" sx={{ color: theme.palette["dark-60"] }}>
          {`But before we can enrol you to Engenious University we need to verify
          that we got your email correctly. Please press on the “Verify email”
          button in the letter that we’ve just sent you to ${
            user?.email ||
            JSON.parse(localStorage.getItem("user"))?.email ||
            "Your email"
          }`}
        </Text>
      </Box>

      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        disabled={isLoading}
        fullWidth
        onClick={onClick}
      >
        {isLoading ? "Loading..." : "Resend confirmation link"}
      </Button>
    </Box>
  );
};

export default ResendPassword;
