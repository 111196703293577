import { Box } from "@mui/system";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import { ReactComponent as Facebook } from "../../../assets/images/facebook-auth.svg";
import { ReactComponent as Google } from "../../../assets/images/google-auth.svg";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useMutation } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import classes from "./index.module.scss";

export default function SocialAuth() {
  const { setAuthUser } = useContext(AuthContext);

  const { mutate } = useMutation(
    ({ data, provider }) => api.post(`/auth/${provider}`, data),
    {
      onSuccess: ({ data }) => {
        setAuthUser(data);
      },
      onError: (error) => {
        console.log(error.response.data.message);
      },
    }
  );
  return (
    <Box className={classes.wrapper}>
      <LoginSocialGoogle
        className={classes.google}
        client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
        isOnlyGetToken={true}
        scope="openid profile email"
        discoveryDocs="claims_supported"
        access_type="offline"
        onResolve={({ provider, data }) => {
          mutate({ data: { accessToken: data.access_token }, provider });
        }}
        onReject={(err) => {
          console.log(err);
        }}
      >
        <button className={classes.button}>
          <Google /> <span>Continue with Google</span>
        </button>
      </LoginSocialGoogle>
      <LoginSocialFacebook
      className={classes.facebook}
        appId={process.env.REACT_APP_FB_APP_ID || ""}
        fieldsProfile={"id"}
        isOnlyGetToken={true}
        onResolve={({ provider, data }) => {
          mutate({ data: { accessToken: data.accessToken }, provider });
        }}
        onReject={(err) => {
          console.log(err);
        }}
      >
        <button className={classes.button}>
          <Facebook />
        </button>{" "}
      </LoginSocialFacebook>
    </Box>
  );
}
