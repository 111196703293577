import mixpanel from "mixpanel-browser";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

const useMixpanel = () => {
  const { user } = useContext(AuthContext);

  const trackEvent = (eventName, properties) => {
    eventName &&
      mixpanel.track(`${eventName} ${properties.name || ""}`, {
        ...properties,
        user,
      });
  };

  const handleEventTrack = (e, entity = {}) => {
    e.stopPropagation();
    const { eventName, options } = entity;
    trackEvent(eventName, options || {});
  };
  return {
    trackEvent,
    handleEventTrack
  };
};

export default useMixpanel;
