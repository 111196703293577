import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Typography } from "@mui/material";

import { theme } from "./theme";
import { useTheme } from "@emotion/react";

export default function Title({ children, ...rest }) {
  const defaultTheme = useTheme();

  return (
    <ThemeProvider theme={theme(defaultTheme)}>
      <Typography {...rest}>
        {children}
      </Typography>
    </ThemeProvider>
  );
}
