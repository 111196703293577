import React, { useRef } from "react";
import Carousel from "./Carousel";
import { Box, Container, Typography } from "@mui/material";
import { ReactComponent as YouTubeWhite } from "../../../../assets/images/youtube-white.svg";
import classes from "./index.module.scss";
import CarouselNavigation from "../../../../components/Common/CarouselNavigation";
import { AccentButton } from "../../../../components/MUI/buttons/button";

export default function OurYouTubeVideos({ data }) {
  const splideRef = useRef(null);

  if (!data) {
    return null;
  }

  return (
    <Container maxWidth="lg" className={classes.container} id="free-resources">
      <Box className={classes.body} component={"section"}>
        <Box className={classes.header}>
          <Typography component={"h2"} variant={"h2"} className={classes.title}>
            🎬 Our YouTube videos
          </Typography>
          <Box className={classes.navigation}>
            <CarouselNavigation splideRef={splideRef} variant={"outlined"} />
          </Box>
        </Box>

        <Box className={classes.carousel}>
          <Carousel splideRef={splideRef} data={data} />
        </Box>
        <Box className={classes.actions}>
          <AccentButton
            variant="contained"
            component={"a"}
            href={process.env.REACT_APP_YOUTUBE_LINK}
            course
            startIcon={<YouTubeWhite />}
            fullWidth
            target="_blank"
          >
            Subscribe
          </AccentButton>
        </Box>
      </Box>
    </Container>
  );
}
