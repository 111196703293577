import { useContext, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import { Button, Text } from "../../../components/MUI";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Input } from "../../../components/MUI/inputs";
import { Box } from "@mui/system";
import { useTheme } from "@emotion/react";
import classes from "./index.module.scss";
import { AuthContext } from "../../../context/AuthContext";
import errorToast from "../../../components/toasts/errorToast";
import { EMAIL_REGEX } from "../../../constants/regex";

const schema = yup.object().shape({
  email: yup.string().matches(EMAIL_REGEX, 'Invalid email address').email().required(),
});

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const theme = useTheme();
  const { setLink, setIsNavigationVisible, setTitle } = useOutletContext();
  const { setUser, user } = useContext(AuthContext);

  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(
    (data) => api.post("/auth/forgotPassword", data),
    {
      onSuccess: () => {
        navigate("/resend-password");
      },
      onError: (error) => {
        errorToast(error.response.data.message);
      },
    }
  );

  const onSubmit = (data) => {
    localStorage.setItem("user", JSON.stringify(data));
    setUser(data);
    mutate(data);
  };

  useEffect(() => {
    setLink("/login");
    setIsNavigationVisible(false);
    setTitle("Forgot password");

    return () => {
      setLink(null);
      setIsNavigationVisible(true);
      setTitle(null);
    };
  });

  return (
    <Box className={classes.wrapper}>
      <Text variant="text-16" sx={{ color: theme.palette["dark-60"] }}>
        Enter your email and we will send you a password recovery link
      </Text>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Box>
        <Input
            label="Email"
            type="email"
            margin="normal"
            variant="outlined"
            fullWidth
            autoComplete="user-email"
            disabled={isLoading}
            defaultValue={user?.email || ""}
            InputLabelProps={{
              shrink: true,
              disableAnimation: true,
            }}
            placeholder="Enter your email"
            {...register("email")}
            error={!!errors.email}
            helperText={errors.email && errors.email.message}
          />
        </Box>

        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          type="submit"
          endIcon={<ChevronRightIcon />}
          disabled={isLoading}
          fullWidth
        >
          {isLoading ? "Loading..." : "Reset password"}
        </Button>
      </form>
    </Box>
  );
};

export default ForgotPassword;
