import React, { useEffect, useState } from "react";
import PathCard from "./PathCard";
import PathCoursesList from "./PathCoursesList";
import { Box } from "@mui/material";
import classes from "./index.module.scss";

export default function PathSlide({ slide }) {
  const [listHeight, setListHeight] = useState("0px");
  const [isCardVisible, setIsCardVisible] = useState(true);

  const listHeightHandle = () => {
    const card = document.getElementById("path-card");
    card && setListHeight(getComputedStyle(card).height);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.addEventListener("resize", listHeightHandle);
      listHeightHandle();
      clearTimeout(timeout);
    });

    return () => {
      window.removeEventListener("resize", listHeightHandle);
    };
  }, []);

  return (
    <Box className={classes.slide}>
      <Box className={classes.slide__wrapper}>
        <PathCard
          isCardVisible={isCardVisible}
          setIsCardVisible={setIsCardVisible}
          slide={slide || {}}
        />
        <PathCoursesList
          listHeight={listHeight}
          isCardVisible={isCardVisible}
          setIsCardVisible={setIsCardVisible}
          courses={slide?.courses || []}
        />
      </Box>
    </Box>
  );
}
