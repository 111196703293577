import { useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useMutation } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import { Button, Text } from "../../../components/MUI";
import { Box } from "@mui/system";
import classes from "./index.module.scss";
import { useTheme } from "@emotion/react";
import { useOutletContext } from "react-router-dom";
import errorToast from "../../../components/toasts/errorToast";
import successToast from "../../../components/toasts/successToast";

const ResendForgottenPassword = () => {
  const { user } = useContext(AuthContext);
  const theme = useTheme();
  const {
    setLink,
    setIsNavigationVisible,
    setTitle,
  } = useOutletContext();

  const { mutate, isLoading } = useMutation(
    (data) => api.post("/auth/forgotPassword", data),
    {
      onSuccess: ({ data }) => {
        successToast(data.message);
      },
      onError: (error) => {
        errorToast(error.response.data.message);
      },
    }
  );

  const onClick = () => {
    const newUser = user || JSON.parse(localStorage.getItem("user"));
    newUser && mutate(newUser);
  };

  useEffect(() => {
    setLink("/forgot-password");
    setIsNavigationVisible(false);
    setTitle("Forgot password");

    return () => {
      setLink(null);
      setIsNavigationVisible(true);
      setTitle(null);
    };
  });

  return (
    <Box className={classes.wrapper}>
      <Box>
        <Text variant="text-16" sx={{ color: theme.palette["dark-60"] }}>
          {`We have sent you a password recovery link to ${
            user?.email ||
            JSON.parse(localStorage.getItem("user"))?.email ||
            "Your email"
          }`}
        </Text>
      </Box>

      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        disabled={isLoading}
        fullWidth
        onClick={onClick}
      >
        {isLoading ? "Loading..." : "Resend link"}
      </Button>
    </Box>
  );
};

export default ResendForgottenPassword;
