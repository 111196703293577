import { Box, Typography } from "@mui/material";
import { AccentButton } from "../../../../../components/MUI/buttons/button";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../../../../context/AuthContext";
import { useContext } from "react";
import classes from "./index.module.scss";

export default function GetFullAccess({ isFree, price, discountedPrice }) {
  const { pathname } = useLocation();
  const { auth, setIsPayment } = useContext(AuthContext);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.content}>
        <Typography component={"h3"} className={classes.title}>
          🔒 Get full access
        </Typography>
        <Typography className={classes.text}>
          {isFree
            ? "You’re trying to open a lecture that is available only upon authentication on the platform"
            : "You’re trying to open a lecture that is available only after buying the course"}
        </Typography>
      </Box>
      <Box className={classes.actions}>
        {isFree ? (
          <AccentButton
            isBig={false}
            variant={"contained"}
            component={Link}
            to={"/registration"}
            onClick={() => {
              localStorage.setItem("redirectPath", pathname);
            }}
          >
            Sign Up
          </AccentButton>
        ) : auth ? (
          <AccentButton
            isBig={false}
            variant="contained"
            onClick={() => {
              localStorage.setItem("isPayment", true);
              setIsPayment(true);
            }}
          >
            Buy for ${discountedPrice ? discountedPrice : price}
          </AccentButton>
        ) : (
          <AccentButton
            isBig={false}
            variant="contained"
            component={Link}
            to={"/registration"}
            onClick={() => {
              localStorage.setItem("redirectPath", pathname);
              localStorage.setItem("isPayment", true);
              setIsPayment(true);
            }}
          >
            Buy for ${discountedPrice ? discountedPrice : price}
          </AccentButton>
        )}
      </Box>
    </Box>
  );
}
