import { useContext, useEffect } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../../context/AuthContext";
import { useMutation } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import { Button, Text } from "../../../components/MUI";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Input } from "../../../components/MUI/inputs";
import { Box } from "@mui/system";
import { useTheme } from "@emotion/react";
import classes from "./index.module.scss";
import errorToast from "../../../components/toasts/errorToast";
import { IconButton, InputAdornment } from "@mui/material";
import useShowPassword from "../../../hooks/useShowPassword";
import ShowPasswordIcon from "../../../components/Common/ShowPasswordIcon";
import { PASSWORD_REGEX } from "../../../constants/regex";

const schema = yup.object().shape({
  password: yup.string().required("Password is required").matches(
    PASSWORD_REGEX,
    "Your password should have minimum eight characters, at least one uppercase letter, one lowercase letter and one digit"
  ),
});

const CreatePassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const theme = useTheme();
  const { setAuthUser, setAccessToken } = useContext(AuthContext);
  const {
    setLink,
    setIsLoginVisible,
    isLoginVisible,
    isRegistrationLinkActive,
    setIsRegistrationLinkActive,
  } = useOutletContext();

  const { handleClickShowPassword, showPassword } = useShowPassword()

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { mutate, isLoading } = useMutation(
    (data) => api.post("/auth/register", data),
    {
      onSuccess: ({ data }) => {
        setAuthUser(data);
      },
      onError: (error) => {
        errorToast(error.response.data.message);
      },
    }
  );

  const onSubmit = (data) => {
    mutate(data);
  };

  useEffect(() => {
    setLink(null);
    isLoginVisible && setIsLoginVisible(false);
    isRegistrationLinkActive && setIsRegistrationLinkActive(false);
    
  }, [isLoginVisible, isRegistrationLinkActive, setIsLoginVisible, setIsRegistrationLinkActive, setLink]);

  useEffect(() => {
    searchParams.get("token")
      ? localStorage.setItem("accessToken", searchParams.get("token")) &&
        setAccessToken(searchParams.get("token"))
      : navigate("/registration");
  }, [searchParams, navigate, setAccessToken]);

  return (
    <Box className={classes.wrapper}>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Input
            label="Password"
            type={showPassword ? "text" : "password"}
            margin="normal"
            variant="outlined"
            fullWidth
            autoComplete="current-password"
            disabled={isLoading}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    <ShowPasswordIcon showPassword={showPassword} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
              disableAnimation: true,
            }}
            placeholder="Create password"
            {...register("password")}
            error={!!errors.password}
            helperText={errors.password && errors.password.message}
          />
        </Box>

        <Text variant="text-16" sx={{ color: theme.palette["dark-60"] }}>
          By continuing forward you agree to the{" "}
          <Link className={classes.link} to="/">
            Privacy Policy
          </Link>{" "}
          and the{" "}
          <Link className={classes.link} to="/">
            Terms of Service
          </Link>
        </Text>

        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          type="submit"
          endIcon={<ChevronRightIcon />}
          disabled={isLoading}
          fullWidth
        >
          {isLoading ? "Loading..." : "Create password"}
        </Button>
      </form>
    </Box>
  );
};

export default CreatePassword;
