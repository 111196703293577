import { Box, Typography } from "@mui/material";
import Chip from "./Chip";
import { Link } from "react-router-dom";
import { formatDateTime } from "../../../../helpers/formatDateTime";
import { formatTimeMilliseconds } from "../../../../helpers/formatTime";

import classes from "./index.module.scss";

export default function CourseCard({ course }) {
  const startDate =
    course.type === "live" &&
    course.liveSchedule &&
    course.liveSchedule[0]?.from
      ? formatDateTime(course.liveSchedule[0].from)
      : null;

  return (
    <Box component={Link} className={classes.card} to={`/courses/${course.id}`}>
      <Box className={classes.image}>
        <img src={course.promoImageUrl} alt={course.title} />
      </Box>
      <Chip type={course.type} />
      <Typography component={"h3"} className={classes.title}>
        {course.title}
      </Typography>
      {course.type && (course.type === "recorded" || course.type === "live") ? (
        <Typography className={classes.duration}>
          {course.type === "recorded" &&
            `${
              course.lecturesCount
                ? course.lecturesCount +
                  (course.lecturesCount === 1 ? " lecture • " : " lectures • ")
                : null
            }
            ${
              course.duration ? formatTimeMilliseconds(course.duration) : null
            }`}
          {course.type === "live" &&
            `${
              startDate?.formattedDate ? startDate.formattedDate + " • " : null
            } ${
              course.lecturesCount
                ? course.lecturesCount +
                  (course.lecturesCount === 1 ? " lecture " : " lectures ")
                : null
            }
            ${
              course.duration
                ? "(" + formatTimeMilliseconds(course.duration) + ")"
                : null
            }`}
        </Typography>
      ) : null}
      <Typography className={classes.description}>
        {course.shortDescription}
      </Typography>
      <Typography className={classes.price}>
        Price:
        {Number(course.price) ? (
          <span>${course.price}</span>
        ) : (
          <span className={classes.price__free}>Free</span>
        )}
      </Typography>
    </Box>
  );
}
