import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { formatTimeMilliseconds } from "../../../../helpers/formatTime";
import { useNavigate } from "react-router-dom";
import AdditionalResourcesPopper from "../../../../components/MUI/poppers/AdditionalResourcesPopper";
import classes from "./index.module.scss";
export default function Content({ list, courseId, isPaid, resources }) {
  const navigate = useNavigate();

  return (
    <ul className={classes.list}>
      {list.map(({ title, duration, isFree, id }) => {
        return (
          <li
            key={id}
            className={`${classes.list__item} ${
              isFree || isPaid ? classes.list__item_free : ""
            }`}
            onClick={() => navigate(`/courses/${courseId}/lecture/${id}`)}
          >
            <div className={classes.list__icon}>
              <PlayArrowIcon
                sx={{
                  color:
                    isFree || isPaid ? "rgba(61, 64, 213, 0.2)" : "transparent",
                }}
              />
            </div>
            <div className={classes.list__text}>
              <span
                className={`${classes.list__title} ${
                  isFree || isPaid ? classes.list__title_free : ""
                }`}
              >
                {title}
              </span>
              <span className={classes.list__duration}>
                {formatTimeMilliseconds(duration)}
              </span>
            </div>
          </li>
        );
      })}
      {resources && resources.length ? (
        <li
          className={classes.list__item}
          key={resources.id}
          style={{ cursor: "default" }}
        >
          <AdditionalResourcesPopper resources={resources} isPaid={isPaid} />
        </li>
      ) : null}
    </ul>
  );
}
