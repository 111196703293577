import { Box, Container, Typography } from "@mui/material";
import Carousel from "./Carousel";
import { ReactComponent as Icon } from "./images/courses.svg";
import { useRef } from "react";
import classes from "./index.module.scss";
import CarouselNavigation from "../../../../components/Common/CarouselNavigation";

export default function Courses({ courses }) {
  const splideRef = useRef(null);

  if (!courses) {
    return null;
  }
  return (
    <Container maxWidth="lg" className={classes.container} id="courses">
      <Box className={classes.body} component={"section"}>
        <Box className={classes.header}>
          <Box className={classes.content}>
            <Typography component="h2" variant="h2" className={classes.title}>
              📚 Our courses
            </Typography>
            <Typography variant="h3" className={classes.text}>
              We truly believe in practical and interactive approach as the best
              learning experience. Each course has demo content available for{" "}
              <span>FREE</span>.
            </Typography>
          </Box>
          <Box className={classes.image}>
            <Icon/>
          </Box>
        </Box>

        <Box className={classes.carousel}>
          <Carousel splideRef={splideRef} data={courses} />
        </Box>
        <Box className={classes.navigation}>
          <CarouselNavigation splideRef={splideRef} variant={"outlined"}/>
        </Box>
      </Box>
    </Container>
  );
}
