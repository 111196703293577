import { Box, Typography } from "@mui/material";
import Card from "./Card";
import classes from "./index.module.scss";

export default function Instructors({ instructors }) {
  if (!instructors || ! instructors.length) {
    return null
  }
  return (
    <Box className={classes.instructors}>
      <Typography
        component="h2"
        variant="h2"
        className={classes.instructors__title}
      >
        Instructors
      </Typography>
      <Box className={classes.instructors__content}>
        {instructors.map((instructor) => {
          return <Card key={instructor.id} instructor={instructor} />;
        })}
      </Box>
    </Box>
  );
}
