import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import RelatedMaterials from "../RelatedMaterials";
import Recorded from "./Recorded";
import Live from "./Live";
import { useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import classes from "./index.module.scss";

export default function Details({ data, playlist }) {
  const { auth } = useContext(AuthContext);

  return (
    <Box className={classes.container}>
      <Box>
        <Link to={auth ? "/courses" : "/"} className={classes.link}>
          <ArrowBackIcon style={{ fontSize: 14 }} />
          <span>back to All Courses</span>
        </Link>
      </Box>
      {data?.type === "recorded" ? <Recorded data={data} /> : null}
      {data?.type === "live" ? <Live  data={data} /> : null}
      {playlist ? <RelatedMaterials playlist={playlist} /> : null}
    </Box>
  );
}
