import { Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { AccentButton, WhiteButton } from "../../../../MUI/buttons/button";
import { ReactComponent as Discord } from "../../../../../assets/images/discord-blue.svg";
import { scrollToSection } from "../../../../../helpers/scrollToSection";
import useButtonClick from "../../../../../hooks/useButtonClick";
import { HEADER_HEIGHT } from "../../../../../constants/layout";

import classes from "./index.module.scss";

export default function DesktopMenu({ navItems, auth }) {
  const { onStartForFreeClick } = useButtonClick();

  return (
    <Box
      component="nav"
      className={classes["navigation-desktop"]}
      sx={{
        gap: "1rem",
        alignItems: "center",
      }}
    >
      {navItems
        ? navItems.map((item) => (
            <WhiteButton
              isBig={false}
              key={item.id}
              variant="contained"
              component={"a"}
              href={`#${item.id}`}
              onClick={(e) => {
                scrollToSection(e, item.id, HEADER_HEIGHT);
              }}
            >
              {item.name}
            </WhiteButton>
          ))
        : null}
      <Box display="flex" gap="16px">
        <WhiteButton
          isBig={false}
          variant="outlined"
          component={"a"}
          startIcon={<Discord />}
          href={process.env.REACT_APP_DISCORD_LINK}
          target="_blank"
          rel="noreferrer"
        >
          Discord Community
        </WhiteButton>

        {auth ? (
          <AccentButton
            isBig={false}
            variant="contained"
            component={Link}
            to="/courses"
          >
            Student Platform
          </AccentButton>
        ) : (
          <AccentButton
            isBig={false}
            variant="contained"
            color="primary"
            onClick={onStartForFreeClick}
          >
            Sign Up / Login
          </AccentButton>
        )}
      </Box>
    </Box>
  );
}
