import { Box, Container, Typography } from "@mui/material";
import { ReactComponent as Icon4 } from "./images/basil_share-outline.svg";
import { ReactComponent as Icon2 } from "./images/carbon_connect.svg";
import { ReactComponent as Icon3 } from "./images/carbon_network-4.svg";
import { ReactComponent as Icon1 } from "./images/tabler_help.svg";
import { ReactComponent as DiscordLogo } from "./images/discord.svg";
import image from "./images/discord-image.png";
import { WhiteButton } from "../../../../components/MUI/buttons/button";
import { mixpanelLandingDiscordSupport } from "../../../../constants/mixpanel";
import useMixpanel from "../../../../hooks/useMixpanel";

import classes from "./index.module.scss";

export default function DiscordSupport() {
  const { handleEventTrack } = useMixpanel();
  const { discordAnchor } = mixpanelLandingDiscordSupport;

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Box className={classes.body} component={"section"}>
        <Box className={classes.content}>
          <Typography component={"h2"} variant={"h2"} className={classes.title}>
            👋 24/7 Discord Support
          </Typography>
          <Box component={"ul"} className={classes.list}>
            <Box component={"li"} className={classes.item}>
              <Icon1 className={classes.item__icon} />
              <Typography component={"h3"} className={classes.item__title}>
              Cooperating
              </Typography>
              <Typography className={classes.item__text}>
              We encourage a collaborative  environment where students can find answers together
              </Typography>
            </Box>
            <Box component={"li"} className={classes.item}>
              <Icon2 className={classes.item__icon} />
              <Typography component={"h3"} className={classes.item__title}>
              Connecting
              </Typography>
              <Typography className={classes.item__text}>
              Discord allows for real-time discussions and Q&A sessions with our instructors and experts
              </Typography>
            </Box>
            <Box component={"li"} className={classes.item}>
              <Icon3 className={classes.item__icon} />
              <Typography component={"h3"} className={classes.item__title}>
                Networking
              </Typography>
              <Typography className={classes.item__text}>
              Students can connect with fellow learners and industry professionals, opening doors to job opportunities
              </Typography>
            </Box>
            <Box component={"li"} className={classes.item}>
              <Icon4 className={classes.item__icon} />
              <Typography component={"h3"} className={classes.item__title}>
                Sharing
              </Typography>
              <Typography className={classes.item__text}>
              Valuable resources and latest advancements in the industry
              </Typography>
            </Box>
          </Box>
          <Box className={classes.actions}>
            <WhiteButton
              startIcon={<DiscordLogo />}
              variant={"contained"}
              component={"a"}
              href={process.env.REACT_APP_DISCORD_LINK}
              target="_blank"
              fullWidth
              onClick={(e) => {
                handleEventTrack(e, discordAnchor);
              }}
            >
              Join our Discord
            </WhiteButton>
          </Box>
        </Box>
        <Box className={classes.image}>
          <Box className={classes.image__wrapper}>
            <img src={image} alt="24/7 Discord Support" width={548} />
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
