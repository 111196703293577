import classes from "./index.module.scss";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { Accordion, Summary, Details } from "../Accordion/TasksAccordion";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import useAccordion from "../../../../hooks/useAccordion";

export default function Content({ list }) {
  const theme = useTheme();
  const { expanded, handleChange } = useAccordion();
  return (
    <Box className={classes.tasksList}>
      {list.map((item, index) => {
        return (
          <Accordion
            expanded={expanded}
            handleChange={handleChange}
            index={index}
            key={item.id}
          >
            <Summary
              index={index}
              expandIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M5.78027 6.96875L9.00002 10.1885L12.2198 6.96875L13.2803 8.02925L9.00002 12.3095L4.71977 8.02925L5.78027 6.96875Z"
                    fill="#3D40D5"
                  />
                </svg>
              }
            >
              <Typography
                component="h4"
                sx={{
                  color: theme.palette.accent,
                  fontSize: "0.875rem",
                  lineHeight: 1.21,
                }}
              >
                {item.title}
              </Typography>
            </Summary>
            <Details>
              <Box className={classes.tasksList__details}>
                {item.text ? (
                  <Typography
                    sx={{
                      color: "#292929",
                      fontSize: "0.75rem",
                      lineHeight: 1.3,
                    }}
                  >
                    {item.text}
                  </Typography>
                ) : null}

                {item.fileUrl ? (
                  <a
                    download
                    href={item.fileUrl}
                    style={{
                      textDecoration: "none",
                      display: "block",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#292929",
                        fontSize: "0.75rem",
                        lineHeight: 1.3,
                      }}
                    >
                      <InsertDriveFileOutlinedIcon
                        sx={{
                          color: theme.palette.accent,
                          fontSize: "inherit",
                          marginRight: 1,
                          marginBottom: -0.2,
                        }}
                      />
                      {item.fileTitle ? item.fileTitle : "Download file"}
                    </Typography>
                  </a>
                ) : null}
                {item.link ? (
                  <a
                    href={item.link}
                    style={{
                      textDecoration: "none",
                      display: "block",
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography
                      sx={{
                        color: "#292929",
                        fontSize: "0.75rem",
                        lineHeight: 1.3,
                      }}
                    >
                      <InsertLinkIcon
                        sx={{
                          color: theme.palette.accent,
                          fontSize: "inherit",
                          marginRight: 1,
                          marginBottom: -0.3,
                        }}
                      />
                      {item.linkTitle ? item.linkTitle : "Visit website"}
                    </Typography>
                  </a>
                ) : null}
              </Box>
            </Details>
          </Accordion>
        );
      })}
    </Box>
  );
}
