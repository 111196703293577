import { Input } from "../../MUI/inputs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./schema";
import { AccentButton } from "../../MUI/buttons/button";
import { api } from "../../../instances/axiosInstances";
import Box from "@mui/material/Box";
import errorToast from "../../toasts/errorToast";

import classes from "./index.module.scss";

export default function CouponForm({courseId}) {
    const queryClient = useQueryClient();

    const {
      trigger,
      register,
      handleSubmit,
      getValues,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schema),
    });

    const { mutate, isLoading } = useMutation(
        (data) => api.post("/coupons/activate", data),
        {
          onSuccess: async({ data }) => {
            // successToast(data.emails.message);
            await queryClient.invalidateQueries({
              queryKey: ["course", "registered", courseId.toString()],
              refetchType: "all",
              exact: true,
            });
          },
          onError: (error) => {
            errorToast(error.response.data.message);
          },
        }
      );

      const onSubmit = (data) => {
        mutate(data)
      };
  return (
    <Box
      component={"form"}
      className={classes.form}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Input
        label="Coupon"
        type="text"
        margin="normal"
        variant="outlined"
        fullWidth
        autoComplete="new-password"
        InputLabelProps={{
          shrink: true,
          disableAnimation: true,
        }}
        placeholder="Code"
        {...register("code", {
          onChange: () => trigger(["code"]),
        })}
        error={!!errors.code}
        helperText={errors.code && errors.code.message}
      />
      <AccentButton
        isBig={false}
        type="submit"
        variant="contained"
        disabled={!getValues("code") || isLoading}
      >
        Apply
      </AccentButton>
    </Box>
  );
}
