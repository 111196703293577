import { useContext, useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../../context/AuthContext";
import { useMutation } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import { Button } from "../../../components/MUI";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Input } from "../../../components/MUI/inputs";
import { Box } from "@mui/system";
import classes from "./index.module.scss";
import SocialAuth from "../../../components/Common/SocialAuth";
import { Divider } from "../../../components/MUI/dividers";
import errorToast from "../../../components/toasts/errorToast";
import { EMAIL_REGEX, PASSWORD_REGEX } from "../../../constants/regex";
import { IconButton, InputAdornment } from "@mui/material";
import useShowPassword from "../../../hooks/useShowPassword";
import ShowPasswordIcon from "../../../components/Common/ShowPasswordIcon";

const schema = yup.object().shape({
  email: yup
    .string()
    .matches(EMAIL_REGEX, "Invalid email address")
    .email()
    .required(),
    password: yup.string().required("Password is required").matches(
      PASSWORD_REGEX,
      "Your password should have minimum eight characters, at least one uppercase letter, one lowercase letter and one digit"
    ),
});

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    setLink,
    setIsLoginVisible,
    isLoginVisible,
    isRegistrationLinkActive,
    setIsRegistrationLinkActive,
  } = useOutletContext();

  const { setAuthUser } = useContext(AuthContext);

  const { handleClickShowPassword, showPassword } = useShowPassword();

  const { mutate, isLoading } = useMutation(
    (data) => api.post("/auth/login", data),
    {
      onSuccess: ({ data }) => {
        setAuthUser(data);
      },
      onError: (error) => {
        errorToast(error.response.data.message);
      },
    }
  );

  const onSubmit = (data) => {
    mutate(data);
  };

  useEffect(() => {
    setLink("/");
    !isLoginVisible && setIsLoginVisible(true);
    !isRegistrationLinkActive && setIsRegistrationLinkActive(true);
  }, [
    isLoginVisible,
    isRegistrationLinkActive,
    setIsLoginVisible,
    setIsRegistrationLinkActive,
    setLink,
  ]);

  return (
    <Box className={classes.wrapper}>
      <SocialAuth />
      <Divider>Or use email</Divider>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Input
            label="Email"
            type="email"
            margin="normal"
            variant="outlined"
            fullWidth
            autoComplete="user-email"
            disabled={isLoading}
            InputLabelProps={{
              shrink: true,
              disableAnimation: true,
            }}
            placeholder="Email"
            {...register("email")}
            error={!!errors.email}
            helperText={errors.email && errors.email.message}
          />
        </Box>
        <Box>
          <Input
            label="Password"
            margin="normal"
            variant="outlined"
            fullWidth
            autoComplete="current-password"
            disabled={isLoading}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    <ShowPasswordIcon showPassword={showPassword} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
              disableAnimation: true,
            }}
            placeholder="Your password"
            {...register("password")}
            error={!!errors.password}
            helperText={errors.password && errors.password.message}
          />
        </Box>

        <Link className={classes.link} to="/forgot-password">
          Forgot password
        </Link>

        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          type="submit"
          endIcon={<ChevronRightIcon />}
          disabled={isLoading}
          fullWidth
        >
          {isLoading ? "Loading..." : "Login"}
        </Button>
      </form>
    </Box>
  );
};

export default Login;
