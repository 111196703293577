import { Avatar, Box } from "@mui/material";
import user from "../../../../../assets/images/user.svg";
import { Rating, Text } from "../../../../../components/MUI";
import { useTheme } from "@emotion/react";
import classes from "./index.module.scss";

export default function Slide({ feedback }) {
  const theme = useTheme();

  const options = {
    stars: +feedback?.rating,
    readOnly: true,
    sx: {
      fontSize: "0.625rem",
    },
  };
  return (
    <Box className={classes.card}>
      <Box>
        <Avatar
          alt={feedback?.userFullName || "User name"}
          src={feedback?.userPhotoUrl || user}
          sx={{ width: 55, height: 55 }}
          variant="square"
          className={classes.avatar}
        />
      </Box>
      <Box>
        <Text
          variant="text-16-const"
          mb={0.5}
          sx={{ color: theme.palette.dark, fontWeight: 700 }}
        >
          {feedback?.userFullName || "User name"}
        </Text>

        {feedback.rating ? (
          <Rating options={options} spacing={0.5} mb={1.5} direction={"row"} />
        ) : null}
        <Box className={classes.wrapper}>
          {feedback.text ? (
            <Text
              variant="text-14"
              sx={{ color: theme.palette["dark-70"] }}
              className={classes.text}
            >
              {feedback.text}
            </Text>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}
