import { Box, Typography } from "@mui/material";
import { ReactComponent as Date } from "../../../../assets/images/uiw_date.svg";
import { ReactComponent as Time } from "../../../../assets/images/zondicons_time.svg";
import { formatDateTime } from "../../../../helpers/formatDateTime";
import ShowMore from "../../../../components/Common/ShowMore";
import classes from "./index.module.scss";

export default function Schedule({
  liveSchedule,
  courseTimeZone,
  className = "",
}) {
  if (!liveSchedule || !liveSchedule.length) {
    return null;
  }

  return (
    <Box className={`${classes.schedule} ${className}`}>
      <Typography
        component="h2"
        variant="h2"
        className={classes.schedule__title}
      >
        Course schedule{courseTimeZone ? ` (${courseTimeZone})` : null}
      </Typography>

      <ShowMore>
        <table className={classes.schedule__table}>
          <tbody>
            {liveSchedule.map((item) => (
              <tr key={item.id}>
                <td>
                  <Typography className={classes.schedule__text}>
                    <Date />
                    {formatDateTime(item.from).date}
                    <span>{formatDateTime(item.from).weekDay}</span>
                  </Typography>
                </td>
                <td>
                  <Typography className={classes.schedule__text}>
                    <Time />
                    {formatDateTime(item.from).time}
                    {" - "}
                    {formatDateTime(item.to).time}
                  </Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ShowMore>
    </Box>
  );
}
