import { Box, Typography } from "@mui/material";
import { formatTimeMilliseconds } from "../../../../helpers/formatTime";

import classes from "./index.module.scss";

export default function Header({ title, lectures, duration }) {
  return (
    <Box className={classes.header}>
      <Typography component="h3" variant="p" className={classes.header__title}>
        {title}
      </Typography>
      <Typography className={classes.header__count}>
        <span>
          {lectures} {lectures === 1 ? "lecture" : "lectures"}
        </span>
        {lectures ? ` • ` : null}
        <span>{formatTimeMilliseconds(duration)}</span>
      </Typography>
    </Box>
  );
}
