import { Box, Typography } from "@mui/material";
import ShowMore from "../../../../components/Common/ShowMore";
import classes from "./index.module.scss";

export default function Description({ description, className="" }) {
  if (!description || !description.length) {
    return null;
  }
  return (
    <Box className={`${classes.description} ${className}`}>
      <Typography
        component="h2"
        variant="h2"
        className={classes.description__title}
      >
        Description
      </Typography>

      <ShowMore maxHeight={150}>
        {description?.map((text, index, self) => (
          <Typography key={index} className={classes.description__text}>
            {text}
            {index + 1 !== self.length ? (
              <>
                <br />
                <br />
              </>
            ) : null}
          </Typography>
        ))}
      </ShowMore>
    </Box>
  );
}
