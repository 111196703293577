import { Box, Container, Typography } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useContext } from "react";
import { ModalContext } from "../../../../context/ModalContext";
import VideoModal from "../../../../components/Common/EmbedVideos/VideoWrapper";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactComponent as Icon1 } from "./images/ep_guide.svg";
import { ReactComponent as Icon2 } from "./images/fluent-mdl2_feedback.svg";
import { ReactComponent as Icon3 } from "./images/fluent-mdl2_insights.svg";
import { ReactComponent as Icon4 } from "./images/material-symbols_work-outline.svg";
import image from "./images/mentorship.png";
import {
  AccentButton,
  PlayButton,
} from "../../../../components/MUI/buttons/button";
import { mixpanelLandingIndividualMentorShip } from "../../../../constants/mixpanel";
import useMixpanel from "../../../../hooks/useMixpanel";

import classes from "./index.module.scss";

export default function IndividualMentorship() {
  const { handleModalOpen } = useContext(ModalContext);
  const { handleEventTrack } = useMixpanel();

  const { watchIntroductionVideo, enrollAnchor } =
    mixpanelLandingIndividualMentorShip;

  const showModal = () => {
    handleModalOpen(
      <VideoModal title={"⚡ Individual mentorship"} url={"https://vimeo.com/852109447"} />
    );
  };
  return (
    <Container className={classes.container}>
      <Box className={classes.body}>
        <Box className={classes.image}>
          <img src={image} alt="Crushing Interview" width={684} />
          <PlayButton
            aria-label="play"
            onClick={(e) => {
              handleEventTrack(e, watchIntroductionVideo);
              showModal();
            }}
          >
            <PlayArrowIcon />
          </PlayButton>
          <Typography component={"h3"} className={classes.image__title}>
            watch introduction video
          </Typography>
        </Box>
        <Box className={classes.content}>
          <Typography component={"h2"} variant={"h2"} className={classes.title}>
            ⚡ Individual mentorship
          </Typography>

          <Box className={classes.content__image}>
            <img src={image} alt="Crushing Interview" width={684} />
            <PlayButton
              aria-label="play"
              onClick={(e) => {
                handleEventTrack(e, watchIntroductionVideo);
                showModal();
              }}
            >
              <PlayArrowIcon />
            </PlayButton>
          </Box>

          <Box component={"ul"} className={classes.list}>
            <Box component={"li"} className={classes.item}>
              <Icon1 className={classes.item__icon} />
              <Typography component={"h3"} className={classes.item__title}>
                Personalized Guidance
              </Typography>
              <Typography className={classes.item__text}>
                Our seasoned mentors will provide assistance with the challenges
                you face
              </Typography>
            </Box>
            <Box component={"li"} className={classes.item}>
              <Icon2 className={classes.item__icon} />
              <Typography component={"h3"} className={classes.item__title}>
                Immediate Feedback
              </Typography>
              <Typography className={classes.item__text}>
                We are guiding you in Discord and are ready to help with all
                your questions
              </Typography>
            </Box>
            <Box component={"li"} className={classes.item}>
              <Icon3 className={classes.item__icon} />
              <Typography component={"h3"} className={classes.item__title}>
                Practical Industry Insights
              </Typography>
              <Typography className={classes.item__text}>
                Our mentors share practical tips, best practices and industry
                insights
              </Typography>
            </Box>
            <Box component={"li"} className={classes.item}>
              <Icon4 className={classes.item__icon} />
              <Typography component={"h3"} className={classes.item__title}>
                Career Development
              </Typography>
              <Typography className={classes.item__text}>
                We have a career-oriented approach to help you find the best
                path
              </Typography>
            </Box>
          </Box>
          <Box className={classes.actions}>
            <AccentButton
              className={classes.actions__enroll}
              endIcon={<ChevronRightIcon />}
              variant={"contained"}
              isBig={false}
              component={"a"}
              href={process.env.REACT_APP_CALENDLY_LINK}
              target="_blank"
              onClick={(e) => {
                handleEventTrack(e, enrollAnchor);
                showModal();
              }}
            >
              Enroll
            </AccentButton>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
