import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import image from "../../../../assets/images/course.png";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import Text from "../../Text";
import Rating from "../../Rating";
import { createTheme, ThemeProvider } from "@mui/material";
import { Button } from "../../buttons";
import Title from "../../Title";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { AuthContext } from "../../../../context/AuthContext";

import classes from "./index.module.scss";
import useButtonClick from "../../../../hooks/useButtonClick";

export default function PathCard({ isCardVisible, setIsCardVisible, slide }) {
  const { auth } = React.useContext(AuthContext);
  const { onSignUpClick } = useButtonClick();
  const theme = useTheme();

  const options = {
    stars: +slide?.rating,
    rating: +slide?.rating,
    votes: +slide?.reviewsCount,
    readOnly: true,
    fontSize: "1rem",
  };

  const finalTheme = createTheme(theme, {
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            backgroundColor: theme.palette.white,
            border: `none`,
            boxShadow: "none",
          },
        },
      },
      MuiCardMedia: {
        styleOverrides: {
          root: {
            padding: "0",
            borderRadius: "1rem",
            backgroundColor: "transperent",
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },

      MuiCardActions: {
        styleOverrides: {
          root: {
            padding: 0,
            flexDirection: "column",
            gap: "0.75rem",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={finalTheme}>
      <Card
        className={`${classes.card} ${
          isCardVisible ? classes.visible : classes.hidden
        }`}
        id="path-card"
      >
        <CardContent className={classes.content}>
          <Title
            className={classes.card__title}
            variant="title-20-const"
            sx={{ color: theme.palette.dark, fontWeight: 700 }}
          >
            📚 {slide?.title}
          </Title>
          {slide?.rating ? (
            <Rating
              className={classes.card__rating}
              options={options}
              spacing={0.5}
              direction={"row"}
            />
          ) : null}
          <CardMedia
            className={classes.card__media}
            component="img"
            alt="Course name"
            height="157"
            image={slide?.promoImageUrl || image}
          />
          <Text
            className={classes.card__text}
            variant="text-16-const"
            sx={{ color: theme.palette.dark, fontWeight: 400 }}
          >
            {slide?.shortDescription}
          </Text>
        </CardContent>
        <CardActions>
          {auth ? (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              endIcon={<ChevronRightIcon />}
              component={Link}
              to={`/courses/${slide?.courses?.at(0)?.id || ""}`}
            >
              Start learning path
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              endIcon={<ChevronRightIcon />}
              onClick={onSignUpClick}
            >
              Start learning path
            </Button>
          )}
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => setIsCardVisible(false)}
          >
            Show path program
          </Button>
        </CardActions>
      </Card>
    </ThemeProvider>
  );
}
