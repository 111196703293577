import { Box } from "@mui/material";
import { Title } from "../../MUI";
import CourseCard from "../../MUI/cards/CourseCard";
import { useTheme } from "@emotion/react";
import classes from "./index.module.scss";
import {  useDeferredValue } from "react";

export default function CoursesWrapper({ title, courses, isLoading }) {
  const theme = useTheme();
  const deferredCourses = useDeferredValue(courses);
  return (
    <Box className={classes.courses}>
      <Box className={classes.courses__wrapper}>
        {title ? (
          <Title
            className={classes.courses__title}
            variant="title-24"
            mb={3}
            sx={{ color: theme.palette.dark }}
          >
            {title}
          </Title>
        ) : null}
        <Box className={classes.courses__list}>
          {deferredCourses.map((course) => (
            <CourseCard key={course.id} course={course} />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
