import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Modal, styled } from "@mui/material";
import { useTheme } from "@emotion/react";
import SquarePaymentForm from "./SquarePayment";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import classes from "./index.module.scss";
import CouponForm from "./CouponForm";
import CouponDetails from "./CouponDetails";

const CustomModal = styled(Modal)(() => ({
  "&.MuiModal-root": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ".MuiBackdrop-root": {
    backgroundColor: "rgba(255, 255, 255, 0.57)",
  },
}));

export default function BuyCourseModal({ course }) {
  const { user, auth, isPayment, setIsPayment } = React.useContext(AuthContext);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const isLocalstoragePayment = localStorage.getItem("isPayment");

  const handleOpen = (e) => {
    e?.preventDefault();
    setOpen(true);
  };

  const handleClose = React.useCallback(
    (e) => {
      e?.preventDefault();
      localStorage.removeItem("isPayment");
      setIsPayment(false);
      setOpen(false);
    },
    [setIsPayment]
  );

  React.useEffect(() => {
    auth && (isPayment || isLocalstoragePayment) && !course?.isPaid
      ? handleOpen()
      : handleClose();
  }, [auth, course?.isPaid, handleClose, isPayment, isLocalstoragePayment]);

  if (!course || course?.isPaid) {
    return null;
  }

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-Payment-Modal"
      aria-describedby="modal-modal-description"
    >
      <Box
        className={classes.body}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Box className={classes.wrapper}>
          <Box className={classes.course}>
            <Box className={classes.course__data}>
              <Typography
                className={classes.course__title}
                variant="h3"
                component="h3"
                sx={{ color: theme.palette.dark, fontWeight: 700 }}
              >
                {course?.title}
              </Typography>
              <img
                className={classes.course__image}
                src={course?.promoImageUrl}
                alt={course?.title}
                width={303}
                height={157}
              />
              <Typography
                className={classes.course__title}
                sx={{ color: theme.palette.dark, fontWeight: 700 }}
              >
                ${course?.price}
              </Typography>
            </Box>

            <Box className={classes.coupon}>
              {course?.discountedPrice && course?.coupon ? (
                <CouponDetails
                  coupon={course?.coupon}
                  discountedPrice={course?.discountedPrice}
                  defaultPrice={course?.price}
                />
              ) : (
                <CouponForm courseId={course?.id} />
              )}
            </Box>

            <Box className={classes.course__price}>
              <Typography sx={{ color: theme.palette.dark, fontWeight: 700 }}>
                Order Total
              </Typography>
              <Typography
                className={classes.course__title}
                sx={{ color: theme.palette.dark, fontWeight: 700 }}
              >
                {course?.discountedPrice ? (
                  <>
                    <span className={classes.oldPrice}>${course?.price}</span> $
                    {course?.discountedPrice}
                  </>
                ) : (
                  `$${course?.price}`
                )}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.content}>
            <Typography
              className={classes.title}
              variant="h2"
              component="h2"
              sx={{ color: theme.palette.dark, fontWeight: 700 }}
            >
              Checkout
            </Typography>
            {user || localStorage.getItem("user") ? (
              <Box className={classes.user}>
                {user?.fullName ||
                JSON.parse(localStorage.getItem("user"))?.fullName ? (
                  <Typography
                    sx={{
                      color: theme.palette.dark,
                      fontWeight: 700,
                      lineHeight: 1.1875,
                      marginBottom: 0.5,
                    }}
                  >
                    {user?.fullName ||
                      JSON.parse(localStorage.getItem("user"))?.fullName}
                  </Typography>
                ) : null}
                {user?.email ||
                JSON.parse(localStorage.getItem("user"))?.email ? (
                  <Typography
                    sx={{
                      color: theme.palette["dark-60"],
                      fontWeight: 700,
                      lineHeight: 1.1875,
                    }}
                  >
                    {user?.email ||
                      JSON.parse(localStorage.getItem("user"))?.email}
                  </Typography>
                ) : null}
              </Box>
            ) : null}
            <Box className={classes.payment}>
              <Typography
                sx={{
                  color: theme.palette.dark,
                  fontWeight: 700,
                  lineHeight: 1.1875,
                  marginBottom: 3,
                }}
              >
                Card information
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.dark,
                  fontSize: "0.875rem",
                  lineHeight: 1.2,
                  marginBottom: 3,
                  padding: 1.75,
                  border: "1px solid #EAEFFE",
                  borderRadius: "12px",
                }}
              >
                <InfoOutlinedIcon
                  sx={{
                    marginRight: 1.5,
                    marginBottom: -0.6,
                    display: "inline-flex",
                  }}
                />
                Your security is important to us. We do not store your credit
                card information.
              </Typography>

              <Box
                sx={{
                  paddingLeft: 1.5,
                  paddingRight: 1.5,
                }}
              >
                <SquarePaymentForm
                  handleClose={handleClose}
                  courseId={course?.id}
                  price={
                    course?.discountedPrice
                      ? course?.discountedPrice
                      : course?.price
                  }
                />
              </Box>

              <Typography
                aria-labelledby="close modal"
                component="button"
                onClick={handleClose}
                sx={{
                  color: theme.palette["dark-60"],
                  lineHeight: 1.1875,
                  textDecoration: "underline",
                  backgroundColor: "transparent",
                  padding: 0,
                  border: "none",
                  cursor: "pointer",
                  marginTop: 1.5,
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                }}
              >
                Cancel
              </Typography>
            </Box>
            <Typography
              sx={{
                color: theme.palette.dark,
                fontWeight: 700,
                lineHeight: 1.1875,
                textAlign: "center",
              }}
            >
              by clicking Pay you agree to the{" "}
              <Typography
                component={Link}
                sx={{
                  color: "inherit",
                  font: "inherit",
                }}
                target={"_blank"}
                to="/terms-and-conditions"
              >
                Terms & Conditions
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
    </CustomModal>
  );
}
