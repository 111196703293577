import { Box, LinearProgress, Typography } from "@mui/material";
import classes from "./index.module.scss";
import { useTheme } from "@emotion/react";
import useProgress from "../../../pages/courses/OngoingLecture/Modules/hooks/useProgress";
import useFinishedCourseLecturesCount from "../../../pages/courses/OngoingLecture/Modules/hooks/useFinishedCourseLecturesCount";
import useCourseLecturesCount from "../../../pages/courses/OngoingLecture/Modules/hooks/useCourseLecturesCount";

export default function Progress({ course }) {
  const theme = useTheme();
  const { courseLecturesCount } = useCourseLecturesCount({ course });
  const { finishedCourseLecturesCount } = useFinishedCourseLecturesCount({
    course,
  });
  const { progress } = useProgress({
    course,
    courseLecturesCount,
    finishedCourseLecturesCount,
  });
  
  return (
    <Box className={classes.progress}>
      <Typography
        color={theme.palette.dark}
        className={classes.progress__title}
        component="h2"
      >
        {`Finished ${finishedCourseLecturesCount} / ${courseLecturesCount} lectures`}
      </Typography>

      <LinearProgress
        className={classes.progress__bar}
        variant="determinate"
        value={progress}
      />
    </Box>
  );
}
