import { useDeferredValue } from "react";
import Slide from "./Slide";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Intersection } from "@splidejs/splide-extension-intersection";
import "@splidejs/react-splide/css";
import classes from "./index.module.scss";

export default function Carousel({ splideRef, data }) {
  const deferredAlumniFeedbacks = useDeferredValue(data);

  return (
    <Splide
      className={classes.carousel}
      extensions={{ Intersection }}
      options={{
        arrows: false,
        pagination: false,
        rewind: true,
        gap: "1.5rem",
        type: "loop",
        autoplay: "pause",
        interval: 3000,
        mediaQuery: 'min',
        breakpoints: {
          561: {
            fixedWidth: "28.1rem",
          },
        },
        fixedWidth: "100%",
        omitEnd: true,
        intersection: {
          inView: {
            autoplay: true,
          },
          outView: {
            autoplay: false,
          },
        },
      }}
      ref={splideRef}
      aria-label="Our Alumni Feedbacks"
    >
      {deferredAlumniFeedbacks.map((slide) => (
        <SplideSlide key={slide.id}>
          <Slide slide={slide} />
        </SplideSlide>
      ))}
    </Splide>
  );
}
