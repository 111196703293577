import { Box, Typography } from "@mui/material";
import { Accordion, Summary, Details } from "../Accordion/ModuleAccordion";
import { Link, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@emotion/react";
import { Button } from "../../../../components/MUI";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import useAccordion from "../../../../hooks/useAccordion";
import Tasks from "../Tasks";
import ModuleDetails from "./ModuleDetails";
import ModuleSummary from "./ModuleSummary";
import useExpandedIndex from "./hooks/useExpandedIndex";
import { getLecturesDurationAndCount } from "./helpers/getLecturesDuration";
import useButtonClick from "../../../../hooks/useButtonClick";
import classes from "./index.module.scss";
import Progress from "../../../../components/Common/Progress/Progress";

export default function Modules({ course, setValue }) {
  const theme = useTheme();
  const { lectureId } = useParams();
  const { onLecturePaymentClick } = useButtonClick();

  const { expandedIndex } = useExpandedIndex({ lectureId, course });
  const { expanded, handleChange } = useAccordion(`panel${expandedIndex}`);

  return (
    <Box component={"aside"} className={classes.sidebar}>
      <Link to={`/courses/${course?.data.courses.id}`} className={classes.link}>
        <ArrowBackIcon style={{ fontSize: 14 }} />
        <span>back to Course Page</span>
      </Link>
      <Typography
        component="h1"
        variant="h1"
        color={theme.palette.dark}
        className={classes.title}
      >
        {course?.data.courses.title}
      </Typography>
      {
        course?.data.courses?.isPaid ? (
          <Progress course={course?.data.courses} />
        ) : null
        // (
        //   course?.data.courses?.price && (
        //     <Button
        //       variant="contained"
        //       color="primary"
        //       sx={{ width: "max-content" }}
        //       endIcon={<ChevronRightIcon />}
        //       onClick={() => {
        //         onLecturePaymentClick(course?.data.courses?.id, lectureId);
        //       }}
        //     >
        //       {`Buy for $${
        //         course?.data.courses?.discountedPrice
        //           ? course?.data.courses?.discountedPrice
        //           : course?.data.courses?.price
        //       }`}
        //     </Button>
        //   )
        // )
      }
      <Tasks tasks={course?.data.courses?.preparationTasks} />
      <Box className={classes.modules}>
        {course?.data.courses.modules.map((module) => {
          const { lecturesCount, lecturesDuration } =
            getLecturesDurationAndCount(module);
          return (
            <Accordion
              expanded={expanded}
              handleChange={handleChange}
              index={module.id}
              key={module.id}
            >
              <Summary index={module.id}>
                <ModuleSummary
                  lecturesCount={lecturesCount}
                  lecturesDuration={lecturesDuration}
                  title={module.title}
                />
              </Summary>
              <Details>
                <ModuleDetails
                  course={course}
                  module={module}
                  setValue={setValue}
                />
              </Details>
            </Accordion>
          );
        })}
      </Box>
    </Box>
  );
}
