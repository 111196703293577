import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { Box, Typography } from "@mui/material";
import poster from "../../../../assets/images/poster.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import NotFound from "../../../root/NotFound";
import Loader from "../../../../components/Common/Loader";
import { useEffect, useState } from "react";
import Player from "./Player";
import Poster from "./Poster";
import ShowMore from "../../../../components/Common/ShowMore";
import GetFullAccess from "./GetFullAccess";
import PreparationTasksImage from "./PreparationTasksImage";
import classes from "./index.module.scss";
import { AccentButton } from "../../../../components/MUI/buttons/button";
import Workplace from "./Workplace";
import ClosedLectionImage from "./ClosedLectionImage";
import { useQueryClient } from "@tanstack/react-query";
import {
  getCurrentLectureIsFree,
  getCurrentLectureTitle,
} from "../helpers/lections";

export default function Lecture() {
  const {
    isPaid,
    lecture,
    lectureLoading,
    pages,
    lectureId,
    courseId,
    errorStatus,
    price,
    title,
    modules,
    image,
    isCourseLoading,
    discountedPrice,
    promoImageUrl,
    isAuth
  } = useOutletContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [nextPage, setNextPage] = useState(true);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [isCurrentLectureComplete, setCurrentLectureComplete] =
    useState(undefined);
  const [currentLectureDuration, setCurrentLectureDuration] = useState(0);

  const getNextPageId = (pages, currentPageId) => {
    const currentIndex = pages.findIndex((item) => {
      return item.id === +currentPageId;
    });

    const nextPageIndex = currentIndex + 1;
    return pages.length > 1 && currentIndex !== -1
      ? setNextPage(pages[nextPageIndex])
      : setNextPage(undefined);
  };

  const getCurrentLectureCompleteStatus = (pages, currentPageId) => {
    const currentLecture = pages.find((item) => {
      return item.id === +currentPageId;
    });
    setCurrentLectureDuration(currentLecture?.duration);
    setCurrentLectureComplete(currentLecture?.isComplete);
  };

  useEffect(() => {
    getNextPageId(pages, lectureId);
    getCurrentLectureCompleteStatus(pages, lectureId);
  }, [lectureId, pages]);

  useEffect(() => {
    localStorage.removeItem("redirectPath");
  }, []);

  return (
    <Box className={classes.lecture}>
      {!isPaid ? (
        <GetFullAccess
          isFree={!Number(price)}
          price={price}
          discountedPrice={discountedPrice}
        />
      ) : null}
      <Typography className={classes.title}>
        {pathname.includes("preparation-tasks")
          ? "Preparation tasks"
          : lecture?.data.coursesLectures?.title ||
            getCurrentLectureTitle(pages, lectureId)}
      </Typography>
      {pathname.includes("preparation-tasks") ? (
        promoImageUrl ? (
          <PreparationTasksImage src={promoImageUrl} />
        ) : null
      ) : !lecture && !getCurrentLectureIsFree(pages, lectureId) ? (
        <ClosedLectionImage src={promoImageUrl} />
      ) : lecture?.data.coursesLectures?.videoLink ? (
        <Player
          setIsLoaderVisible={setIsLoaderVisible}
          courseId={courseId}
          nextPage={nextPage}
          videoLink={lecture?.data.coursesLectures?.videoLink}
          videoTitle={lecture?.data.coursesLectures?.title}
          isPaid={isPaid}
          poster={promoImageUrl}
          lectureId={lectureId}
          isComplete={isCurrentLectureComplete}
          duration={currentLectureDuration}
          isCourseLoading={isCourseLoading}
        />
      ) : (
        <Poster poster={promoImageUrl} />
      )}
      {lecture?.data.coursesLectures?.linkTitle &&
      lecture?.data.coursesLectures?.link ? (
        <Workplace
          linkTitle={lecture.data.coursesLectures.linkTitle}
          link={lecture.data.coursesLectures.link}
        />
      ) : null}
      {lecture?.data.coursesLectures?.text ? (
        <ShowMore maxHeight={150} overlayColor="white">
          <Typography className={classes.description}>
            {lecture?.data.coursesLectures?.text}
          </Typography>
        </ShowMore>
      ) : null}
      {!pathname.includes("preparation-tasks") && nextPage ? (
        <Box className={classes.actions}>
          <AccentButton
            isBig={false}
            variant="contained"
            component={Link}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/courses/${courseId}/lecture/${nextPage.id}`);
            }}
            endIcon={<ChevronRightIcon />}
          >
            Next lecture
          </AccentButton>
        </Box>
      ) : null}
    </Box>
  );
}
