import { Box, Container } from "@mui/material";
import Intro from "./Intro";
import Companies from "./Companies";

import classes from "./index.module.scss";

export default function Hero() {

  return (
    <Container maxWidth="lg">
      <Box className={classes.body} component="section">
        <Intro />
        <Companies/>
      </Box>
    </Container>
  );
}
