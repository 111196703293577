import React, { useContext, useState } from "react";
import { Box, List, ListItem } from "@mui/material";
import { ReactComponent as Discord } from "../../../../../assets/images/discord-blue.svg";
import { ReactComponent as YouTube } from "../../../../../assets/images/youtube-blue.svg";
import { ReactComponent as Facebook } from "../../../../../assets/images/facebook-blue.svg";
import { NavLink, useLocation } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { AuthContext } from "../../../../../context/AuthContext";
import { api } from "../../../../../instances/axiosInstances";
import { RedirectContext } from "../../../../../context/RedirectContext";
import { WhiteButton } from "../../../../MUI/buttons/button";
import Logo from "../../common/Logo";
import BurgerButton from "../../common/BurgerButton";
import { ReactComponent as UniversityLogo } from "../../../../../assets/images/logo.svg";
import classes from "./index.module.scss";

export default function Drawer({ handleDrawerToggle }) {
  const {pathname} = useLocation()
  const { removeAuthUser, accessToken } = useContext(AuthContext);
  const { removeCurrentCourse } = useContext(RedirectContext);
  const [error, setError] = useState("");
  const { mutate } = useMutation((data) => api.post("/auth/logout", data), {
    onSuccess: () => {
      localStorage.setItem("redirectPath", pathname);

      removeAuthUser();
      removeCurrentCourse();

      const timeout = setTimeout(() => {
        handleDrawerToggle();
        clearTimeout(timeout);
      });
    },
    onError: (error) => {
      setError(error.response.data.message);
    },
  });
  return (
    <Box
      flexGrow={1}
      sx={{
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: "2.5rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Logo handleDrawerToggle={handleDrawerToggle}>
          <UniversityLogo />
        </Logo>
        <BurgerButton handleDrawerToggle={handleDrawerToggle}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M6.4 19.0129L5 17.6129L10.6 12.0129L5 6.41294L6.4 5.01294L12 10.6129L17.6 5.01294L19 6.41294L13.4 12.0129L19 17.6129L17.6 19.0129L12 13.4129L6.4 19.0129Z"
              fill="#3D40D5"
            />
          </svg>
        </BurgerButton>
      </Box>
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 0,
          flexGrow: 1,
          overflowY: "auto",
          marginLeft: "-1rem",
          marginRight: "-1rem",
        }}
      >
        <ListItem
          disablePadding
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0,
          }}
        >
          <NavLink
            className={classes.link}
            to="/courses"
            onClick={handleDrawerToggle}
          >
            Courses
          </NavLink>
          <NavLink
            className={classes.link}
            to="/paths"
            onClick={handleDrawerToggle}
          >
            Paths
          </NavLink>
          <NavLink
            className={classes.link}
            to="/profile"
            onClick={handleDrawerToggle}
          >
            Profile
          </NavLink>
        </ListItem>
        <ListItem
          disablePadding
          sx={{
            marginTop: "auto",
            display: "flex",
            flexDirection: "column",
            gap: 1,
            paddingLeft: "1rem",
            paddingRight: "1rem",
          }}
        >
          <WhiteButton
            fullWidth
            isBig={false}
            variant="outlined"
            component={"a"}
            startIcon={<Discord />}
            href={process.env.REACT_APP_DISCORD_LINK}
            target="_blank"
            rel="noreferrer"
            onClick={handleDrawerToggle}
          >
            Discord Community
          </WhiteButton>
          <WhiteButton
            fullWidth
            isBig={false}
            variant="outlined"
            component={"a"}
            startIcon={<YouTube />}
            href={process.env.REACT_APP_YOUTUBE_LINK}
            target="_blank"
            rel="noreferrer"
            onClick={handleDrawerToggle}
          >
            Our Youtube
          </WhiteButton>
          <WhiteButton
            fullWidth
            isBig={false}
            variant="outlined"
            component={"a"}
            startIcon={<Facebook />}
            href={process.env.REACT_APP_FACEBOOK_LINK}
            target="_blank"
            rel="noreferrer"
            onClick={handleDrawerToggle}
          >
            Join us
          </WhiteButton>
          <WhiteButton
            fullWidth
            isBig={false}
            variant="contained"
            onClick={(e) => {
              e.preventDefault();
              mutate({
                token: accessToken || localStorage.getItem("accessToken"),
              });
            }}
          >
            Logout
          </WhiteButton>
        </ListItem>
      </List>
    </Box>
  );
}
