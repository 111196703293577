import * as yup from "yup";

const schema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  phoneNumber: yup
    .string()
    .min(10, "Phone Number is required")
    .required("Phone Number is required"),
  company: yup.string(),
  discordUsername: yup
    .string()
    .required("We need this to invite you to our learning space"),
  position: yup.string(),
  linkedinLink: yup.string(),
  yearsOfExperience: yup.string(),
  interestedTopics: yup.array().of(
    yup.object().shape({
      label: yup.string(),
      value: yup.string(),
    })
  ),
  country: yup.string().required("Country is required"),
  city: yup.string().required("City is required"),
  state: yup.string().when("country", {
    is: (country) => country === "USA",
    then: () => yup.string().required("State is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

export default schema;
