import { Avatar, Box, Typography } from "@mui/material";
import avatar from "../../../../../../assets/images/avatar.png";
import { Rating } from "../../../../../../components/MUI";
import classes from "./index.module.scss";
import ShowMore from "../../../../../../components/Common/ShowMore";

export default function Slide({ slide }) {
  const options = {
    stars: +slide?.rating,
    readOnly: true,
    sx: {
      fontSize: "0.625rem",
    },
  };
  return (
    <Box className={classes.slide}>
      <Box>
        <Avatar
          alt={slide?.userFullName || "User name"}
          src={slide?.userPhotoUrl || avatar}
          sx={{ width: "2.75rem", height: "2.75rem" }}
          variant="square"
          className={classes.avatar}
        />
      </Box>
      <Box>
        <Typography component={"h3"} className={classes.userName}>
          {slide?.userFullName || "User name"}
        </Typography>
        {slide.course?.title ? (
          <Typography className={classes.courseTitle}>
            {slide.course.title}
          </Typography>
        ) : null}
        {slide.rating ? (
          <Rating options={options} spacing={0.5} mb={1.5} direction={"row"} />
        ) : null}
        {slide.text ? (
          <ShowMore maxHeight={85} overlayColor={"white"}>
            <Typography className={classes.text}>{slide.text}</Typography>
          </ShowMore>
        ) : null}
      </Box>
    </Box>
  );
}
