import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import useAppSize from "../../../../../hooks/useAppSize";
import DesktopMenu from "./DesktopMenu";
import BurgerButton from "../../common/BurgerButton";
import Drawer from "./Drawer";
import { ReactComponent as Discord } from "../../../../../assets/images/discord.svg";
import { Box } from "@mui/material";
import { WhiteButton } from "../../../../MUI/buttons/button";
import MobileMenu from "../../common/MobileMenu";
import { ReactComponent as UniversityLogo } from "../../../../../assets/images/logo.svg";
import Logo from "../../common/Logo";

import classes from "./index.module.scss";

export default function Header({ auth }) {
  const { width: windowWidth } = useAppSize();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  React.useEffect(() => {
    windowWidth && windowWidth > 992 && mobileOpen && handleDrawerClose();
  }, [windowWidth, mobileOpen]);

  return (
    <>
      <AppBar
        component="header"
        className={classes.header}
        sx={{
          boxShadow: "none",
        }}
        id="header"
      >
        <Toolbar
          disableGutters
          className={`${classes.body} ${
            mobileOpen ? classes.body_transparent : classes.body_colored
          }`}
        >
          <Box className={classes.logo}>
            <Logo>
              <UniversityLogo />
            </Logo>
          </Box>

          <DesktopMenu auth={auth} />
          <WhiteButton
            variant="outlined"
            component={"a"}
            startIcon={<Discord />}
            href={process.env.REACT_APP_DISCORD_LINK}
            target="_blank"
            rel="noreferrer"
            className={classes["discord-mobile"]}
            isBig={false}
          >
            Discord
            <span className={classes["discord-mobile__text"]}>
              &nbsp;Community
            </span>
          </WhiteButton>

          <Box className={classes.burger}>
            <BurgerButton handleDrawerToggle={handleDrawerToggle}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M3 12H21M3 6H21M9 18H21"
                  stroke="#3D40D5"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </BurgerButton>
          </Box>
        </Toolbar>
      </AppBar>
      <MobileMenu
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      >
        <Drawer handleDrawerToggle={handleDrawerToggle} auth={auth} />
      </MobileMenu>
    </>
  );
}
