import { ReactComponent as Icon } from "../../../../../assets/images/wpf_online.svg";
import { Box, Typography } from "@mui/material";
import classes from "./index.module.scss";

export default function Chip() {
  return (
    <Box className={classes.chip}>
      <Icon className={classes.chip__icon}/>
      <Typography className={classes.chip__text}>Live Course</Typography>
    </Box>
  );
}
