import { Box, Typography } from "@mui/material";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import Card from "./Card";
import classes from "./index.module.scss";

export default function Reviews({ alumniFeedbacks, rating, className }) {
  if (!alumniFeedbacks || !alumniFeedbacks.length) {
    return null
  }
  return (
    <Box className={`${classes.alumniFeedbacks} ${className}`}>
      <Box className={classes.alumniFeedbacks__header}>
        <Typography
          component="h2"
          variant="h2"
          className={classes.alumniFeedbacks__title}
        >
          Reviews
        </Typography>
        <Box className={classes.rating}>
          <StarRoundedIcon sx={{ color: "var(--yellow)" }} />
          <Typography
            component="h2"
            variant="h2"
            className={classes.rating__title}
          >
            {rating}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.alumniFeedbacks__content}>
        {alumniFeedbacks.map((feedback) => {
          return <Card key={feedback.id} feedback={feedback} />;
        })}
      </Box>
    </Box>
  );
}
