import { Link, NavLink } from "react-router-dom";
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as DrawerToggleIcon } from "../../../assets/images/drawer-toggle-icon.svg";
import { ReactComponent as DrawerLogo } from "../../../assets/images/drawer-logo.svg";
import { ReactComponent as ArchiveIcon } from "../../../assets/images/archive.svg";
import { ReactComponent as ConversionIcon } from "../../../assets/images/conversion.svg";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import classes from "./index.module.scss";
import { Container } from "@mui/material";
import Header from "../components/platform/Header";
import Footer from "../components/Footer";

const drawerWidth = 264;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: theme.spacing(11),
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(3),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  "& .MuiDrawer-paper": {
    position: "relative",
    background: "linear-gradient(180deg, #3D40D5 0%, #0003AC 100%)",
    borderRadius: "24px",
    height: "100%",
  },
}));

export default function PlatformLayout({children}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    localStorage.removeItem("redirectPath")
  }, []);

  return (
    <Container disableGutters maxWidth={false} className={classes.container}>
      <Container maxWidth="lg" className={classes.container__inner}>
        <Box className={classes.wrapper}>
          <Drawer className={classes.drawer} variant="permanent" open={open}>
            <DrawerHeader
              sx={{ justifyContent: open ? "space-between" : "center" }}
            >
              <Link
                to="/"
                style={{
                  display: open ? "flex" : "none",
                  transform: "translateZ(1px)",
                }}
              >
                <DrawerLogo />
              </Link>
              <IconButton
                onClick={toggleDrawer}
                className={classes.toggle}
                sx={{ transform: `scaleX(${open ? 1 : -1})` }}
              >
                <DrawerToggleIcon />
              </IconButton>
            </DrawerHeader>
            <Divider
              sx={{
                margin: `0 1.5rem`,
                borderColor: theme.palette["white-10"],
                borderBottomWidth: "1px",
              }}
            />
            <List sx={{ padding: "1.5rem 0 2rem" }} className={classes.list}>
              <ListItem
                className={classes.list__item}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  className={classes.list__link}
                  component={NavLink}
                  to="/courses"
                  sx={{
                    padding: "0 1.5rem",
                    minHeight: 36,
                    justifyContent: open ? "initial" : "center",
                  }}
                >
                  <ListItemIcon
                    className={classes.list__icon}
                    sx={{
                      minWidth: 0,
                      mr: open ? 1.5 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <ArchiveIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.list__text}
                    primary="Courses"
                    sx={{
                      opacity: open ? 1 : 0,
                      display: open ? "flex" : "none",
                    }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem
                className={classes.list__item}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  className={classes.list__link}
                  component={NavLink}
                  to="/paths"
                  sx={{
                    padding: "0 1.5rem",
                    minHeight: 36,
                    justifyContent: open ? "initial" : "center",
                  }}
                >
                  <ListItemIcon
                    className={classes.list__icon}
                    sx={{
                      minWidth: 0,
                      mr: open ? 1.5 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <ConversionIcon />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.list__text}
                    primary="Paths"
                    sx={{
                      opacity: open ? 1 : 0,
                      display: open ? "flex" : "none",
                    }}
                  />
                </ListItemButton>
              </ListItem>
              
            </List>
            <Divider
              sx={{
                margin: `0 1.5rem`,
                borderColor: theme.palette["white-10"],
                borderBottomWidth: "1px",
              }}
              hidden={!open}
            />
          </Drawer>
          <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
            
            <Header />
            <Box
              component={"main"}
              className={classes.outlet}
              sx={{ flexGrow: 1 }}
            >{children}
              {/* <Outlet /> */}
            </Box>
            <Box className={classes.footer}>
              <Footer />
            </Box>
          </Box>
        </Box>
      </Container>
    </Container>
  );
}
