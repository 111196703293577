import { Box } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { CarouselButton } from "../../MUI/buttons/button";

import classes from "./index.module.scss";

export default function CarouselNavigation({ splideRef, variant }) {
  const handlePrev = () => {
    if (splideRef.current) {
      splideRef.current.go("-1");
    }
  };

  const handleNext = () => {
    if (splideRef.current) {
      splideRef.current.go("+1");
    }
  };
  return (
    <Box className={classes.navigation}>
      <CarouselButton variant={variant} onClick={handlePrev}>
        <ChevronLeftIcon />
      </CarouselButton>
      <CarouselButton variant={variant} onClick={handleNext}>
        <ChevronRightIcon />
      </CarouselButton>
    </Box>
  );
}
