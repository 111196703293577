import { Box, Container } from "@mui/material";
import { Title } from "../../../components/MUI";
import classes from "./index.module.scss";
import { useTheme } from "@emotion/react";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import Loader from "../../../components/Common/Loader";
import { PlatformLayout } from "../../../components/Layouts";

import CoursesWrapper from "../../../components/Common/CoursesWrapper";

export default function Courses() {
  const theme = useTheme();
  const { data, isLoading } = useQuery(["courses", "registered"], () =>
    api.get(process.env.REACT_APP_COURSES_REGISTERED_PATH)
  );

  return (
    <PlatformLayout>
      {isLoading ? <Loader /> : null}
      <Container disableGutters maxWidth={false} className={classes.container}>
        <Box className={classes.wrapper}>
          <Title
            variant="title-40-const"
            sx={{ color: theme.palette.dark }}
            component="h1"
          >
            Courses
          </Title>

          <Box
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            {data && !isLoading ? (
              <CoursesWrapper courses={data?.data.courses} />
            ) : null}
            {!data && !isLoading ? <Box>There is no courses yet</Box> : null}
          </Box>
        </Box>
      </Container>
    </PlatformLayout>
  );
}
