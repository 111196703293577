import * as React from "react";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import { api } from "../../../instances/axiosInstances";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import errorToast from "../../toasts/errorToast";
import successToast from "../../toasts/successToast";
import { QuestionnairePopupContext } from "../../../context/QuestionnairePopupContext";

const SquarePaymentForm = ({ handleClose, price, courseId }) => {
  const queryClient = useQueryClient();

  const user = queryClient.getQueryData(["user"])?.data;
  const { handleOpen: handleQuestionnairePopupOpen } = React.useContext(QuestionnairePopupContext);


  const { mutate, isLoading } = useMutation(
    (data) => api.post("/transactions/pay", data),
    {
      onSuccess: async () => {
        successToast("You have full access to course content");
        handleClose();
        handleQuestionnairePopupOpen(user?.users)
        await queryClient.invalidateQueries({
          queryKey: ["course", "content", "registered", courseId.toString()],
          refetchType: "all",
          exact: true,
        });

        await queryClient.invalidateQueries({
          queryKey: ["course", "registered", courseId.toString()],
          refetchType: "all",
          exact: true,
        });
        
        await queryClient.invalidateQueries({
          queryKey: ["lecture", "registered"],
          refetchType: "all",
          exact: true,
        });

        await queryClient.invalidateQueries({
          queryKey: ["user"],
          refetchType: "all",
          exact: true,
        });
      },
      onError: (error) => {
        errorToast(error.response.data.message);
      },
    }
  );
  return (
    <PaymentForm
      autoBuild={false}
      applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID}
      locationId={process.env.REACT_APP_SQUARE_LOCATION_ID}
      cardTokenizeResponseReceived={async (token) => {
        let body = JSON.stringify({
          sourceId: token.token,
          // price: price,
          courseId: courseId,
        });

        mutate(body);
      }}
    >
      <CreditCard
        buttonProps={{
          isLoading: isLoading,
        }}
      />
    </PaymentForm>
  );
};

export default SquarePaymentForm;
