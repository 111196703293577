import { Navigate, Route, Routes } from "react-router-dom";
import {
  Home,
  NotFound,
  PoliciesLayout,
  PrivacyPolicy,
  TermsAndConditions,
} from "../pages/root";
import { ProtectedRoute, QuestionnaireRoute } from "./routes";
import { Courses, Paths } from "../pages/platform";
import CourseDetails from "../pages/courses/CourseDetails";
import {
  Login,
  CreatePassword,
  Registration,
  ResendPassword,
  ForgotPassword,
  ResendForgottenPassword,
  CreateForgottenPassword,
} from "../pages/auth";
import OngoingLecture from "../pages/courses/OngoingLecture";
import Lecture from "../pages/courses/OngoingLecture/Lecture";
import Questionnaire from "../pages/Questionnaire";

import { AuthLayout } from "../components/Layouts";
import Profile from "../pages/Profile";

export const Router = ({ isAuthenticated, isAuthLoading, user }) => {
  const authElement = () => {
    const path = localStorage.getItem("redirectPath") || "/";
    return !isAuthenticated ? <AuthLayout /> : <Navigate to={path} />;
  };
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      {/* registration */}
      <Route path="/registration" element={authElement()}>
        <Route index={true} element={<Registration />} />
        <Route path="resend-password" element={<ResendPassword />} />
        <Route path="create-password" element={<CreatePassword />} />
      </Route>
      {/* login */}
      <Route path="/login" element={authElement()}>
        <Route index={true} element={<Login />} />
      </Route>
      <Route path="/forgot-password" element={authElement()}>
        <Route index={true} element={<ForgotPassword />} />
      </Route>
      <Route path="/resend-password" element={authElement()}>
        <Route index={true} element={<ResendForgottenPassword />} />
      </Route>
      <Route path="/reset-password" element={authElement()}>
        <Route index={true} element={<CreateForgottenPassword />} />
      </Route>
      {/* courses and path */}
      <Route
        path="paths"
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            isAuthLoading={isAuthLoading}
            redirectPath={"/registration"}
          >
            <Paths />
          </ProtectedRoute>
        }
      />
      <Route
        path="courses"
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            isAuthLoading={isAuthLoading}
            redirectPath={"/registration"}
          >
            <Courses />
          </ProtectedRoute>
        }
      />
      <Route
        path="courses/:id"
        element={
          <CourseDetails
            isAuthLoading={isAuthLoading}
            isAuth={isAuthenticated}
          />
        }
      />
      <Route
        path="/courses/:id/lecture/:lectureId"
        element={<OngoingLecture isAuth={isAuthenticated} />}
      >
        <Route index={true} element={<Lecture isAuth={isAuthenticated} />} />
      </Route>
      <Route path="privacy-policy" element={<PoliciesLayout />}>
        <Route index={true} element={<PrivacyPolicy />} />
      </Route>
      <Route path="terms-and-conditions" element={<PoliciesLayout />}>
        <Route index={true} element={<TermsAndConditions />} />
      </Route>

      {/* questionnaire */}

      <Route
        path="questionnaire"
        element={
          <QuestionnaireRoute
            isAuthenticated={isAuthenticated}
            isAuthLoading={isAuthLoading}
            user={user}
          >
            <Questionnaire />
          </QuestionnaireRoute>
        }
      />

      <Route
        path="profile"
        element={
          <ProtectedRoute
            isAuthenticated={isAuthenticated}
            isAuthLoading={isAuthLoading}
            redirectPath={"/login"}
          >
            <Profile user={user} />
          </ProtectedRoute>
        }
      />
      {/* 404 */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
