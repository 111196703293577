import React, { useEffect } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { Box } from '@mui/system';
import styles from './index.module.scss'

function PoliciesLayout() {
    useEffect(() => {
        localStorage.removeItem("redirectPath")
      }, []);
    return (
        <Box>
            <nav className={styles.nav}>
                <NavLink className={styles.nav__link} to="/terms-and-conditions">Terms and Conditions</NavLink>
                <NavLink className={styles.nav__link} to="/privacy-policy">Privacy Policy</NavLink>
            </nav>
            <Outlet />
        </Box>
    );
}

export default PoliciesLayout;