import React, { useContext, useState } from "react";
import { ThemeProvider, useTheme } from "@emotion/react";
import { Box, createTheme, IconButton, Menu, MenuItem } from "@mui/material";
import avatar from "../../../../../assets/images/avatar.png";
import { ReactComponent as LogoutIcon } from "../../../../../assets/images/logout.svg";
import { Text } from "../../../../MUI";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../../context/AuthContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "../../../../../instances/axiosInstances";
import { RedirectContext } from "../../../../../context/RedirectContext";

export default function UserMenu() {
  const { removeAuthUser, accessToken } = useContext(AuthContext);
  const { removeCurrentCourse } = useContext(RedirectContext);
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData(["user"]);
  const {pathname} = useLocation()

  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [error, setError] = useState("");
  const { mutate, isLoading } = useMutation(
    (data) => api.post("/auth/logout", data),
    {
      onSuccess: () => {
      localStorage.setItem("redirectPath", pathname);
        removeAuthUser();
        removeCurrentCourse();

        const timeout = setTimeout(() => {
          // navigate("/login");
          handleClose();
          clearTimeout(timeout);
        });
      },
      onError: (error) => {
        setError(error.response.data.message);
      },
    }
  );
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const finalTheme = createTheme(theme, {
    components: {
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: `${theme.palette.white} !important`,
            boxShadow: "0px 20px 30px rgba(21, 23, 61, 0.07) !important",
            borderRadius: "12px !important",
            padding: "0.75rem",
            minWidth: "194px !important",
          },
          list: {
            padding: 0,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: "0.875rem",
            fontFamily: "var(--proxima)",
            fontWeight: 700,
            display: "flex",
            gap: "0.875rem",
            alignItems: "center",
          },
        },
      },
    },
  });

  return (
    <Box
      display={"flex"}
      sx={{
        padding: 0,
        margin: 0,
        height: "min-content",
      }}
    >
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        sx={{
          padding: 0,
          width: "3rem",
          height: "3rem",
          borderRadius: "0.875rem",
          overflow: "hidden",
        }}
      >
        <img
          src={
            data?.data.users.profilePhotoUrl
              ? data.data.users.profilePhotoUrl
              : avatar
          }
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            display:"flex"
          }}
          width={48}
          alt="User"
        />
      </IconButton>
      <ThemeProvider theme={finalTheme}>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{ mt: "60px" }}
          keepMounted={true}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          BackdropProps={{
            sx: {
              opacity: "0 !important",
            },
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              navigate("/profile");
              handleClose();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
            >
              <path
                d="M6.24902 6.17578C6.24902 8.24328 7.93152 9.92578 9.99902 9.92578C12.0665 9.92578 13.749 8.24328 13.749 6.17578C13.749 4.10828 12.0665 2.42578 9.99902 2.42578C7.93152 2.42578 6.24902 4.10828 6.24902 6.17578ZM16.6657 18.2591H17.499V17.4258C17.499 14.2099 14.8815 11.5924 11.6657 11.5924H8.33236C5.11569 11.5924 2.49902 14.2099 2.49902 17.4258V18.2591H16.6657Z"
                fill="#3D40D5"
              />
            </svg>
            <Text variant="text-14" color={theme.palette.dark}>
              Profile
            </Text>
          </MenuItem>
          <MenuItem
            onClick={() => {
              mutate({
                token: accessToken || localStorage.getItem("accessToken"),
              });
            }}
          >
            <LogoutIcon />
            <Text variant="text-14" color={theme.palette.danger}>
              Log Out
            </Text>
          </MenuItem>
        </Menu>
      </ThemeProvider>
    </Box>
  );
}
