import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import Text from "../../Text";
import Title from "../../Title";
import classes from "./index.module.scss";
import { useDeferredValue } from "react";
import { Button } from "../../buttons";
import { formatTimeMilliseconds } from "../../../../helpers/formatTime";

const PathCoursesList = ({
  listHeight,
  isCardVisible,
  setIsCardVisible,
  courses,
}) => {
  const theme = useTheme();

  const deferredCourses = useDeferredValue(courses);

  return (
    <Box
      className={`${classes.wrapper} ${
        isCardVisible ? classes.hidden : classes.visible
      }`}
      sx={{ height: `${listHeight}` }}
    >
      <Title
        className={classes["courses-title"]}
        variant="title-20-const"
        sx={{ color: theme.palette.dark, fontWeight: 700 }}
      >
        Path
      </Title>
      <Title
        className={classes["courses-title-mobile"]}
        variant="title-20-const"
        sx={{ color: theme.palette.dark, fontWeight: 700 }}
      >
        📚 iOS SDET Path
      </Title>
      <Box className={classes.list}>
        {deferredCourses.map((course) => (
          <Link
            key={course.id}
            to={`/courses/${course.id}`}
            className={classes.item}
          >
            <Text
              className={classes.item__title}
              variant="text-16-const"
              component="h4"
              sx={{ color: theme.palette.dark, fontWeight: 700 }}
            >
              {course.title}
            </Text>
            <Text
              className={classes.item__text}
              variant="text-14"
              sx={{ color: theme.palette["dark-60"], fontWeight: 400 }}
            >
              {course.lecturesCount} lecture
              {course.lecturesCount > 1 ? "s" : ""}, {formatTimeMilliseconds(course.duration)}
            </Text>
          </Link>
        ))}
      </Box>
      <Button
        fullWidth
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={() => setIsCardVisible(true)}
      >
        Show general info
      </Button>
    </Box>
  );
};
export default PathCoursesList;
