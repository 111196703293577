import { Box } from "@mui/material";
import React from "react";
import { AccentButton, WhiteButton } from "../../../../MUI/buttons/button";
import { ReactComponent as Discord } from "../../../../../assets/images/discord-blue.svg";
import { ReactComponent as YouTube } from "../../../../../assets/images/youtube-blue.svg";
import { ReactComponent as Facebook } from "../../../../../assets/images/facebook-blue.svg";
import useButtonClick from "../../../../../hooks/useButtonClick";

import classes from "./index.module.scss";
import UserMenu from "../../common/UserMenu";

export default function DesktopMenu({ auth }) {
  const { onStartForFreeClick } = useButtonClick();

  return (
    <Box
      component="nav"
      className={classes["navigation-desktop"]}
      sx={{
        gap: "1rem",
        alignItems: "center",
      }}
    >
      <Box display="flex" gap="1rem">
        <WhiteButton
          isBig={false}
          variant="outlined"
          component={"a"}
          startIcon={<Discord />}
          href={process.env.REACT_APP_DISCORD_LINK}
          target="_blank"
          rel="noreferrer"
        >
          Discord Community
        </WhiteButton>
        <WhiteButton
          isBig={false}
          variant="outlined"
          component={"a"}
          startIcon={<YouTube />}
          href={process.env.REACT_APP_YOUTUBE_LINK}
          target="_blank"
          rel="noreferrer"
        >
          Our Youtube
        </WhiteButton>
        <WhiteButton
          isBig={false}
          variant="outlined"
          component={"a"}
          startIcon={<Facebook />}
          href={process.env.REACT_APP_FACEBOOK_LINK}
          target="_blank"
          rel="noreferrer"
        >
          Join us
        </WhiteButton>

        {auth ? (
          <UserMenu />
        ) : (
          <AccentButton
            isBig={false}
            variant="contained"
            onClick={onStartForFreeClick}
          >
            Sign Up / Login
          </AccentButton>
        )}
      </Box>
    </Box>
  );
}
