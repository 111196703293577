import React from "react";
import { LandingLayout } from "../../../../components/Layouts";
import styles from "./index.module.scss";

function TermsAndConditions() {
  return (
    <LandingLayout>
      <div className={styles.wrapper}>
        <div className={styles.textBlock}>
          <h3>Terms</h3>
          <p>
            By accessing this website, you are agreeing to be bound by these
            website Terms and Conditions of Use, all applicable laws and
            regulations, and agree that you are responsible for compliance with
            any applicable local laws. If you do not agree with any of these
            terms, you are prohibited from using or accessing this site. The
            materials contained in this website are protected by applicable
            copyright and trade mark law.
          </p>
        </div>
        <div className={styles.textBlock}>
          <h3>Website Contents</h3>
          <p>
            This website and the Contents are intended solely for personal,
            non-commercial use. You may download or copy the Contents and other
            downloadable materials displayed on this website for your personal
            use only. No right, title, or interest in any downloaded Content is
            transferred to you as a result of any such downloading or copying.
            You may not reproduce (except as noted above), publish, transmit,
            distribute, display, modify, create derivative works from, sell or
            exploit in any way any of the Contents of this website.
          </p>
        </div>
        <div className={styles.textBlock}>
          <h3>Access to Password Protected/Secure Areas</h3>
          <p>
            Access to and use of password protected and/or secure areas of the
            Site is restricted to authorized users only. Unauthorized
            individuals attempting to access these areas of the Site may be
            subject to prosecution.
          </p>
        </div>
        <div className={styles.textBlock}>
          <h3>Copyright</h3>
          <p>
            All content included on the Site, including but not limited to text,
            graphics, logos, button icons, images, audio clips, digital
            downloads, data compilations and software, is the property and
            copyright work of either Engenious University, its users, its
            content suppliers or its licensors and is protected by copyright,
            trademarks, patents or other intellectual property rights and laws.
            The compilation of the content on the Site is the exclusive property
            and copyright of Engenious University and is protected by copyright,
            trademarks, patents or other intellectual property rights and laws.
          </p>
        </div>
        <div className={styles.textBlock}>
          <h3>Hyperlinking</h3>
          <p>
            THIS SITE MAY PROVIDE A LINK TO OTHER SITES BY ALLOWING THE USER TO
            LEAVE THIS SITE TO ACCESS THIRD-PARTY MATERIAL OR BY BRINGING THE
            THIRD-PARTY MATERIAL INTO THIS SITE VIA “INVERSE” HYPERLINKS AND
            FRAMING TECHNOLOGY (A “LINKED SITE”). ENGENIOUS UNIVERSITY HAS NO
            DISCRETION TO ALTER, UPDATE, OR CONTROL THE CONTENT ON A LINKED
            SITE. THE FACT THAT ENGENIOUS UNIVERSITY HAS PROVIDED A LINK TO A
            SITE IS NOT AN ENDORSEMENT, AUTHORIZATION, SPONSORSHIP, OR
            AFFILIATION WITH RESPECT TO SUCH SITE, ITS OWNERS, OR ITS PROVIDERS.
            THERE ARE INHERENT RISKS IN RELYING UPON USING, OR RETRIEVING ANY
            INFORMATION FOUND ON THE INTERNET, AND ENGENIOUS UNIVERSITY URGES
            YOU TO MAKE SURE YOU UNDERSTAND THESE RISKS BEFORE RELYING UPON,
            USING, OR RETRIEVING ANY SUCH INFORMATION ON A LINKED SITE.
          </p>
        </div>
        <div className={styles.textBlock}>
          <h3>No Warranties</h3>
          <p>
            ALL CONTENT, PRODUCTS, AND SERVICES ON THE SITE, OR OBTAINED FROM A
            WEBSITE TO WHICH THE SITE IS LINKED (A “LINKED SITE”) ARE PROVIDED
            TO YOU “AS IS” WITHOUT WARRANTY OF ANY KIND EITHER EXPRESS OR
            IMPLIED INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, TITLE,
            NON-INFRINGEMENT, SECURITY OR ACCURACY. ENGENIOUS UNIVERSITY DOES
            NOT ENDORSE AND IS NOT RESPONSIBLE FOR (A) THE ACCURACY OR
            RELIABILITY OF ANY OPINION, ADVICE OR STATEMENT MADE THROUGH THE
            SITE BY ANY PARTY OTHER THAN ENGENIOUS UNIVERSITY, (B) ANY CONTENT
            PROVIDED ON LINKED SITES OR (C) THE CAPABILITIES OR RELIABILITY OF
            ANY PRODUCT OR SERVICE OBTAINED FROM A LINKED SITE. OTHER THAN AS
            REQUIRED UNDER APPLICABLE CONSUMER PROTECTION LAW, UNDER NO
            CIRCUMSTANCE WILL ENGENIOUS UNIVERSITY BE LIABLE FOR ANY LOSS OR
            DAMAGE CAUSED BY A USER’S RELIANCE ON INFORMATION OBTAINED THROUGH
            THE SITE OR A LINKED SITE, OR USER’S RELIANCE ON ANY PRODUCT OR
            SERVICE OBTAINED FROM A LINKED SITE. IT IS THE RESPONSIBILITY OF THE
            USER TO EVALUATE THE ACCURACY, COMPLETENESS OR USEFULNESS OF ANY
            OPINION, ADVICE OR OTHER CONTENT AVAILABLE THROUGH THE SITE, OR
            OBTAINED FROM A LINKED SITE. PLEASE SEEK THE ADVICE OF
            PROFESSIONALS, AS APPROPRIATE, REGARDING THE EVALUATION OF ANY
            SPECIFIC OPINION, ADVICE, PRODUCT, SERVICE, OR OTHER CONTENT.
          </p>
        </div>
        <div className={styles.textBlock}>
          <h3>Limitation of Liability for Use of the Site and Linked Sites</h3>
          <p>
            THE INFORMATION, SOFTWARE, PRODUCTS AND DESCRIPTIONS OF SERVICES
            PUBLISHED ON THE SITE OR A LINKED SITE MAY INCLUDE INACCURACIES OR
            TYPOGRAPHICAL ERRORS, AND ENGENIOUS UNIVERSITY SPECIFICALLY
            DISCLAIMS ANY LIABILITY FOR SUCH INACCURACIES OR ERRORS. ENGENIOUS
            UNIVERSITY DOES NOT WARRANT OR REPRESENT THAT THE CONTENT ON THE
            SITE IS COMPLETE OR UP-TO-DATE. ENGENIOUS UNIVERSITY IS UNDER NO
            OBLIGATION TO UPDATE ANY CONTENT ON THE SITE. ENGENIOUS UNIVERSITY
            MAY CHANGE THE CONTENT ON THE SITE AT ANY TIME WITHOUT NOTICE.
            ENGENIOUS UNIVERSITY MAY MAKE IMPROVEMENTS OR CHANGES TO THE SITE AT
            ANY TIME. YOU AGREE THAT ENGENIOUS UNIVERSITY, ITS AFFILIATES AND
            ANY OF THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS
            WILL NOT BE LIABLE, WHETHER IN CONTRACT, TORT, STRICT LIABILITY OR
            OTHERWISE, FOR ANY INDIRECT, PUNITIVE, SPECIAL, CONSEQUENTIAL,
            INCIDENTAL OR INDIRECT DAMAGES (INCLUDING WITHOUT LIMITATION LOST
            PROFITS, COST OF PROCURING SUBSTITUTE SERVICE OR LOST OPPORTUNITY)
            ARISING OUT OF OR IN CONNECTION WITH THE USE OF THE SITE OR A LINKED
            SITE, OR WITH THE DELAY OR INABILITY TO USE THE SITE OR A LINKED
            SITE, EVEN IF ENGENIOUS UNIVERSITY IS MADE AWARE OF THE POSSIBILITY
            OF SUCH DAMAGES. THIS LIMITATION ON LIABILITY INCLUDES, BUT IS NOT
            LIMITED TO, THE TRANSMISSION OF ANY VIRUSES WHICH MAY INFECT A
            USER’S EQUIPMENT, FAILURE OF MECHANICAL OR ELECTRONIC EQUIPMENT OR
            COMMUNICATION LINES, TELEPHONE OR OTHER INTERCONNECT PROBLEMS (E.G.,
            YOU CANNOT ACCESS YOUR INTERNET SERVICE PROVIDER), UNAUTHORIZED
            ACCESS, THEFT, OPERATOR ERRORS, STRIKES OR OTHER LABOR PROBLEMS OR
            ANY FORCE MAJEURE. ENGENIOUS UNIVERSITY CANNOT AND DOES NOT
            GUARANTEE CONTINUOUS, UNINTERRUPTED OR SECURE ACCESS TO THE WEB
            SITE.
          </p>
        </div>
        <div className={styles.textBlock}>
          <h3>Confidentiality of User Communications</h3>
          <p>
            Except as required by law and in accordance with the Engenious
            University Internet Privacy Statement. Engenious University will
            maintain the confidentiality of all user communications which
            contain personal user information and which are transmitted directly
            to Engenious University. Postings by a user on any message board or
            in any chat room will not be protected as confidential and Engenious
            University may use and provide information contained in any such
            postings (including any ideas, concepts, know-how or other
            intellectual property) to any of its parent company, subsidiaries
            and affiliates for any purpose whatsoever and as deemed appropriate
            by Engenious University. User should be aware that Linked Sites may
            contain confidentiality provisions that differ from the provisions
            provided herein. Engenious University is not responsible for such
            provisions, and expressly disclaims any and all liability related to
            such provisions.
          </p>
        </div>
        <div className={styles.textBlock}>
          <h3>Linked Internet Sites</h3>
          <p>
            Engenious University prohibits caching, unauthorized hypertext links
            to the Site and the framing of any Content available through the
            Site. Engenious University reserves the right to disable any
            unauthorized links or frames and specifically disclaims any
            responsibility for the Content available on any other Internet sites
            linked to the Site. Access to any other Internet sites linked to the
            Site is at the user’s own risk. User should be aware that Linked
            Sites may contain rules and regulations, privacy provisions,
            confidentiality provisions, transmission of personal data
            provisions, and other provisions that differ from the provisions
            provided on the Site. Engenious University is not responsible for
            such provisions, and expressly disclaims any and all liability
            related to such provisions.
          </p>
        </div>
        <div className={styles.textBlock}>
          <h3>Press Releases</h3>
          <p>
            All press releases and other materials presented or released to the
            financial community that are contained on the Site were, to the best
            of Engenious University’ knowledge, timely and accurate when issued.
            However, the passage of time can render information stale, and you
            should not rely on the continued accuracy of any such material
            beyond the date of issuance. Engenious University has no
            responsibility to update any information contained in any such
            material. All viewers should carefully check the dates of issuance
            of the material contained in the Site.
          </p>
        </div>
        <div className={styles.textBlock}>
          <h3>Postings</h3>
          <p>
            Engenious University is under no obligation to review any messages,
            information or content (“Postings”) posted on the Site by users and
            assumes no responsibility or liability relating to any such
            Postings. Notwithstanding the above, Engenious University may from
            time to time monitor the Postings on the Site and may decline to
            accept and/or remove any Postings that contain:
          </p>
          <ul>
            <li>
              Any unlawful, harmful, threatening, abusive, harassing,
              defamatory, vulgar, obscene, profane, hateful, racially,
              ethnically or otherwise objectionable material of any kind,
              including, but not limited to, any material which encourages
              conduct that would constitute a criminal offense, give rise to
              civil liability or otherwise violate any applicable local, state,
              national or international law.
            </li>
            <li>Advertisements or solicitations of any kind.</li>
            <li>Messages posted by users impersonating others.</li>
            <li>
              Personal information such as messages which state phone numbers,
              social security numbers, account numbers, addresses, or employer
              references.
            </li>
            <li>
              Messages by non-spokesperson employees of Engenious University
              purporting to speak on behalf of Engenious University or
              containing confidential information or expressing opinions
              concerning Engenious University.
            </li>
            <li>
              Messages that offer unauthorized downloads of any copyrighted or
              private information.
            </li>
            <li>
              Multiple messages placed within individual folders by the same
              user restating the same point.
            </li>
            <li>Chain letters of any kind.</li>
          </ul>
        </div>
        <div className={styles.textBlock}>
          <h3>Transmission of Personal Data</h3>
          <p>
            User acknowledges and agrees that by providing Engenious University
            with any personal or proprietary user information through the Site,
            user consents to the transmission of such personal or proprietary
            user information over international borders as necessary for
            processing in accordance with Engenious University’ standard
            business practices. User should be aware that Linked Sites may
            contain transmission of personal data provisions that differ from
            the provisions provided herein. Engenious University is not
            responsible for such provisions, and expressly disclaims any and all
            liability related to such provisions.
          </p>
        </div>

        <div className={styles.textBlock}>
          <h3>Refund Policy</h3>
          <p>
            By enrolling in any course offered by the University, you agree to
            adhere to this Policy.
          </p>
          <br />
          <ol>
            <li>
              Refund Eligibility:
              <ol>
                <li>
                  Course Fee: The course fee is refundable in accordance with
                  the following conditions:
                  <ol type="a">
                    <li>
                      Full Refund: A student is eligible for a full refund of
                      the course fee if a request for cancellation is made
                      within 7 calendar days of enrollment and before the
                      student gains access to the course materials.
                    </li>
                    <li>
                      No Refund: No refund will be provided after 7 calendar
                      days after the course purchase transaction.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              Refund Request Process:
              <ol>
                <li>
                  To initiate a refund request, the student must contact the
                  University's Student Support team at&nbsp;
                  <a href="mailto:university@engenious.io">
                    university@engenious.io
                  </a>
                  &nbsp;or in the live chat on the website&nbsp;
                  <a
                    href="https://university.engenious.io/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://university.engenious.io/
                  </a>
                  , providing relevant details, such as their full name, course
                  name, enrollment date, transaction date, and reason for the
                  refund request.
                </li>
                <li>
                  The refund request must be received within the specified
                  timeframes mentioned in Section 1 to be considered for a
                  refund.
                </li>
                <li>
                  The University reserves the right to verify the student's
                  access and progress in the course before processing the refund
                  request.
                </li>
              </ol>
            </li>
            <li>
              Refund Processing:
              <ol>
                <li>
                  Upon receipt of a valid refund request, the Engenious
                  University will review the request within 10 business days.
                </li>
                <li>
                  If the refund request meets the eligibility criteria outlined
                  in Section 1, the Engenious University will process the refund
                  using the original payment method within 14 business days.
                </li>
                <li>
                  The Engenious University is not responsible for any fees or
                  charges imposed by third-party payment processors in
                  connection with the refund.
                </li>
              </ol>
            </li>
            <li>
              Course Cancellation or Deletion:
              <ol>
                <li>
                  The Engenious University reserves the right to cancel or
                  delete a course from&nbsp;
                  <a
                    href="https://university.engenious.io/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://university.engenious.io/
                  </a>
                  &nbsp;due to unforeseen circumstances, insufficient
                  enrollments, or other valid reasons. In such cases, students
                  will be notified promptly, and the Engenious University will
                  provide a full refund of the course tuition fee.
                </li>
              </ol>
            </li>
            <li>
              Modifications to the Refund Policy:
              <ol>
                <li>
                  The Engenious University reserves the right to modify or
                  update this Refund Policy at any time. Changes will be
                  effective upon posting the updated Policy on the Engenious
                  University's website.
                </li>
                <li>
                  Students will be notified of any significant changes to the
                  Policy via email or through a prominent notice on the
                  Engenious University's website.
                </li>
              </ol>
            </li>
          </ol>
          <br />
          <p>
            By enrolling in a course offered by Engenious University, you
            acknowledge and agree to abide by the terms and conditions of this
            Refund Policy. If you have any questions or require further
            clarification, please contact our Student Support team.
          </p>
        </div>

        <div className={styles.textBlock}>
          <h3>Modification and Notice</h3>
          <p>
            You agree that Engenious University may modify these Terms and
            Conditions and any other policies on our Site at any time and that
            posting the modified Terms and Conditions or policies on our Site
            will constitute sufficient notice of such modification.
          </p>
        </div>
        <div className={styles.textBlock}>
          <h3>Severability</h3>
          <p>
            If any provision of these Terms and Conditions shall be deemed
            unlawful, void or for any reason unenforceable, then that provision
            shall be deemed severable from these Terms and Conditions and shall
            not affect the validity and enforceability of any remaining
            provisions.
          </p>
        </div>
        <div className={styles.textBlock}>
          <h3>Violations of Rules and Regulations</h3>
          <p>
            Engenious University reserves the right to seek all remedies
            available at law and in equity for violations of these Rules and
            Regulations, including the right to block access from a particular
            Internet address to the Site.
          </p>
        </div>
        <div className={styles.textBlock}>
          <h3>
            Digital Millennium Copyright Act – Notification to Engenious
            University Regarding Alleged Copyright Infringement
          </h3>
          <p>
            Engenious University reserves the right to remove any Content on the
            Site which allegedly infringes another person’s copyright. Notices
            to Engenious University regarding any alleged copyright infringement
            on the Site should be directed to Engenious University’s Legal
            Department at:
            <p>Engenious, Inc</p>
            <p>7901 4th St N,</p>
            <p>STE 15096</p>
            <p>St. Petersburg, FL 33702, USA</p>
          </p>
        </div>
        <div className={styles.textBlock}>
          <h3>Jurisdiction/Governing Law</h3>
          <p>
            Users of this Site agree to be governed by the laws of the United
            States of America and state of Florida, including copyright laws,
            without regard to its conflict of laws rules.
          </p>
        </div>
      </div>
    </LandingLayout>
  );
}

export default TermsAndConditions;
