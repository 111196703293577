import Header from "../components/root/Header";
import Main from "../components/Main";
import Footer from "../components/Footer";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";

export default function LandingLayout({ children }) {
  const { auth } = useContext(AuthContext);
  useEffect(() => {
    localStorage.removeItem("redirectPath")
  }, []);
  return (
    <>
      <Header auth={auth} />
      <Main>
        {children}
        {/* <Outlet /> */}
      </Main>
      <Footer />
    </>
  );
}
