import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typography, Box } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import photo from "../../../assets/images/photo.png";
import classes from "./index.module.scss";
import {
  AccentButton,
  WhiteButton,
} from "../../../components/MUI/buttons/button";
import { apiFormData } from "../../../instances/axiosInstances";

const schema = yup.object().shape({
  image: yup
    .mixed()
    .test("file", "Please select a valid image (PNG or JPEG)", (value) => {
      if (!value) return false;
      const validTypes = ["image/png", "image/jpeg", "image/jpg"];
      return validTypes.includes(value.type);
    })
    .test("fileSize", "The file is too large (max: 4MB)", (value) => {
      if (!value) return false;
      return value.size <= 4000000;
    })
    .required("Please select an image"),
});

const ImageUpload = ({ profilePhotoUrl }) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [isInputVisible, setIsInputVisible] = useState(false);

  const { control, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const handleFileChange = (e, field) => {
    e.preventDefault();
    const file = e.target.files[0];
    field.onChange(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
    }
  };

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (data) => apiFormData.put("/users/photo", data),
    {
      onSuccess: async() => {
        setIsInputVisible(false);
        await queryClient.invalidateQueries({
          queryKey: ["user"],
          refetchType: "all",
          exact: true,
        });
        reset();
      },

      onError: async() => {
        setIsInputVisible(false);
        await queryClient.invalidateQueries({
          queryKey: ["user"],
          refetchType: "all",
          exact: true,
        });
        setPreviewImage(null);
        reset();
      },
    }
  );

  const { mutate: removeImage, isLoading: removeLoading } = useMutation(
    (data) => apiFormData.put("/users/photo", data),
    {
      onSuccess: async() => {
        setIsInputVisible(false);
        await queryClient.invalidateQueries({
          queryKey: ["user"],
          refetchType: "all",
          exact: true,
        });
        setPreviewImage(null);
        reset();
      },
      onError: async() => {
        setIsInputVisible(false);
        await queryClient.invalidateQueries({
          queryKey: ["user"],
          refetchType: "all",
          exact: true,
        });
        setPreviewImage(null);
        reset();
      },
    }
  );

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.set("profilePhoto", data.image);
    mutate(formData);
  };

  const onImageRemove = () => {
    const formData = new FormData();
    formData.set("profilePhoto", null);
    removeImage(formData);
  };

  useEffect(() => {
    previewImage && handleSubmit(onSubmit)();
  }, [previewImage]);

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="image"
        control={control}
        defaultValue={null}
        render={({ field }) => (
          <>
            {isInputVisible ? (
              <input
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                id="image-upload"
                style={{ display: "none" }}
                onChange={(e) => {
                  handleFileChange(e, field);
                }}
              />
            ) : null}
            <Box className={classes.wrapper}>
              <Box className={classes.image}>
                {previewImage || profilePhotoUrl ? (
                  <img
                    className={classes.image__preview}
                    src={previewImage || profilePhotoUrl}
                    alt="Avatar"
                    width={90}
                  />
                ) : (
                  <img
                    className={classes.image__photo}
                    src={photo}
                    alt="Avatar"
                    width={90}
                  />
                )}
              </Box>
              {formState.errors.image && (
                <Typography className={classes.error}>
                  {formState.errors.image.message}
                </Typography>
              )}
            </Box>

            <Box className={classes.actions}>
              <AccentButton
                isBig={false}
                variant="contained"
                component="label"
                disabled={isLoading || removeLoading}
                htmlFor="image-upload"
                onClick={() => setIsInputVisible(true)}
              >
                {profilePhotoUrl ? "Change" : "Upload"}
              </AccentButton>
              {profilePhotoUrl ? (
                <WhiteButton
                  isBig={false}
                  variant="outlined"
                  disabled={isLoading || removeLoading}
                  onClick={() => {
                    setIsInputVisible(false);
                    onImageRemove();
                  }}
                >
                  Remove
                </WhiteButton>
              ) : null}
              <Typography className={classes.warning}>
                100x100px min • PNG or JPG • 4MB max
              </Typography>
            </Box>
          </>
        )}
      />
    </form>
  );
};

export default ImageUpload;
