import { Box } from "@mui/material";

import classes from "./index.module.scss";

export default function Slide({ slide }) {

  return (
    <Box
      component={"a"}
      href={slide.link}
      className={classes.slide}
      target={"_blank"}
    >
      <img src={slide.imageUrl} alt={slide.name} />
      <Box className={classes.layout}>

      </Box>
    </Box>
  );
}
