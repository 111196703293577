import { Box, Typography } from "@mui/material";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import classes from "./index.module.scss";

export default function Requirements({ requirements, className = "" }) {
  if (!requirements || !requirements.length) {
    return null;
  }
  return (
    <Box className={`${classes.requirements} ${className}`}>
      <Typography
        component="h2"
        variant="h2"
        className={classes.requirements__title}
      >
        <ErrorOutlineRoundedIcon
          sx={{ color: "#FF3D2E", fontSize: "inherit", marginBottom: -0.35 }}
        />{" "}
        Course requirements
      </Typography>
      <Box component="ul" className={classes.requirements__list}>
        {requirements.map((requirement, index) => (
          <Typography
            key={index}
            component="li"
            className={classes.requirements__text}
          >
            {requirement}
          </Typography>
        ))}
      </Box>
    </Box>
  );
}
