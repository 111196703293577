import { Box, Typography } from "@mui/material";
import Tasks from "../Tasks";
import Description from "../Description";
import Reviews from "../Reviews";
import Requirements from "../Requirements";
import WhatToLearn from "../WhatToLearn";
import WhoIsCourseFor from "../WhoIsCourseFor";
import Instructors from "../Instructors";
import FAQ from "../FAQ";
import WhatWeTalkAbout from "../WhatWeTalkAbout";
import CourseCardLive from "../CourseCardLive";
import Schedule from "../Schedule";
import ModulesLive from "../ModulesLive";
import classes from "./index.module.scss";

export default function Live({ data }) {
  return (
    <Box className={classes.course}>
      <CourseCardLive data={data} />
      <Box className={classes.wrapper}>
        <Box className={classes.left}>
          <WhatWeTalkAbout type={data.type} whatToTalkAbout={data.whatToTalkAbout} className={classes.whatWeTalkAbout}/>
          <WhatToLearn whatToLearn={data.whatToLearn} className={classes.whatToLearn} type={data.type}/>
          <Schedule
            className={classes.schedule__mobile}
            liveSchedule={data.liveSchedule}
            courseTimeZone={data?.courseTimeZone}
          />
          <Requirements
            className={classes.requirements__mobile}
            requirements={data.requirements}
          />
          <Box className={`${classes.content} ${classes.syllabus}`}>
            <Box className={classes.content__header}>
              <Typography
                component="h2"
                variant="h2"
                className={classes.content__title}
              >
                Course syllabus
              </Typography>
            </Box>
            <Tasks
              tasks={data.preparationTasks}
              title={"Webinar preparation tasks"}
            />
            <ModulesLive modules={data.modules} />
          </Box>
          <Description description={data.description} className={classes.description}/>
          <Reviews
            alumniFeedbacks={data.alumniFeedbacks}
            rating={data.rating}
            className={classes.alumniFeedbacks__desktop}
          />
        </Box>

        <Box className={classes.right}>
          <Schedule
            className={classes.schedule__desktop}
            liveSchedule={data.liveSchedule}
            courseTimeZone={data?.courseTimeZone}
          />
          <Requirements
            className={classes.requirements__desktop}
            requirements={data.requirements}
          />

          <WhoIsCourseFor whoIsCourseFor={data.whoIsCourseFor} />
          <Instructors
            instructors={data.instructors}
            alumniFeedbacks={data.alumniFeedbacks}
          />
          <FAQ faq={data.frequentlyAskedQuestions} />
          <Reviews
            alumniFeedbacks={data.alumniFeedbacks}
            rating={data.rating}
            className={classes.alumniFeedbacks__mobile}
          />
        </Box>
      </Box>
    </Box>
  );
}
