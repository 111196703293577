import React from "react";
import { Box, List, ListItem } from "@mui/material";
import { Link } from "react-router-dom";
import { scrollToSection } from "../../../../../helpers/scrollToSection";
import useButtonClick from "../../../../../hooks/useButtonClick";
import { AccentButton, WhiteButton } from "../../../../MUI/buttons/button";
import BurgerButton from "../../common/BurgerButton";
import { HEADER_HEIGHT } from "../../../../../constants/layout";
import { ReactComponent as Discord } from "../../../../../assets/images/discord.svg";
import { ReactComponent as UniversityLogo } from "../../../../../assets/images/logo.svg";
import Logo from "../../common/Logo";
import classes from "./index.module.scss";

export default function Drawer({ navItems, handleDrawerToggle, auth }) {
  const { onStartForFreeClick } = useButtonClick();

  return (
    <Box
      flexGrow={1}
      sx={{
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: "2.5rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Logo handleDrawerToggle={handleDrawerToggle}>
          <UniversityLogo />
        </Logo>
        <BurgerButton handleDrawerToggle={handleDrawerToggle}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M6.4 19.0129L5 17.6129L10.6 12.0129L5 6.41294L6.4 5.01294L12 10.6129L17.6 5.01294L19 6.41294L13.4 12.0129L19 17.6129L17.6 19.0129L12 13.4129L6.4 19.0129Z"
              fill="#3D40D5"
            />
          </svg>
        </BurgerButton>
      </Box>
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 0,
          flexGrow: 1,
          overflowY: "auto",
          marginLeft: "-1rem",
          marginRight: "-1rem",
        }}
      >
        {navItems
          ? navItems.map((item) => (
              <ListItem key={item.id} disablePadding>
                <a
                  className={classes.link}
                  href={`#${item.id}`}
                  onClick={(e) => {
                    scrollToSection(e, item.id, HEADER_HEIGHT);
                    handleDrawerToggle();
                  }}
                >
                  {item.name}
                </a>
              </ListItem>
            ))
          : null}
        <ListItem
          disablePadding
          onClick={handleDrawerToggle}
          sx={{
            marginTop: "auto",
            display: "flex",
            flexDirection: "column",
            gap: 1,
            paddingLeft: "1rem",
            paddingRight: "1rem",
          }}
        >
          <WhiteButton
            isBig={false}
            variant="outlined"
            component={"a"}
            startIcon={<Discord />}
            href={process.env.REACT_APP_DISCORD_LINK}
            target="_blank"
            rel="noreferrer"
            fullWidth
          >
            Discord Community
          </WhiteButton>
          {auth ? (
            <AccentButton
              isBig={false}
              variant="contained"
              fullWidth
              component={Link}
              to="/courses"
            >
              Student Platform
            </AccentButton>
          ) : (
            <AccentButton
              isBig={false}
              variant="contained"
              color="primary"
              fullWidth
              onClick={onStartForFreeClick}
            >
              Sign Up / Login
            </AccentButton>
          )}
        </ListItem>
      </List>
    </Box>
  );
}
