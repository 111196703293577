import { useContext } from "react";
import { Box, Typography } from "@mui/material";
import {
  TransparentButton,
  WhiteButton,
} from "../../../../../components/MUI/buttons/button";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import image from "../images/intro.png";
import { AuthContext } from "../../../../../context/AuthContext";
import { ModalContext } from "../../../../../context/ModalContext";
import VideoModal from "../../../../../components/Common/EmbedVideos/VideoWrapper";
import { scrollToSection } from "../../../../../helpers/scrollToSection";
import { HEADER_HEIGHT } from "../../../../../constants/layout";
import { Link } from "react-router-dom";
import { mixpanelLandingHero } from "../../../../../constants/mixpanel";
import useMixpanel from "../../../../../hooks/useMixpanel";
import classes from "./index.module.scss";

export default function Intro() {
  const { auth } = useContext(AuthContext);
  const { handleModalOpen } = useContext(ModalContext);
  const { handleEventTrack } = useMixpanel();
  const { startForFreeLink, startForFreeAnchor, watchVideo } =
    mixpanelLandingHero;

  // const showModal = (
  //   url = "https://player.vimeo.com/video/773369240?h=15ef016199&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  //   title = "1.1 welcome"
  // ) => {
  //   handleModalOpen(<VideoModal title={title} url={url} />);
  // };
  return (
    <Box className={classes.body} component="section">
      <Box className={classes.content}>
        <Typography className={classes.title} component={"h1"} variant={"h1"}>
          Engenious University
        </Typography>
        <Typography
          className={classes.subtitle}
          component={"h2"}
          variant={"h2"}
        >
          For those who aspire
        </Typography>
        <Box component={"ul"} className={classes.list}>
          <Typography className={classes.list__item} component={"li"}>
            real-world projects and hands-on tasks
          </Typography>
          <Typography className={classes.list__item} component={"li"}>
            full-time guidance by our experts
          </Typography>
          <Typography className={classes.list__item} component={"li"}>
            career-oriented personalized approach{" "}
          </Typography>
        </Box>
        <Box className={classes.actions}>
          {auth ? (
            <WhiteButton
              className={classes.actions__start}
              endIcon={<ChevronRightIcon />}
              variant={"contained"}
              component={Link}
              to="/courses"
              onClick={(e) => handleEventTrack(e, startForFreeLink)}
            >
              Start for Free
            </WhiteButton>
          ) : (
            <WhiteButton
              className={classes.actions__start}
              endIcon={<ChevronRightIcon />}
              variant={"contained"}
              component={"a"}
              href={`#courses`}
              onClick={(e) => {
                handleEventTrack(e, startForFreeAnchor);
                scrollToSection(e, "courses", HEADER_HEIGHT);
              }}
            >
              Start for Free
            </WhiteButton>
          )}
          {/* <TransparentButton
            className={classes.actions__play}
            startIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20.8889 12.5C20.8889 17.4092 16.9092 21.3889 12 21.3889C7.0908 21.3889 3.11111 17.4092 3.11111 12.5C3.11111 7.5908 7.0908 3.61111 12 3.61111C16.9092 3.61111 20.8889 7.5908 20.8889 12.5ZM22 12.5C22 18.0228 17.5228 22.5 12 22.5C6.47715 22.5 2 18.0228 2 12.5C2 6.97715 6.47715 2.5 12 2.5C17.5228 2.5 22 6.97715 22 12.5ZM16.4446 12.5L9.03722 16.9444V8.05556L16.4446 12.5Z"
                  fill="white"
                />
              </svg>
            }
            variant={"contained"}
            aria-label="play"
            onClick={(e) => {
              handleEventTrack(e, watchVideo);
              showModal();
            }}
          >
            Watch Video
          </TransparentButton> */}
        </Box>
      </Box>
      <Box className={classes.image}>
        <Box className={classes.image__wrapper}>
          <img src={image} alt="Robot" width={765} />
        </Box>
      </Box>
    </Box>
  );
}
