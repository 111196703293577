import { createTheme } from "@mui/material/styles";

export const theme = (defaultTheme)=>createTheme({
  typography: {
    fontFamily: "var(--proxima)",
    body1: {
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "1.1875",
      color: defaultTheme.palette["dark-70"],
    },
    body2: {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "1.1875",
      color: defaultTheme.palette["dark-40"],
    },
  },
  components: {
    MuiRating: {
      styleOverrides: {
        root: {
          paddingBottom: defaultTheme.spacing(0),
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body1: "span",
          body2: "span",
        },
      },
    },
  },
});
