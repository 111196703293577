import { Box, Typography } from "@mui/material";
import { ReactComponent as Icon } from "../../../../assets/images/mdi_talk.svg";
import classes from "./index.module.scss";

export default function WhatWeTalkAbout({ whatToTalkAbout, type, className="" }) {
  const renderTitle = (type) =>{
    switch (type) {
      case "live":
        return "Key course features"    
      default:
        return "What we’ll talk about"
    }
  }
  if (!whatToTalkAbout || !whatToTalkAbout.length) {
    return null;
  }
  return (
    <Box className={`${classes.whatToLearn} ${className}`}>
      <Typography
        component="h2"
        variant="h2"
        className={classes.whatToLearn__title}
      >
        {renderTitle(type)}
      </Typography>
      <ul className={classes.whatToLearn__list}>
        {whatToTalkAbout.map((item, index) => (
          <li key={index} className={classes.whatToLearn__item}>
            <Box className={classes.whatToLearn__icon}>
              <Icon />
            </Box>
            <Typography className={classes.whatToLearn__text}>
              {item}
            </Typography>
          </li>
        ))}
      </ul>
    </Box>
  );
}
