// component landing Hero (EU)

const MIXPANEL_LANDING_HERO_DEFAULT = {
  page: "Landing",
  section: "Hero",
};

const mixpanelLandingHero = {
  startForFreeLink: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_HERO_DEFAULT,
      name: "Start for Free",
      component: "Intro",
      element: "Link",
      elementInner: "Start for Free",
    },
  },
  startForFreeAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_HERO_DEFAULT,
      name: "Start for Free",
      component: "Intro",
      element: "Anchor",
      elementInner: "Start for Free",
    },
  },
  watchVideo: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_HERO_DEFAULT,
      name: "Watch Video",
      component: "Intro",
      element: "Button",
      elementInner: "Watch Video",
    },
  },
};

// component landing Crushing Interview (EU)
export const MIXPANEL_LANDING_CRUSHING_INTERVIEW_DEFAULT = {
  page: "Landing",
  section: "Crushing Interview",
};

const mixpanelLandingCrushingInterview = {
  crushInterviewAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_CRUSHING_INTERVIEW_DEFAULT,
      name: "Crush interview",
      component: "Content",
      element: "Anchor",
      elementInner: "Crush interview",
    },
  },
};

// component landing Individual mentorship (EU)
export const MIXPANEL_LANDING_INDIVIDUAL_MENTORSHIP_DEFAULT = {
  page: "Landing",
  section: "Individual mentorship",
};

const mixpanelLandingIndividualMentorShip = {
  watchIntroductionVideo: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_INDIVIDUAL_MENTORSHIP_DEFAULT,
      name: "Watch introduction video",
      component: "Image",
      element: "Button",
      elementInner: "Watch introduction video",
    },
  },
  enrollAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_INDIVIDUAL_MENTORSHIP_DEFAULT,
      name: "Enroll",
      component: "Content",
      element: "Anchor",
      elementInner: "Enroll",
    },
  },
};

// component landing 24/7 Discord Support (EU)
export const MIXPANEL_LANDING_DISCORD_SUPPORT_DEFAULT = {
  page: "Landing",
  section: "24/7 Discord Support",
};

const mixpanelLandingDiscordSupport = {
  discordAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_DISCORD_SUPPORT_DEFAULT,
      name: "Join our Discord",
      component: "Content",
      element: "Anchor",
      elementInner: "Join our Discord",
    },
  },
};

// component landing Why join us (EU)
export const MIXPANEL_LANDING_WHY_JOIN_US_DEFAULT = {
  page: "Landing",
  section: "Why join us",
};

const mixpanelLandingWhyJoinUs = {
  startForFreeLink: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_WHY_JOIN_US_DEFAULT,
      name: "Start for Free",
      component: "Content",
      element: "Link",
      elementInner: "Start for Free",
    },
  },
  startForFreeAnchor: {
    eventName: "Click",
    options: {
      ...MIXPANEL_LANDING_WHY_JOIN_US_DEFAULT,
      name: "Start for Free",
      component: "Content",
      element: "Anchor",
      elementInner: "Start for Free",
    },
  },
};

export {
  mixpanelLandingHero,
  mixpanelLandingCrushingInterview,
  mixpanelLandingIndividualMentorShip,
  mixpanelLandingDiscordSupport,
  mixpanelLandingWhyJoinUs,
};
