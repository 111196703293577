import { useTheme } from "@emotion/react";
import { Box, Container, Stack } from "@mui/material";
import { NavLink } from "react-router-dom";
import { ReactComponent as Logo } from "../../../../assets/images/logo-light.svg";
import { ReactComponent as Phone } from "../../../../assets/images/phone.svg";
import { ReactComponent as Mail } from "../../../../assets/images/mail.svg";
import { Text } from "../../../MUI";
import classes from "./index.module.scss";
import { scrollToSection } from "../../../../helpers/scrollToSection";
import { HEADER_HEIGHT } from "../../../../constants/layout";

export default function Footer() {
  const theme = useTheme();
  const year = new Date().getFullYear();
  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg" className={classes.body}>
        <Box>
          <NavLink to="/">
            <Logo />
          </NavLink>

          <Text
            variant="text-12"
            sx={() => ({
              color: theme.palette["white-70"],
              mt: 1.5,
            })}
          >
            {year} Engenious Inc. (c) All rights reserved
          </Text>
        </Box>
        <Box className={classes.menu}>
          <Stack className={classes.menu__item}>
            {/* <a
              href={`#courses`}
              onClick={(e) => {
                scrollToSection(e, `courses`, HEADER_HEIGHT);
              }}
              className={classes.menu__link}
            >
              <Text
                variant="text-14"
                sx={() => ({
                  color: theme.palette["white"],
                })}
              >
                Courses
              </Text>
            </a> */}
            <a
              className={classes.menu__link}
              href="https://engenious.io/events"
              target="_blank"
              rel="noreferrer"
            >
              <Text
                variant="text-14"
                sx={() => ({
                  color: theme.palette["white"],
                })}
              >
                Events
              </Text>
            </a>
            <a
              className={classes.menu__link}
              href={process.env.REACT_APP_DISCORD_LINK}
              target="_blank"
              rel="noreferrer"
            >
              <Text
                variant="text-14"
                sx={() => ({
                  color: theme.palette["white"],
                })}
              >
                Community
              </Text>
            </a>
          </Stack>
          <Stack className={classes.menu__item}>
            <a
              className={classes.menu__link}
              href={`mailto:${process.env.REACT_APP_ENGENIOUS_EMAIL}`}
            >
              <Text
                variant="text-14"
                sx={() => ({
                  color: theme.palette["white"],
                })}
              >
                Contact us
              </Text>
            </a>
            <NavLink className={classes.menu__link} to="/privacy-policy">
              <Text
                variant="text-14"
                sx={() => ({
                  color: theme.palette["white"],
                })}
              >
                Privacy Policy
              </Text>
            </NavLink>
            <NavLink className={classes.menu__link} to="/terms-and-conditions">
              <Text
                variant="text-14"
                sx={() => ({
                  color: theme.palette["white"],
                })}
              >
                Terms and Conditions
              </Text>
            </NavLink>
          </Stack>
          <Stack className={classes.menu__contacts}>
            <Stack direction="row" sx={{ gap: 1.5 }}>
              <a
                href={process.env.REACT_APP_YOUTUBE_LINK}
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    d="M21.8431 7.20301C21.7292 6.78041 21.5066 6.39501 21.1975 6.08518C20.8883 5.77534 20.5034 5.55187 20.0811 5.43701C18.5151 5.00701 12.2501 5.00001 12.2501 5.00001C12.2501 5.00001 5.98609 4.99301 4.41909 5.40401C3.99701 5.52415 3.61291 5.75078 3.30365 6.06214C2.9944 6.3735 2.77037 6.75913 2.65309 7.18201C2.24009 8.74801 2.23609 11.996 2.23609 11.996C2.23609 11.996 2.23209 15.26 2.64209 16.81C2.87209 17.667 3.54709 18.344 4.40509 18.575C5.98709 19.005 12.2351 19.012 12.2351 19.012C12.2351 19.012 18.5001 19.019 20.0661 18.609C20.4886 18.4943 20.8738 18.2714 21.1837 17.9622C21.4936 17.653 21.7175 17.2682 21.8331 16.846C22.2471 15.281 22.2501 12.034 22.2501 12.034C22.2501 12.034 22.2701 8.76901 21.8431 7.20301ZM10.2461 15.005L10.2511 9.00501L15.4581 12.01L10.2461 15.005Z"
                    fill="white"
                  />
                </svg>
              </a>
              <a
                href={process.env.REACT_APP_FACEBOOK_LINK}
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    d="M12.251 2.00195C6.72895 2.00195 2.25195 6.47895 2.25195 12.001C2.25195 16.991 5.90795 21.127 10.689 21.88V14.892H8.14895V12.001H10.689V9.79795C10.689 7.28995 12.182 5.90695 14.465 5.90695C15.559 5.90695 16.705 6.10195 16.705 6.10195V8.56095H15.441C14.201 8.56095 13.813 9.33295 13.813 10.124V11.999H16.584L16.141 14.89H13.813V21.878C18.594 21.129 22.25 16.992 22.25 12.001C22.25 6.47895 17.773 2.00195 12.251 2.00195Z"
                    fill="white"
                  />
                </svg>
              </a>
              <a
                href={process.env.REACT_APP_DISCORD_LINK}
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    d="M19.5201 5.33005C18.1901 4.71005 16.7501 4.26005 15.2501 4.00005C15.237 3.99963 15.2239 4.00209 15.2118 4.00728C15.1997 4.01246 15.1889 4.02023 15.1801 4.03005C15.0001 4.36005 14.7901 4.79005 14.6501 5.12005C13.0591 4.88005 11.4411 4.88005 9.85012 5.12005C9.71012 4.78005 9.50012 4.36005 9.31012 4.03005C9.30012 4.01005 9.27012 4.00005 9.24012 4.00005C7.74012 4.26005 6.31012 4.71005 4.97012 5.33005C4.96012 5.33005 4.95012 5.34005 4.94012 5.35005C2.22012 9.42005 1.47012 13.38 1.84012 17.3C1.84012 17.32 1.85012 17.34 1.87012 17.35C3.67012 18.67 5.40012 19.47 7.11012 20C7.14012 20.01 7.17012 20 7.18012 19.98C7.58012 19.43 7.94012 18.85 8.25012 18.24C8.27012 18.2 8.25012 18.16 8.21012 18.15C7.64012 17.93 7.10012 17.67 6.57012 17.37C6.53012 17.35 6.53012 17.29 6.56012 17.26C6.67012 17.18 6.78012 17.09 6.89012 17.01C6.91012 16.99 6.94012 16.99 6.96012 17C10.4001 18.57 14.1101 18.57 17.5101 17C17.5301 16.99 17.5601 16.99 17.5801 17.01C17.6901 17.1 17.8001 17.18 17.9101 17.27C17.9501 17.3 17.9501 17.36 17.9001 17.38C17.3801 17.69 16.8301 17.94 16.2601 18.16C16.2201 18.17 16.2101 18.22 16.2201 18.25C16.5401 18.86 16.9001 19.44 17.2901 19.99C17.3201 20 17.3501 20.01 17.3801 20C19.1001 19.47 20.8301 18.67 22.6301 17.35C22.6501 17.34 22.6601 17.32 22.6601 17.3C23.1001 12.77 21.9301 8.84005 19.5601 5.35005C19.5501 5.34005 19.5401 5.33005 19.5201 5.33005ZM8.77012 14.91C7.74012 14.91 6.88012 13.96 6.88012 12.79C6.88012 11.62 7.72012 10.67 8.77012 10.67C9.83012 10.67 10.6701 11.63 10.6601 12.79C10.6601 13.96 9.82012 14.91 8.77012 14.91ZM15.7401 14.91C14.7101 14.91 13.8501 13.96 13.8501 12.79C13.8501 11.62 14.6901 10.67 15.7401 10.67C16.8001 10.67 17.6401 11.63 17.6301 12.79C17.6301 13.96 16.8001 14.91 15.7401 14.91Z"
                    fill="white"
                  />
                </svg>
              </a>
            </Stack>
            <Stack direction="row" sx={{ gap: 1 }}>
              <Box>
                <Phone />
              </Box>
              <a
                className={classes.menu__link}
                href={`tel:${process.env.REACT_APP_ENGENIOUS_PHONE_NUMBER}`}
              >
                <Text
                  variant="text-14"
                  sx={() => ({
                    color: theme.palette["white"],
                  })}
                >
                  {process.env.REACT_APP_ENGENIOUS_PHONE_MASK}
                </Text>
              </a>
            </Stack>
            <Stack direction="row" sx={{ gap: 1 }}>
              <Box>
                <Mail />
              </Box>
              <a
                className={classes.menu__link}
                href={`mailto:${process.env.REACT_APP_ENGENIOUS_EMAIL}`}
              >
                <Text
                  variant="text-14"
                  sx={() => ({
                    color: theme.palette["white"],
                  })}
                >
                  university@engenious.io
                </Text>
              </a>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </footer>
  );
}
