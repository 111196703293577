import { Avatar, Box, Typography } from "@mui/material";
import user from "../../../../../assets/images/user.svg";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import classes from "./index.module.scss";

import { useTheme } from "@emotion/react";
import ShowMore from "../../../../../components/Common/ShowMore";

export default function Slide({ instructor }) {
  const theme = useTheme();

  return (
    <Box className={classes.card}>
      <Box className={classes.card__header}>
        <Avatar
          alt={instructor?.fullName || "Instructor name"}
          src={instructor?.photoUrl || user}
          sx={{ width: 55, height: 55 }}
          variant="square"
          className={classes.avatar}
        />
        <Box className={classes.info}>
          <Typography
            component="h3"
            sx={{
              color: theme.palette.dark,
              fontWeight: 700,
              fontSize: "1rem",
              lineHeight: 1.2,
            }}
          >
            {instructor?.fullName || "Instructor name"}
          </Typography>
          <Typography
            sx={{
              color: theme.palette["dark-70"],
              fontSize: "0.75rem",
              lineHeight: 1.2,
            }}
          >
            {instructor?.position || "Instructor position"}
          </Typography>
          <Box className={classes.rating}>
            <StarRoundedIcon
              sx={{
                color: theme.palette.yellow,
                fontSize: "0.875rem",
                lineHeight: 1.2,
              }}
            />
            <Typography
              sx={{
                color: theme.palette.yellow,
                fontSize: "0.75rem",
                lineHeight: 1.2,
              }}
            >
              {instructor?.rating} Instructor Rating
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        {instructor.introductionText ? (
          <ShowMore maxHeight={120}>
            <Typography
              sx={{ color: theme.palette["dark-70"], fontSize: "0.875rem" }}
            >
              {instructor.introductionText}
            </Typography>
          </ShowMore>
        ) : null}
      </Box>
    </Box>
  );
}
