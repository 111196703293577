import { createTheme } from "@mui/material/styles";

export const theme = () =>
  createTheme({
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: "var(--biotif)",
            fontWeight: 700,
            lineHeight: "1.375",
          },
        },
        defaultProps: {
          variantMapping: {
            "title-64": "h1",
            "title-48": "h2",
            "title-40": "h3",
            "title-24": "h3",
            "title-24-const": "h3",
            "title-32": "h3",
            "title-28": "h4",
            "title-20": "h4",
            "title-20-const": "h4",
          },
        },
      },
    },
    typography: {
      "title-64": {
        fontSize: "clamp(2.5rem, 0.7857rem + 3.5714vw, 4rem)",
      },
      "title-48": {
        fontSize: "clamp(2rem, 0.8571rem + 2.381vw, 3rem)",
      },
      "title-40": {
        fontSize: "clamp(1.625rem, 0.625rem + 2.0833vw, 2.5rem)",
      },
      "title-40-const": {
        fontSize: "2.5rem",
      },
      "title-32": {
        fontSize: "2rem",
      },
      "title-28": {
        fontSize: "clamp(1.25rem, 0.6786rem + 1.1905vw, 1.75rem)",
      },
      "title-24": {
        fontSize: "1.5rem",
      },
      "title-24-const": {
        fontSize: "1.5rem",
      },
      "title-20-const": {
        fontSize: "1.25rem",
      },
      "title-20": {
        fontSize: "1.25rem",
      },
    },
  });
