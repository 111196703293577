import { Box, Container } from "@mui/material";
import { Title } from "../../../components/MUI";
import classes from "./index.module.scss";
import { useTheme } from "@emotion/react";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import React from "react";
import Loader from "../../../components/Common/Loader";
import PathSlide from "../../../components/MUI/cards/PathSlide";
import { PlatformLayout } from "../../../components/Layouts";

export default function Paths() {
  const theme = useTheme();

  const { data: pathsData, isLoading } = useQuery(["paths", "registered"], () =>
    api.get(process.env.REACT_APP_PATHS_UNREGISTERED_PATH)
  );

  return (
    <PlatformLayout>
      {isLoading ? <Loader /> : null}
      <Container disableGutters maxWidth={false} className={classes.container}>
        <Box className={classes.wrapper}>
          <Title
            variant="title-40-const"
            sx={{ color: theme.palette.dark }}
            component="h1"
          >
            Paths
          </Title>

          <Box
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              gap: "1.5rem",
            }}
          >
            {pathsData && !isLoading
              ? pathsData.data.paths.map((path) => (
                  <PathSlide key={path.id} slide={path} />
                ))
              : null}
            {!pathsData && !isLoading ? (
              <Box>There are no paths yet</Box>
            ) : null}
          </Box>
        </Box>
      </Container>
    </PlatformLayout>
  );
}
