import { Box, IconButton } from "@mui/material";
import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { handleNext, handlePrev } from "../../../../helpers/carouselControls";
import classes from "./index.module.scss";

export default function CustomNavigation({ sliderRef }) {
  return (
    <Box className={classes.navigation}>
      <IconButton
        className={classes.button}
        onClick={() => handlePrev(sliderRef)}
      >
        <ChevronLeftIcon sx={{ color: "var(--accent)", fontSize: "1rem" }} />
      </IconButton>
      <IconButton
        className={classes.button}
        onClick={() => handleNext(sliderRef)}
      >
        <ChevronRightIcon sx={{ color: "var(--accent)", fontSize: "1rem" }} />
      </IconButton>
    </Box>
  );
}
