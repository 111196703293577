import React from "react";
import { Box, Drawer } from "@mui/material";
import classes from "./index.module.scss";
import { DRAWER_WIDTH } from "../../../../../constants/layout";

export default function MobileMenu({
  children,
  mobileOpen,
  handleDrawerToggle,
  window,
}) {
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Box component="nav">
      <Drawer
        className={classes["navigation-mobile"]}
        container={container}
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: false,
        }}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            maxWidth: DRAWER_WIDTH,
            width: "100%",
            backgroundColor: "var(--on-surface-a-100)",
          },
        }}
      >
        {children}
      </Drawer>
    </Box>
  );
}
