import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import useAppSize from "../../../../../hooks/useAppSize";
import DesktopMenu from "./DesktopMenu";
import BurgerButton from "../../common/BurgerButton";
import Drawer from "./Drawer";
import { ReactComponent as UniversityLogo } from "../../../../../assets/images/logo.svg";
import { ReactComponent as Discord } from "../../../../../assets/images/discord.svg";
import { useLocation } from "react-router-dom";

import { Box, Container } from "@mui/material";
import { WhiteButton } from "../../../../MUI/buttons/button";
import classes from "./index.module.scss";
import Logo from "../../common/Logo";
import MobileMenu from "../../common/MobileMenu";

export default function Header({ auth }) {
  const { pathname } = useLocation();
  const { width: windowWidth } = useAppSize();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navItems = [
    {
      id: "courses",
      name: "Courses",
    },
    {
      id: "free-resources",
      name: "Free resources",
    },
    {
      id: "testimonials",
      name: "Testimonials",
    },
  ];

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  React.useEffect(() => {
    windowWidth && windowWidth > 1152 && mobileOpen && handleDrawerClose();
  }, [windowWidth, mobileOpen]);

  return (
    <>
      <AppBar
        component="header"
        className={classes.header}
        sx={{
          backgroundColor: "var(--on-surface-a-100)",
          boxShadow: "none",
        }}
        id="header"
      >
        <Container maxWidth={"lg"}>
          <Toolbar
            sx={{
              minHeight: "unset !important",
              padding: "0 !important",
            }}
          >
            <Logo>
              <UniversityLogo />
            </Logo>

            <DesktopMenu
              navItems={pathname === "/" ? navItems : null}
              auth={auth}
            />

            <WhiteButton
              variant="outlined"
              component={"a"}
              startIcon={<Discord />}
              href={process.env.REACT_APP_DISCORD_LINK}
              target="_blank"
              rel="noreferrer"
              className={classes["discord-mobile"]}
              isBig={false}
            >
              Discord
              <span className={classes["discord-mobile__text"]}>
                &nbsp;Community
              </span>
            </WhiteButton>
            <Box className={classes.burger}>
              <BurgerButton handleDrawerToggle={handleDrawerToggle}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M3 12H21M3 6H21M9 18H21"
                    stroke="#3D40D5"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </BurgerButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <MobileMenu
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      >
        <Drawer
          navItems={pathname === "/" ? navItems : null}
          handleDrawerToggle={handleDrawerToggle}
          auth={auth}
        />
      </MobileMenu>
    </>
  );
}
