import { Button } from "../../MUI";
import { ReactComponent as Icon } from "../../../assets/images/chat.svg";
import classes from "./index.module.scss";

export default function LiveChatButton() {
  const handleClick = () => {
    window.LiveChatWidget.call("maximize");
  };

  return (
    <>
      <Button
        onClick={handleClick}
        variant="outlined"
        className={`${classes.button} ${classes.button__desktop}`}
        startIcon={<Icon />}
      >
        Live chat
      </Button>
      <Button
        onClick={handleClick}
        variant="outlined"
        className={`${classes.button} ${classes.button__mobile}`}
      >
        <Icon />
      </Button>
    </>
  );
}
