import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import { ReactComponent as Image } from "../../../assets/images/auth.svg";
import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTheme } from "@emotion/react";
import classes from "./index.module.scss";
import { RedirectContext } from "../../../context/RedirectContext";

export default function AuthLayout() {
  const theme = useTheme();
  const { removeCurrentCourse } = useContext(RedirectContext);

  const [link, setLink] = useState(null);
  const [isLoginVisible, setIsLoginVisible] = useState(true);
  const [isRegistrationLinkActive, setIsRegistrationLinkActive] =
    useState(true);
  const [isNavigationVisible, setIsNavigationVisible] = useState(true);
  const [title, setTitle] = useState("");

  return (
    <Box className={classes.container}>
      <Box className={classes.body}>
        <Box className={classes.content}>
          <Link to={"/"} className={classes.logo} onClick={removeCurrentCourse}>
            <Logo />
          </Link>
          {link ? (
            <Link to={link} className={classes.link}>
              <ArrowBackIcon sx={{ color: theme.palette.dark }} />
            </Link>
          ) : null}
          {isNavigationVisible ? (
            <nav className={classes.nav}>
              <NavLink
                onClick={(e) =>
                  isRegistrationLinkActive ? false : e.preventDefault()
                }
                className={classes.nav__link}
                to="/registration"
              >
                Registration
              </NavLink>
              {isLoginVisible ? (
                <NavLink className={classes.nav__link} to="/login">
                  Login
                </NavLink>
              ) : null}
            </nav>
          ) : (
            <h1 className={classes.title}>{title}</h1>
          )}
          <Outlet
            context={{
              setLink,
              setIsLoginVisible,
              isLoginVisible,
              isRegistrationLinkActive,
              setIsRegistrationLinkActive,
              isNavigationVisible,
              setIsNavigationVisible,
              title,
              setTitle,
            }}
          />
        </Box>
        <Box className={classes.image}>
          <Image />
        </Box>
      </Box>
    </Box>
  );
}
