import { ReactComponent as Icon } from "../../../../../assets/images/zondicons_play-outline.svg";
import { Box, Typography } from "@mui/material";
import classes from "./index.module.scss";

export const Chip = () => {
  return (
    <Box className={classes.chip}>
      <Icon className={classes.chip__icon}/>
      <Typography className={classes.chip__text}>Recorded Course</Typography>
    </Box>
  );
};
