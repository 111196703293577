import * as React from "react";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import { Button } from "../../buttons";
import { ReactComponent as FilesIcon } from "../../../../assets/images/resources.svg";
import { ReactComponent as FilesIconBlue } from "../../../../assets/images/resources-blue.svg";
import { ReactComponent as FilesIconDark } from "../../../../assets/images/resources-dark.svg";
import CloseIcon from "@mui/icons-material/Close";
// import ClickAwayListener from "@mui/base/ClickAwayListener";
import { ClickAwayListener, Typography } from "@mui/material";
import classes from "./index.module.scss";
import { useTheme } from "@emotion/react";
import LockIcon from "@mui/icons-material/Lock";

export default function AdditionalResourcesPopper({ resources, isPaid }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? resources.id : undefined;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FilesIcon />}
          sx={{
            fontSize: "0.874rem",
            padding: "8px 16px",
            minHeight: "33px",
            position: "relative",
          }}
          aria-describedby={id}
          onClick={handleClick}
        >
          Additional Resources
        </Button>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          className={classes.popper}
          placement="top-start"
          disablePortal={true}
          modifiers={[
            {
              name: "flip",
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: "document",
                padding: 8,
              },
            },
            {
              name: "preventOverflow",
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary: "document",
                padding: 8,
              },
            },
            {
              name: "arrow",
              enabled: false,
            },
          ]}
        >
          <Box className={classes.content}>
            <Box className={classes.content__header}>
              <Typography
                component="h4"
                sx={{
                  fontWeight: 700,
                  color: theme.palette.accent,
                  fontSize: "0.875rem",
                  lineHeight: 1.21,
                  flexGrow: 1,
                }}
              >
                <FilesIconBlue className={classes["content__header-icon"]} />{" "}
                Additional Resources
              </Typography>
              <CloseIcon
                onClick={handleClose}
                sx={{ cursor: "pointer", fontSize: 16 }}
              />
            </Box>

            <ul className={classes.content__list}>
              {resources.map((resource) => (
                <li key={resource.id} className={classes.content__item}>
                  {resource.fileUrl ? (
                    isPaid || resource.isFree ? (
                      <Typography
                        className={classes["content__item-link"]}
                        download
                        href={resource.fileUrl}
                        component="a"
                        sx={{
                          fontWeight: 700,
                          color: "#42444D",
                          fontSize: "0.875rem",
                          lineHeight: 1.21,
                          textDecoration: "none",
                        }}
                      >
                        <FilesIconDark
                          className={classes["content__item-icon"]}
                        />
                        {resource?.title ? resource?.title : "Download file"}
                        
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontWeight: 700,
                          color: "#42444D",
                          fontSize: "0.875rem",
                          lineHeight: 1.21,
                          opacity: 0.7,
                        }}
                        className={classes["content__item-link_disabled"]}
                      >
                        <FilesIconDark
                          className={classes["content__item-icon"]}
                        />
                        {resource?.title ? resource?.title : "Download file"}
                        
                        <LockIcon
                          sx={{
                            fontSize: 16,
                            marginLeft: 0.5,
                          }}
                        />
                      </Typography>
                    )
                  ) : null}
                  {resource.description ? (
                    <Typography
                      sx={{
                        color: "#292929",
                        fontSize: "0.75rem",
                        lineHeight: 1.3,
                      }}
                      className={classes["content__item-description"]}
                    >
                      {resource.description}
                    </Typography>
                  ) : null}
                </li>
              ))}
            </ul>
          </Box>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
}
