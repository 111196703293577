import { useContext, useEffect, useRef } from "react";
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../../context/AuthContext";
import { useMutation } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import { Button } from "../../../components/MUI";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Input } from "../../../components/MUI/inputs";
import { Box } from "@mui/system";
import classes from "./index.module.scss";
import errorToast from "../../../components/toasts/errorToast";
import { IconButton, InputAdornment } from "@mui/material";
import useShowPassword from "../../../hooks/useShowPassword";
import ShowPasswordIcon from "../../../components/Common/ShowPasswordIcon";
import { PASSWORD_REGEX } from "../../../constants/regex";

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .matches(
      PASSWORD_REGEX,
      "Your password should have minimum eight characters, at least one uppercase letter, one lowercase letter and one digit"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Repeat password is required"),
});

const CreateForgottenPassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { setAuthUser, setAccessToken } = useContext(AuthContext);
  const { setLink, setIsNavigationVisible, setTitle } = useOutletContext();

  const { handleClickShowPassword, showPassword } = useShowPassword();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { mutate, isLoading } = useMutation(
    (data) => api.post("/auth/resetPassword", data),
    {
      onSuccess: ({ data }) => {
        setAuthUser(data);
      },
      onError: (error) => {
        errorToast(error.response.data.message);
      },
    }
  );

  const onSubmit = (data) => {
    delete data.confirmPassword;
    mutate(data);
  };

  useEffect(() => {
    setLink(null);
    setIsNavigationVisible(false);
    setTitle("Create new password");

    return () => {
      setLink(null);
      setIsNavigationVisible(true);
      setTitle(null);
    };
  });

  useEffect(() => {
    searchParams.get("token")
      ? localStorage.setItem("accessToken", searchParams.get("token")) &&
        setAccessToken(searchParams.get("token"))
      : navigate("/forgot-password");
  }, [searchParams, navigate, setAccessToken]);

  const password = useRef({});
  password.current = watch("password", "");

  return (
    <Box className={classes.wrapper}>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Input
            label="New password"
            type={showPassword ? "text" : "password"}
            margin="normal"
            variant="outlined"
            fullWidth
            autoComplete="off"
            disabled={isLoading}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    <ShowPasswordIcon showPassword={showPassword} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
              disableAnimation: true,
            }}
            placeholder="Create new password"
            {...register("password")}
            error={!!errors.password}
            helperText={errors.password && errors.password.message}
          />
        </Box>
        <Box>
          <Input
            label="Repeat password"
            type={showPassword ? "text" : "password"}
            margin="normal"
            variant="outlined"
            fullWidth
            autoComplete="off"
            disabled={isLoading}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    <ShowPasswordIcon showPassword={showPassword} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
              disableAnimation: true,
            }}
            placeholder="Repeat new password"
            {...register("confirmPassword")}
            error={!!errors.confirmPassword}
            helperText={
              errors.confirmPassword && errors.confirmPassword.message
            }
          />
        </Box>

        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          type="submit"
          endIcon={<ChevronRightIcon />}
          disabled={isLoading}
          fullWidth
        >
          {isLoading ? "Loading..." : "Update password"}
        </Button>
      </form>
    </Box>
  );
};

export default CreateForgottenPassword;
