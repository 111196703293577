import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

export const ModuleAccordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `none`,
  backgroundColor: theme.palette.white,
  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
  borderRadius: "12px",

  "&:before": {
    display: "none",
  },
}));

export const ModuleAccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ExpandMoreIcon sx={{ fontSize: "1.5rem", color: "var(--accent)" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.white,
  flexDirection: "row-reverse",
  borderRadius: "12px",
  padding: "0 12px",

  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(0)",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export const ModuleAccordionDetails = styled(MuiAccordionDetails)(
  ({ theme }) => ({
    padding: theme.spacing(1.5),
    paddingTop: 0,
    paddingBottom: 0,
    border: "none",
  })
);

export const FAQAccordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `none`,
  backgroundColor: theme.palette.white,
  boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.1)",
  borderRadius: "24px",

  "&:before": {
    display: "none",
  },
}));

export const FAQAccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ExpandMoreIcon sx={{ fontSize: "1.5rem", color: "var(--accent)" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.white,
  flexDirection: "row",
  borderRadius: "24px",
  padding: "0 18px",

  "& .MuiAccordionSummary-content": {
    margin: "18px 0",
    transition: "margin-bottom 0.3s",
    marginRight: theme.spacing(3.875),
    "&.Mui-expanded": {
      marginBottom: "16px",
    },
  },

  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(0)",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
}));

export const FAQAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2.125),
  paddingTop: 0,
  border: "none",
}));

export const TasksAccordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `none`,
  backgroundColor: theme.palette.white,
  boxShadow: "none",
  borderRadius: 0,

  "&:before": {
    display: "none",
  },
}));

export const TasksAccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ExpandMoreIcon sx={{ fontSize: "1.5rem", color: "var(--accent)" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.white,
  flexDirection: "row",
  borderRadius: 0,
  padding: "0 0 0 28px",
  borderTop: "1px solid rgba(61, 64, 213, 0.1)",

  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(0)",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export const TasksAccordionDetails = styled(MuiAccordionDetails)(
  ({ theme }) => ({
    padding: "0 0 12px 36px",
    border: "none",
  })
);
