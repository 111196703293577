import { Box, Typography } from "@mui/material";
import classes from "./index.module.scss";

export default function WhoIsCourseFor({ whoIsCourseFor }) {
  if (!whoIsCourseFor) {
    return null
  }
  return (
    <Box className={classes.whoIsCourseFor}>
      <Typography
        component="h2"
        variant="h2"
        className={classes.whoIsCourseFor__title}
      >
        Who this course is for:
      </Typography>
      <Typography className={classes.whoIsCourseFor__text}>
        {whoIsCourseFor}
      </Typography>
    </Box>
  );
}
