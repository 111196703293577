import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Rating as MUIRating, Stack, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

import { theme } from "./theme";
import { useTheme } from "@emotion/react";

export default function Rating({ options, ...stack }) {
  const defaultTheme = useTheme();

  const {
    rating,
    votes,
    name = "half-rating-read",
    stars,
    precision = 0.1,
    fontSize="1.25rem",
    ...rest
  } = options;
  return (
    <ThemeProvider theme={theme(defaultTheme)}>
      <Stack {...stack}>
        {rating ? <Typography variant="body1">{rating}</Typography> : null}
        <MUIRating
          sx={{ fontSize: fontSize }}
          emptyIcon={
            <StarIcon
              style={{ opacity: 0.2, fill: "rgba(246, 195, 68)" }}
              fontSize="inherit"
            />
          }
          name={name}
          value={stars}
          precision={precision}
          {...rest}
        />
        {votes ? <Typography variant="body2">({votes})</Typography> : null}
      </Stack>
    </ThemeProvider>
  );
}
