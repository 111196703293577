import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from "@mui/material";
import { Chip, Rating } from "../../../../components/MUI";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTheme } from "@emotion/react";
import { PlayButton } from "../../../../components/MUI/buttons";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import VideoModal from "../../../../components/Common/EmbedVideos/VideoWrapper";
import React, { useContext } from "react";
import { ModalContext } from "../../../../context/ModalContext";
import { AuthContext } from "../../../../context/AuthContext";
import { Link, useLocation } from "react-router-dom";
import { Chip as CourseType } from "./Chip";
import Progress from "../../../../components/Common/Progress/Progress";
import classes from "./index.module.scss";
import CouponDetails from "../../../../components/modal/BuyCourseModal/CouponDetails";
import {
  AccentButton,
  WhiteButton,
} from "../../../../components/MUI/buttons/button";

export default function CourseCardRecorded({ data }) {
  const theme = useTheme();
  const { auth, setIsPayment } = React.useContext(AuthContext);
  const { handleModalOpen } = useContext(ModalContext);
  const { pathname } = useLocation();

  const showVideoModal = ({ title, url }) => {
    handleModalOpen(<VideoModal title={title} url={url} />);
  };

  const options = {
    rating: +data?.rating,
    votes: +data?.reviewsCount,
    stars: +data?.rating,
    readOnly: true,
  };

  if (!data) {
    return null;
  }

  return (
    <Box className={classes.container}>
      <Card className={classes.card}>
        <Box className={classes.card__wrapper}>
          {data.rating ? (
            <CardHeader
              className={classes.card__header}
              avatar={
                <Box className={classes.chip}>
                  <CourseType />
                  <Rating options={options} spacing={0.5} direction={"row"} />
                </Box>
              }
            />
          ) : null}
          <CardContent className={classes.card__content}>
            {data?.paths && data?.paths.length ? (
              <Typography
                className={classes.card__subtitle}
                color={theme.palette["accent-06"]}
              >
                Part of{" "}
                {data?.paths.map((path, index, self) => (
                  <React.Fragment key={path.id}>
                    <span>{path.title} Path</span>
                    {index + 1 !== self.length ? `, ` : ""}
                  </React.Fragment>
                ))}
              </Typography>
            ) : null}
            {data.title ? (
              <Typography
                component="h1"
                variant="h1"
                color={theme.palette.dark}
                className={classes.card__title}
              >
                {data.title}
              </Typography>
            ) : null}

            {data.shortDescription ? (
              <Typography color={theme.palette["dark-06"]}>
                {data.shortDescription}
              </Typography>
            ) : null}
            {data.tags.length ? (
              <Box className={classes.card__tags}>
                {data.tags.map((tag) => (
                  <Chip color="neutral" label={tag} key={tag} />
                ))}
              </Box>
            ) : null}
            {Number(data?.price) !== 0 &&
            data?.discountedPrice &&
            data?.coupon ? (
              <Box className={classes.coupon}>
                <CouponDetails
                  coupon={data?.coupon}
                  discountedPrice={data?.discountedPrice}
                  defaultPrice={data?.price}
                  isPriceVisible={true}
                />
              </Box>
            ) : null}
          </CardContent>
          <CardActions className={classes.card__actions}>
            {!data.isPaid && !Number(data?.price) ? (
              <AccentButton
                isBig={false}
                variant="contained"
                endIcon={<ChevronRightIcon />}
                component={Link}
                to={`/courses/${data.id}/lecture/preparation-tasks`}
              >
                Start Free Course
              </AccentButton>
            ) : null}
            {!data.isPaid && Number(data?.price) ? (
              <>
                {auth ? (
                  <AccentButton
                    isBig={false}
                    variant={"contained"}
                    endIcon={<ChevronRightIcon />}
                    onClick={() => {
                      localStorage.setItem("isPayment", true);
                      setIsPayment(true);
                    }}
                  >
                    Buy for $
                    {data?.discountedPrice ? data?.discountedPrice : data.price}
                  </AccentButton>
                ) : (
                  <AccentButton
                    isBig={false}
                    variant="contained"
                    endIcon={<ChevronRightIcon />}
                    component={Link}
                    to={"/registration"}
                    onClick={() => {
                      localStorage.setItem("redirectPath", pathname);
                      localStorage.setItem("isPayment", true);
                      setIsPayment(true);
                    }}
                  >
                    Buy for $
                    {data?.discountedPrice ? data?.discountedPrice : data.price}
                  </AccentButton>
                )}
                <WhiteButton
                  isBig={false}
                  variant="outlined"
                  endIcon={<ChevronRightIcon />}
                  component={Link}
                  to={`/courses/${data.id}/lecture/preparation-tasks`}
                >
                  Start for FREE
                </WhiteButton>
              </>
            ) : null}

            {auth && data?.isPaid ? (
              <>
                <AccentButton
                  isBig={false}
                  variant={"contained"}
                  endIcon={<ChevronRightIcon />}
                  component={Link}
                  to={`/courses/${data.id}/lecture/${
                    data?.currentLectureId
                      ? data.currentLectureId
                      : "preparation-tasks"
                  }`}
                >
                  Continue
                </AccentButton>
                <Progress course={data} />
              </>
            ) : null}
          </CardActions>
        </Box>
        <Box className={classes.mediaWrapper}>          <CardMedia className={classes.card__media}>
            <img src={data.promoImageUrl} alt={data.title} width="496" />
            {data.promoVideoUrl ? (
              <PlayButton
                aria-label="play"
                onClick={() =>
                  showVideoModal({
                    title: data.title,
                    url: data.promoVideoUrl,
                  })
                }
              >
                <PlayArrowIcon />
              </PlayButton>
            ) : null}
          </CardMedia>
        </Box>
      </Card>
    </Box>
  );
}
