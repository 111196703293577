import { createContext, useMemo, useState } from "react";
import { parseJwt } from "../helpers/parseJwt";
import { useQueryClient } from "@tanstack/react-query";
export const AuthContext = createContext();

export default function AuthContextProvider({ children }) {
  const queryClient = useQueryClient();

  const [auth, setAuth] = useState(false);
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [isPayment, setIsPayment] = useState(false);
  const [isFreePayment, setIsFreePayment] = useState(false);
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);

  const setAuthUser = ({ accessToken, refreshToken }) => {
    const user = parseJwt(accessToken);
    localStorage.setItem("accessToken", accessToken);
    refreshToken && localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("user", JSON.stringify(user));
    setAccessToken(accessToken);
    refreshToken && setRefreshToken(refreshToken);
    setUser(user);
    setAuth(true);
  };

  const removeAuthUser = () => {
    setAuth(false);
    setUser(null);
    setAccessToken(null);
    setRefreshToken(null);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
    queryClient.removeQueries(["course", "registered"]);
    queryClient.removeQueries(["courses", "registered"]);
    queryClient.removeQueries(["paths", "registered"]);
    queryClient.removeQueries(["course", "content", "registered"]);
    queryClient.removeQueries(["lecture", "registered"]);
    queryClient.removeQueries({ user, exact: true });
  };

  const value = useMemo(
    () => ({
      auth,
      user,
      accessToken,
      refreshToken,
      isAuthLoading,
      isPayment,
      isFreePayment,
      setIsFreePayment,
      setIsAuthLoading,
      setIsPayment,
      setUser,
      setAuth,
      setAuthUser,
      removeAuthUser,
      setAccessToken,
      setRefreshToken,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accessToken, auth, isAuthLoading, refreshToken, user, isPayment, isFreePayment]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
