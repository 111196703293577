import React from "react";
import { LandingLayout } from "../../../../components/Layouts";
import styles from "./index.module.scss";

function PrivacyPolicy() {
  return (
    <LandingLayout>
      <div className={styles.wrapper}>
        <div className={styles.textBlock}>
          <p>
            This Privacy Policy governs the manner in which Engenious Inc.
            collects, uses, maintains and discloses information collected from
            users (each, a “User”) of the Engenious Inc website (“Site”). This
            privacy policy applies to the Site and all products and services
            offered by Engenious Inc. We encourage you to read this Privacy
            Policy before using the Engenious Inc. or providing information to
            us. By using the Engenious Inc. or providing information to us, you
            acknowledge that we may collect, use and disclose your information
            in accordance with this Privacy Policy.
          </p>
        </div>
        <h2>PERSONAL INFORMATION</h2>
        <div className={styles.textBlock}>
          <h3>PERSONAL INFORMATION WE MAY COLLECT</h3>
          <p>
            “Personal Information” is information that identifies you as an
            individual. In addition to Personal Information, Other Information
            (as defined below) may be collected in connection with the Services;
            the collection, use and disclosure of Other Information is addressed
            separately below, under the heading “OTHER INFORMATION”. We and our
            third-party service providers may collect the following Personal
            Information from you:
          </p>
          <ul>
            <li>Name.</li>
            <li>Postal address.</li>
            <li>Telephone number.</li>
            <li>E-mail address.</li>
            <li>Information contained in Questionnaire on the website</li>
            <li>
              Other personally identifiable information about you and third
              parties that you may choose to transmit through the Services,
              including personally identifiable information contained within
              files, video or audio calls or text messages.
            </li>
          </ul>
          <p>
            {" "}
            We do not request, solicit or intend to collect any other personally
            identifiable information, and you should not disclose any such
            information in connection with the Services. IF YOU SUBMIT ANY
            PERSONAL INFORMATION OF OR RELATING TO THIRD PARTIES TO US OR OUR
            THIRD-PARTY SERVICE PROVIDERS IN CONNECTION WITH THE SERVICES, YOU
            REPRESENT THAT YOU HAVE THE AUTHORITY TO DO SO.
          </p>
        </div>
        <div className={styles.textBlock}>
          <h3>HOW WE MAY USE PERSONAL INFORMATION</h3>
          <p>
            We and our third-party service providers may use Personal
            Information:
          </p>
          <ul>
            <li>
              To respond to your inquiries and fulfill your requests, such as to
              send newsletters to you, complete your commercial transactions and
              operate the Services.
            </li>
            <li>
              To send to you administrative information, including information
              regarding the Services and changes to our terms, conditions and
              policies.
            </li>
            <li>
              To send to you marketing communications that we believe may be of
              interest to you, unless you opt-out in accordance with the
              “CHOICES AND ACCESS” section below.
            </li>
            <li>
              For our business purposes, such as quality assessment, data
              analysis, audits, developing new products, improving the Services,
              identifying usage trends and determining the effectiveness of our
              promotional campaigns.
            </li>
            <li>
              As we believe to be necessary or appropriate: (a) under applicable
              law, including laws outside your country of residence; (b) to
              comply with legal process; (c) to respond to requests from public
              or government authorities, including public or government
              authorities outside your country of residence; (d) to enforce our
              terms and conditions; (e) to protect our operations or those of
              any of our affiliates; (f) to protect our rights, privacy, safety
              or property, or that of our affiliates, service providers, you or
              others, including through credit-fraud protection and risk
              reduction; (g) at our discretion under emergency circumstances, to
              notify emergency services or your family members, personal
              representative or other individuals involved in your care of your
              location and condition; or (h) to allow us to pursue available
              remedies or limit the damages that we may sustain.
            </li>
          </ul>
        </div>
        <div className={styles.textBlock}>
          <h3>HOW PERSONAL INFORMATION MAY BE DISCLOSED</h3>
          <p>Your Personal Information may be disclosed:</p>
          <ul>
            <li>
              To our third-party service providers that may provide services
              such as hosting of the Services, data analysis, IT services and
              infrastructure, customer service, e-mail delivery, auditing,
              payment processing and other similar services. Please note that we
              may use cloud service providers in connection with the hosting of
              the Services and the storage of Personal Information, and we may
              have limited or no opportunity or ability to impose contractual
              restrictions on these and other service providers.
            </li>
            <li>
              By you, through messages (including files, video and audio calls
              and text messages), chats, message boards, forums, public profile
              pages, user directories and blogs and other functionality with
              which you are able to share or post information and materials.
              Please note that any information and materials that you post or
              disclose using such functionality will become public information,
              and may be available for other users of the Services and the
              general public to record, access, use, alter, manipulate or
              disclose to other individuals or social networking sites such as
              Facebook or YouTube. We are not responsible for such recording,
              access, use, alteration, manipulation or disclosure. We urge you
              to be very careful when deciding to disclose your Personal
              Information, or any other information, in connection with the
              Services.
            </li>
            <li>
              To a third party in the event of any reorganization, merger, sale,
              joint venture, assignment, transfer or other disposition of all or
              any portion of our business, assets or stock (including in
              connection with any bankruptcy or similar proceeding).
            </li>
            <li>
              As we believe to be necessary or appropriate: (a) under applicable
              law, including laws outside your country of residence; (b) to
              comply with legal process; (c) to respond to requests from public
              or government authorities, including public or government
              authorities outside your country of residence; (d) to enforce our
              terms and conditions; (e) to protect our operations or those of
              any of our affiliates; (f) to protect our rights, privacy, safety
              or property, or that of our affiliates, service providers, you or
              others, including through credit-fraud protection and risk
              reduction; (g) at our discretion under emergency circumstances, to
              notify emergency services or your family members, personal
              representative or other individuals involved in your care of your
              location and condition; or (h) to allow us to pursue available
              remedies or limit the damages that we may sustain.
            </li>
          </ul>
        </div>
        <h2>OTHER INFORMATION</h2>
        <div className={styles.textBlock}>
          <h3>HOW WE MAY COLLECT OTHER INFORMATION</h3>
          <p>
            We and our third-party service providers may collect Other
            Information in a variety of ways, including:
          </p>
          <ul>
            <li>
              Through your device: Certain information is collected
              automatically through your device, such as your operating system
              name and version, device manufacturer and model, language, and the
              name and version of the software application you are using. We may
              also generate a unique device identifier for use by Engenious to
              operate the Services.
            </li>
            <li>
              Through server log files: Your “IP Address” is a number that is
              automatically assigned to the device that you are using by your
              Internet Service Provider (ISP). An IP Address is identified and
              logged automatically in our server log files whenever a user uses
              the Services, along with the time of the use and the pages or
              areas visited within the Services. Collecting IP Addresses is
              standard practice on the Internet and is done automatically by
              many websites. We and our third-party service providers use IP
              Addresses for purposes such as helping to calculate usage levels
              of the Services diagnose server problems and administer the
              Services.
            </li>
            <li>
              Using cookies: Cookies allow a server to transfer data to a device
              for recordkeeping and other purposes. We and our third-party
              service providers use cookies and other technologies to, among
              other things, better serve you with more tailored information and
              facilitate your ongoing access to and use of the Services, as well
              as for online tracking purposes. If you do not want information
              collected through the use of cookies, there is a simple procedure
              in most devices that allows you to decline the use of cookies. If
              you choose to decline cookies, some or all of the features,
              functionality and promotions available through the Services may
              not be available to you.
            </li>
            <li>
              Using pixel tags and other similar technologies: Pixel tags (also
              known as web beacons and clear GIFs) and other similar
              technologies may be used in connection with some pages and areas
              of the Services and HTML-formatted e-mail messages to, among other
              things, track the actions of users of the Services and e-mail
              recipients, measure the success of our marketing campaigns and
              compile statistics about usage and response rates of the Services.
            </li>
            <li>
              From you: We may collect demographic information such as your
              location, as well as other information, such as your preferred
              means of communication, when you voluntarily provide such
              information to us. Unless combined with Personal Information, this
              information does not personally identify you or any other user of
              the Services.
            </li>
            <li>
              By aggregating information: Personal Information does not
              personally identify you or any other user of the Services. For
              example, we may aggregate Personal Information to calculate the
              percentage of our users who have a particular telephone area code.
            </li>
          </ul>
        </div>
        <div className={styles.textBlock}>
          <h3>HOW WE MAY USE AND DISCLOSE OTHER INFORMATION</h3>
          <p>
            Because Other Information does not personally identify you, we may
            use and disclose Other Information for any purpose. In some
            instances, we may combine Other Information with Personal
            Information (such as combining your zip code with your name). If we
            combine any Other Information with Personal Information, the
            combined information will be treated by us as Personal Information
            as long as it is so combined.
          </p>
        </div>
        <h2>THIRD-PARTY ADVERTISERS</h2>
        <div className={styles.textBlock}>
          <p>
            {" "}
            We may use third-party advertising companies to serve ads when you
            visit the Services. Please note that these companies may use
            information about your use of the Services to provide advertisements
            about goods and services that may be of interest to you. In the
            course of serving advertisements to the Services, these companies
            may place or recognize a unique cookie on your browser.
          </p>
        </div>
        <h2>SECURITY</h2>
        <div className={styles.textBlock}>
          <p>
            {" "}
            We seek to use within Engenious reasonable organizational, technical
            and administrative measures to protect Personal Information under
            our control. Unfortunately, no data transmission over the Internet
            or data storage system is guaranteed to be 100% secure. Please also
            be aware that we may use third-party cloud service providers that
            provide hosting, data storage and other services pursuant to
            standard terms and conditions that are generally non-negotiable;
            accordingly, although we seek to conduct reasonable due diligence on
            such service providers, we may be unable to impose contractual
            obligations on them with respect to the measures they use to protect
            Personal Information. We are not responsible for the data security
            measures of any third parties. If you have reason to believe that
            your interaction with us is no longer secure (for example, if you
            feel that the security of any account you might have with us has
            been compromised), please immediately notify us of the problem by
            contacting us in accordance with the “CONTACTING US” section below.
          </p>
        </div>
        <h2>CHOICES AND ACCESS</h2>
        <div className={styles.textBlock}>
          <h3>
            YOUR CHOICES REGARDING OUR USE AND DISCLOSURE OF YOUR PERSONAL
            INFORMATION FOR MARKETING PURPOSES:
          </h3>
          <p>
            We give you many choices regarding our use and disclosure of your
            Personal Information for marketing purposes. You may opt-out from:
            Receiving marketing-related e-mails from us based upon your Personal
            Information: If you no longer want to receive marketing-related
            e-mails from us on a going-forward basis based upon Personal
            Information collected pursuant to this Privacy Policy, you may
            opt-out of receiving such marketing-related e-mails by accessing the
            “E-mail Subscriptions” page on the Site, or by clicking the
            “unsubscribe” link that is included at the end of such
            marketing-related e-mails. We will try to comply with your
            request(s) as soon as reasonably practicable. Please note that if
            you opt-out as described above, we will not be able to remove your
            Personal Information from the databases of third parties with which
            we have already shared such information (i.e., to which we have
            already provided such information as of the date that we implement
            your opt-out request). Please also note that if you do opt-out of
            receiving marketing-related e-mails from us, we may still send
            administrative messages to you; you cannot opt-out from receiving
            administrative messages.
          </p>
        </div>
        <div className={styles.textBlock}>
          <h3>HOW YOU CAN ACCESS YOUR PERSONAL INFORMATION</h3>
          <p>
            If you would like to review or update your Personal Information that
            has been previously provided to us, you may do so by logging into
            your account within the Services, or, for certain Personal
            Information that is not reviewable through the Services, you may
            contact us in accordance with the “CONTACTING US” section below. We
            will try to comply with your request to update your Personal
            Information as soon as reasonably practicable. We are not
            responsible for changing information from the databases of third
            parties with whom we have already shared your Personal Information.
          </p>
        </div>
        <h2>RETENTION PERIOD</h2>
        <div className={styles.textBlock}>
          <p>
            We will retain your Personal Information for the period necessary to
            fulfill the purposes outlined in this Privacy Policy unless a longer
            retention period is required or allowed by law.
          </p>
        </div>
        <h2>CONTACTING US</h2>
        <div className={styles.textBlock}>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us by sending an e-mail to university@engenious.io. Please note that
            information you submit through e-mail may not be secure; so please
            do not include credit card information or other sensitive
            information in your messages to us.
          </p>
        </div>
      </div>
    </LandingLayout>
  );
}

export default PrivacyPolicy;
