import { useTheme } from "@emotion/react";
import { NavLink } from "react-router-dom";
import { Typography } from "@mui/material";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import LockIcon from "@mui/icons-material/Lock";
import { formatTimeMilliseconds } from "../../../../helpers/formatTime";
import AdditionalResourcesPopper from "../../../../components/MUI/poppers/AdditionalResourcesPopper";
import classes from "./index.module.scss";

export default function ModuleDetails({ course, module, setValue }) {
  const theme = useTheme();
  return (
    <>
      <ul className={classes.list}>
        {module.lectures.map((lecture) => {
          return (
            <li key={lecture.id} className={classes.list__item}>
              {lecture?.userLecture?.isComplete ? (
                <CheckBoxRoundedIcon
                  className={classes["list__item-checkbox"]}
                  sx={{
                    color: theme.palette.accent,
                    fontSize: 21,
                  }}
                />
              ) : null}
              {lecture?.isFree || course?.data.courses.isPaid ? (
                <NavLink
                  className={classes["list__item-title"]}
                  to={`/courses/${course?.data.courses.id}/lecture/${lecture.id}`}
                  onClick={() => (setValue ? setValue(1) : false)}
                >
                  {lecture.title}
                </NavLink>
              ) : (
                <Typography
                  component={NavLink}
                  className={classes["list__item-title"]}
                  to={`/courses/${course?.data.courses.id}/lecture/${lecture.id}`}
                >
                  <LockIcon
                    sx={{
                      color: "#000000",
                      opacity: 0.5,
                      fontSize: "1rem",
                      marginRight: 1.5,
                      marginBottom: -0.2,
                    }}
                  />
                  {lecture.title}
                </Typography>
              )}

              <Typography
                color={"#42444D"}
                className={classes["list__item-duration"]}
              >
                Watching time: {formatTimeMilliseconds(lecture.duration)}
              </Typography>
            </li>
          );
        })}
      </ul>
      {module.additionalResources && module.additionalResources.length ? (
        <AdditionalResourcesPopper
          resources={module.additionalResources}
          isPaid={course?.data.courses.isPaid}
        />
      ) : null}
    </>
  );
}
