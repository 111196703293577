import OurAlumniAboutUs from "./OurAlumniAboutUs";
import OurYouTubeVideos from "./OurYouTubeVideos";
import WhyEngeniousUniversity from "./WhyEngeniousUniversity";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import Courses from "./Courses";
import CrushingInterview from "./CrushingInterview";
import IndividualMentorship from "./IndividualMentorship";
import DiscordSupport from "./DiscordSupport";
import { Container } from "@mui/material";
import classes from "./index.module.scss";
import Hero from "./Hero";
import { LandingLayout } from "../../../components/Layouts";
import Loader from "../../../components/Common/Loader";

export default function Home() {
  const { isAuthLoading, auth } = useContext(AuthContext);
  const authState = auth ? "registered" : "unregistered";

  const options = {
    enabled: !isAuthLoading,
  };
  const { data, isLoading } = useQuery(
    ["landing", authState],
    () =>
      api.get(
        auth
          ? process.env.REACT_APP_LANDING_REGISTERED_PATH
          : process.env.REACT_APP_LANDING_UNREGISTERED_PATH
      ),
    options
  );

  return (
    <LandingLayout>
      {isLoading ? (
        <Loader />
      ) : (
        <Container
          maxWidth={false}
          disableGutters
          className={classes.container}
        >
          <Hero />
          {data?.data.landing.courses ? (
            <Courses courses={data?.data.landing.courses} />
          ) : null}
          <CrushingInterview />
          <IndividualMentorship />
          <DiscordSupport />
          {data?.data.landing.freeYoutubeVideos ? (
            <OurYouTubeVideos data={data?.data.landing.freeYoutubeVideos} />
          ) : null}
          {data?.data.landing.alumniFeedbacks ? (
            <OurAlumniAboutUs
              data={data?.data.landing.alumniFeedbacks}
              auth={auth}
            />
          ) : null}
          <WhyEngeniousUniversity />
        </Container>
      )}
    </LandingLayout>
  );
}
