import React, { useRef } from "react";
import Carousel from "./Carousel";
import { Box, Container, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactComponent as HatIcon } from "../../../../assets/images/alumni.svg";
import { Link } from "react-router-dom";
import classes from "./index.module.scss";
import { scrollToSection } from "../../../../helpers/scrollToSection";
import CarouselNavigation from "../../../../components/Common/CarouselNavigation";
import { WhiteButton } from "../../../../components/MUI/buttons/button";
import { HEADER_HEIGHT } from "../../../../constants/layout";

export default function OurAlumniAboutUs({ data, auth }) {
  
  const splideRef = useRef(null);

  if (!data) {
    return null;
  }

  return (
    <Container maxWidth="lg" className={classes.container} id="testimonials">
      <Box className={classes.body} component={"section"}>
        <Box className={classes.header}>
          <Typography component={"h2"} variant={"h2"} className={classes.title}>
            ⭐ Our alumni about us
          </Typography>
          <Box className={classes.navigation}>
            <CarouselNavigation splideRef={splideRef} variant={"contained"} />
          </Box>
        </Box>

        <Box className={classes.carousel}>
          <Carousel splideRef={splideRef} data={data} />
        </Box>

        <Box className={classes.footer}>
          <HatIcon className={classes.footer__icon} />
          <Typography
            component={"h2"}
            variant={"h2"}
            className={classes.footer__title}
          >
            Become one of our students now!
          </Typography>
          <Box className={classes.actions}>
            {auth ? (
              <WhiteButton
                variant="contained"
                color="secondary"
                component={Link}
                endIcon={<ChevronRightIcon />}
                fullWidth
                to="/courses"
              >
                Start for Free
              </WhiteButton>
            ) : (
              <WhiteButton
                variant="contained"
                color="secondary"
                component={"a"}
                href={`#courses`}
                onClick={(e) => {
                  scrollToSection(e, "courses", HEADER_HEIGHT);
                }}
                endIcon={<ChevronRightIcon />}
                fullWidth
              >
                Start for Free
              </WhiteButton>
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
