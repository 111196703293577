import { useContext, useRef } from "react";
import { ModalContext } from "../../../../context/ModalContext";
import { Swiper, SwiperSlide } from "swiper/react";
import CustomNavigation from "./CustomNavigation";
import { Box, Typography } from "@mui/material";
import VideoModal from "../../../../components/Common/EmbedVideos/VideoWrapper";
import "swiper/css";
import classes from "./index.module.scss";

export default function RelatedMaterials({ playlist }) {
  const sliderRef = useRef(null);
  const { handleModalOpen } = useContext(ModalContext);

  const showModal = ({ url, title }) => {
    handleModalOpen(<VideoModal title={title} url={url} />);
  };
  if (!playlist || !playlist?.length) {
    return null;
  }
  return (
    <Box className={classes.wrapper}>
      <Typography component="h2" variant="h2" className={classes.title}>
        Subscribe to our free Youtube channel
      </Typography>
      <CustomNavigation sliderRef={sliderRef} />
      <Box className={classes.swiper}>
        <Swiper slidesPerView={"auto"} spaceBetween={12} ref={sliderRef}>
          {playlist.map((video) => {
            return (
              <SwiperSlide
                className={classes.slide}
                key={video.id}
                onClick={() => {
                  showModal({
                    title: video.title,
                    url: `https://youtu.be/${video.videoId}`,
                  });
                }}
              >
                <img
                  className={classes.image}
                  src={video.thumbnail.url}
                  width={video.thumbnail.width}
                  height={video.thumbnail.height}
                  alt={video.title}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
    </Box>
  );
}
