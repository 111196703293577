import { ThemeProvider } from "@mui/material/styles";
import { Router } from "./Router";
import { CssBaseline } from "@mui/material";
import { theme } from "./assets/theme";
import useAppSize from "./hooks/useAppSize";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { AuthContext } from "./context/AuthContext";
import { useMutation, useQuery } from "@tanstack/react-query";
import { api } from "./instances/axiosInstances";
import Loader from "./components/Common/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import errorToast from "./components/toasts/errorToast";
import { ModalContext } from "./context/ModalContext";
import { Modal } from "./components/MUI";
import smoothscroll from "smoothscroll-polyfill";
import ScrollToTop from "./components/Common/ScrollToTop";
import { LiveChatWidget } from "@livechat/widget-react";
import QuestionnaireModal from "./components/modal/QuestionnaireModal";
import LiveChatButton from "./components/Common/LiveChatButton";
import { QuestionnairePopupContext } from "./context/QuestionnairePopupContext";

export default function App() {
  const { showModal, modalContent, handleModalClose } =
    useContext(ModalContext);
  const { user, setAuthUser, isAuthLoading, setIsAuthLoading, auth } =
    useContext(AuthContext);
  const { setCurrentUser } =
    useContext(QuestionnairePopupContext);

  const [isChatVisible, setIsChatVisible] = useState(false);

  function onVisibilityChanged(data) {
    switch (data.visibility) {
      case "maximized":
        setIsChatVisible(true);
        break;
      case "minimized":
        setIsChatVisible(false);
        break;
      case "hidden":
        setIsChatVisible(false);
        break;
      default:
        setIsChatVisible(false);
    }
  }

  const { mutate } = useMutation((data) => api.post("/auth/refresh", data), {
    onSuccess: ({ data }) => {
      setAuthUser(data);
      setIsAuthLoading(false);
    },
    onError: (error) => {
      errorToast(error.response.data.message, {
        autoClose: false,
        hideProgressBar: true,
      });

      setIsAuthLoading(false);
    },
  });

  useQuery(["user"], () => api.get(`/users/${user.id}`), {
    enabled: !!user,
    onSuccess:({data})=>{
      setCurrentUser(data?.users)}
  });

  useLayoutEffect(() => {
    setIsAuthLoading(!!localStorage.getItem("refreshToken"));
    localStorage.getItem("refreshToken") &&
      mutate({
        refreshToken: localStorage.getItem("refreshToken"),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  useAppSize();

  if (isAuthLoading) {
    return <Loader autoplay={false} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router
        isAuthenticated={auth}
        isAuthLoading={isAuthLoading}
        user={user}
      />
      <ToastContainer
        position="top-right"
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        theme="light"
        autoClose={5000}
      />
      <Modal open={showModal} handleClose={handleModalClose}>
        {modalContent}
      </Modal>
      <QuestionnaireModal />
      <ScrollToTop />
      {!isChatVisible ? <LiveChatButton /> : null}
      <LiveChatWidget
        onVisibilityChanged={onVisibilityChanged}
        license={process.env.REACT_APP_LIVECHAT_CLIENT_ID}
      />
    </ThemeProvider>
  );
}
