import Lottie from "lottie-react";
import classes from "./index.module.scss";
import animation from "./graduation-hat.json";
import { Container } from "@mui/material";

export default function Loader({ autoplay = true }) {
  return (
    <Container disableGutters maxWidth={false} className={classes.wrapper}>
      <Lottie
        animationData={animation}
        loop={true}
        autoplay={autoplay}
        className={classes.loader}
      />
    </Container>
  );
}
