import React from "react";
import { Box } from "@mui/material";
import { ReactComponent as Discord } from "../../../../../assets/images/discord-blue.svg";
import { ReactComponent as YouTube } from "../../../../../assets/images/youtube-blue.svg";
import { ReactComponent as Facebook } from "../../../../../assets/images/facebook-blue.svg";
import { WhiteButton } from "../../../../MUI/buttons/button";
import UserMenu from "../../common/UserMenu";
import classes from "./index.module.scss";

export default function DesktopMenu() {
  return (
    <Box
      component="nav"
      className={classes["navigation-desktop"]}
      sx={{
        gap: "1rem",
        alignItems: "center",
      }}
    >
      <Box display="flex" gap="1rem" alignItems="center">
        <WhiteButton
          isBig={false}
          variant="outlined"
          component={"a"}
          startIcon={<Discord />}
          href={process.env.REACT_APP_DISCORD_LINK}
          target="_blank"
          rel="noreferrer"
        >
          Discord Community
        </WhiteButton>
        <WhiteButton
          isBig={false}
          variant="outlined"
          component={"a"}
          startIcon={<YouTube />}
          href={process.env.REACT_APP_YOUTUBE_LINK}
          target="_blank"
          rel="noreferrer"
        >
          Our Youtube
        </WhiteButton>
        <WhiteButton
          isBig={false}
          variant="outlined"
          component={"a"}
          startIcon={<Facebook />}
          href={process.env.REACT_APP_FACEBOOK_LINK}
          target="_blank"
          rel="noreferrer"
        >
          Join us
        </WhiteButton>
      </Box>
      <UserMenu />
    </Box>
  );
}
