import { Box, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import classes from "./index.module.scss";

export default function WhatToLearn({ whatToLearn, type, className = "" }) {
  const renderTitle = (type) =>{
    switch (type) {
      case "live":
        return "Why you should sign up"    
      default:
        return "What you'll learn"
    }
  }
  if (!whatToLearn || !whatToLearn.length) {
    return null;
  }
  return (
    <Box className={`${classes.whatToLearn} ${className}`}>
      <Typography
        component="h2"
        variant="h2"
        className={classes.whatToLearn__title}
      >
        {renderTitle(type)}
      </Typography>
      <ul className={classes.whatToLearn__list}>
        {whatToLearn.map((item, index) => (
          <li key={index} className={classes.whatToLearn__item}>
            <Box className={classes.whatToLearn__icon}>
              <CheckIcon sx={{ color: "var(--accent)" }} />
            </Box>
            <Typography className={classes.whatToLearn__text}>
              {item}
            </Typography>
          </li>
        ))}
      </ul>
    </Box>
  );
}
