export const getYoutubePlaylistVideos = async (playlistId, apiKey) => {
    let nextPageToken = "";
    let videos = [];

    while (nextPageToken !== null) {
      const url = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet,contentDetails&maxResults=50&playlistId=${playlistId}&pageToken=${nextPageToken}&key=${apiKey}`;

      const response = await fetch(url);
      const data = await response.json();

      const videoIds = data.items.map((item) => item.contentDetails.videoId);
      const durations = await getVideoDurations(videoIds, apiKey);

      const items = data.items.map((item, index) => {
        const id = item.id;
        const description = item.snippet.description;
        const videoId = item.contentDetails.videoId;
        const duration = durations[index];
        const thumbnail = {
          url: item.snippet.thumbnails.medium.url,
          width: item.snippet.thumbnails.medium.width,
          height: item.snippet.thumbnails.medium.height,
        };
        const title = item.snippet.title;

        return { id, videoId, thumbnail, title, duration, description };
      });

      videos = videos.concat(items);

      nextPageToken = data.nextPageToken || null;
    }

    return videos;
  };

  const getVideoDurations = async (videoIds, apiKey) => {
    const idString = videoIds.join(",");
    const url = `https://www.googleapis.com/youtube/v3/videos?part=contentDetails&id=${idString}&key=${apiKey}`;

    const response = await fetch(url);
    const data = await response.json();

    const durations = data.items.map((item) => {
      const duration = item.contentDetails.duration;
      const durationInSeconds = parseDuration(duration);
      return durationInSeconds;
    });
    return durations;
  };

  const parseDuration = (duration) => {
    const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
    const hours = match[1] ? parseInt(match[1]) : 0;
    const minutes = match[2] ? parseInt(match[2]) : 0;
    const seconds = match[3] ? parseInt(match[3]) : 0;
    return hours * 3600 + minutes * 60 + seconds;
  };