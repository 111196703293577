import { Box, Typography } from "@mui/material";
import CourseCardRecorded from "../CourseCardRecorded";
import {
  formatTimeMilliseconds,
  getLecturesCount,
  getLecturesDuration,
} from "../../../../helpers/formatTime";
import Tasks from "../Tasks";
import Modules from "../Modules";
import Description from "../Description";
import Reviews from "../Reviews";
import Requirements from "../Requirements";
import WhatToLearn from "../WhatToLearn";
import WhoIsCourseFor from "../WhoIsCourseFor";
import Instructors from "../Instructors";
import FAQ from "../FAQ";
import classes from "./index.module.scss";

export default function Recorded({ data }) {

  return (
    <Box className={classes.course}>
      <CourseCardRecorded data={data} />
      <Box className={classes.wrapper}>
        <Box className={classes.left}>
          <Box className={classes.content}>
            <Box className={classes.content__header}>
              <Typography
                component="h2"
                variant="h2"
                className={classes.content__title}
              >
                Course content
              </Typography>
              <Typography className={classes.content__subtitle}>
                {`${data.modules.length} ${
                  data.modules.length > 1 ? "sections" : "section"
                } • ${getLecturesCount(data.modules)} ${
                  getLecturesCount(data.modules) > 1 ? "lectures" : "lecture"
                } • ${formatTimeMilliseconds(
                  getLecturesDuration(data.modules)
                )} total length`}
              </Typography>
            </Box>
            <Tasks tasks={data.preparationTasks} />
            <Modules
              modules={data.modules}
              courseId={data.id}
              isPaid={data?.isPaid}
            />
          </Box>
          <Description description={data.description} />
          <Reviews
            alumniFeedbacks={data.alumniFeedbacks}
            rating={data.rating}
            className={classes.alumniFeedbacks__desktop}
          />
        </Box>

        <Box className={classes.right}>
          <Requirements requirements={data.requirements} />
          <WhatToLearn whatToLearn={data.whatToLearn} />
          <WhoIsCourseFor whoIsCourseFor={data.whoIsCourseFor} />
          <Instructors
            instructors={data.instructors}
            alumniFeedbacks={data.alumniFeedbacks}
          />
          <FAQ faq={data.frequentlyAskedQuestions} />
          <Reviews
            alumniFeedbacks={data.alumniFeedbacks}
            rating={data.rating}
            className={classes.alumniFeedbacks__mobile}
          />
        </Box>
      </Box>
    </Box>
  );
}
