import { useDeferredValue } from "react";
import CourseCard from "../../../../../components/MUI/cards/CourseCard";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Intersection } from "@splidejs/splide-extension-intersection";
import "@splidejs/react-splide/css";
import classes from "./index.module.scss";

export default function Carousel({ splideRef, data }) {
  const deferredCourses = useDeferredValue([...data, ...data]);

  return (
    <Splide
      className={classes.carousel}
      extensions={{ Intersection }}
      options={{
        arrows: false,
        pagination: false,
        rewind: true,
        gap: "1.5rem",
        type: "loop",
        autoplay: "pause",
        interval: 3000,
        fixedWidth: "18.75rem",
        omitEnd: true,
        intersection: {
          inView: {
            autoplay: true,
          },
          outView: {
            autoplay: false,
          },
        },
      }}
      ref={splideRef}
      aria-label="Our Courses"
    >
      {deferredCourses.map((course, index) => (
        <SplideSlide key={index}>
          <CourseCard course={course} />
        </SplideSlide>
      ))}
    </Splide>
  );
}
