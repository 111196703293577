import { animateScroll } from "react-scroll";

export const scrollToSection = (e, id, header) => {
  e.preventDefault();
  const el = document.getElementById(id);
  if (Math.floor(el.getBoundingClientRect().y - header) === 0) return;

  animateScroll.scrollTo(
    el.getBoundingClientRect().y + window.scrollY - header,
    {
      duration: 500,
    }
  );
};
