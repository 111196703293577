import { useDeferredValue } from "react";
import Slide from "./Slide";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Intersection } from "@splidejs/splide-extension-intersection";
import "@splidejs/react-splide/css";
import classes from "./index.module.scss";

export default function Carousel({ splideRef, data }) {
  const deferredYouTubeVideos = useDeferredValue(data);

  return (
    <Splide
      className={classes.carousel}
      extensions={{ Intersection }}
      options={{
        arrows: false,
        pagination: false,
        rewind: true,
        gap: "0.75rem",
        type: "loop",
        autoplay: "pause",
        interval: 3000,
        mediaQuery: "min",
        breakpoints: {
          561: {
            fixedWidth: "28rem",
          },         
        },
        fixedWidth: "20rem",
        omitEnd: true,
        intersection: {
          inView: {
            autoplay: true,
          },
          outView: {
            autoplay: false,
          },
        },
      }}
      ref={splideRef}
      aria-label="Our Alumni Feedbacks"
    >
      {deferredYouTubeVideos.map((slide) => (
        <SplideSlide key={slide.id}>
          <Slide slide={slide} />
        </SplideSlide>
      ))}
    </Splide>
  );
}
