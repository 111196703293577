import { Box, Container, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactComponent as Logo1 } from "./images/logo-univer.svg";
import { ReactComponent as Logo2 } from "./images/logo-rebase.svg";
import image from "./images/interview.png";
import { AccentButton } from "../../../../components/MUI/buttons/button";
import { mixpanelLandingCrushingInterview } from "../../../../constants/mixpanel";
import useMixpanel from "../../../../hooks/useMixpanel";

import classes from "./index.module.scss";

export default function CrushingInterview() {
  const { handleEventTrack } = useMixpanel();
  const { crushInterviewAnchor } = mixpanelLandingCrushingInterview;

  return (
    <Container maxWidth="lg">
      <Box className={classes.body}>
        <Box className={classes.content}>
          <Typography component={"h2"} variant={"h2"} className={classes.title}>
            🤝 Crushing Interview
          </Typography>

          <Box className={classes.content__image}>
            <img src={image} alt="Crushing Interview" width={684} />
          </Box>

          <Typography component={"h3"} className={classes.subtitle}>
            Mock Interviews for Software Engineers & SDET:
          </Typography>

          <Box component={"ul"} className={classes.list}>
            <Typography component={"li"} className={classes.list__item}>
              Let engineering managers and engineers from top tech companies
              help to improve your interviewing skills
            </Typography>
            <Typography component={"li"} className={classes.list__item}>
              4 sessions of a group mock interviews with the hiring engineering
              managers
            </Typography>
            <Typography component={"li"} className={classes.list__item}>
              8 sessions of a group of mock interviews with engineers from top
              tech companies
            </Typography>
            <Typography component={"li"} className={classes.list__item}>
              Sessions with Ex-Google Engineering Manager, and software
              engineers from big tech
            </Typography>
          </Box>
          <Box className={classes.actions}>
            <AccentButton
              className={classes.actions__crush}
              endIcon={<ChevronRightIcon />}
              variant={"contained"}
              component={"a"}
              isBig={false}
              href={process.env.REACT_APP_CRUSHING_INTERVIEW_LINK}
              target="_blank"
              onClick={(e) => {
                handleEventTrack(e, crushInterviewAnchor);
              }}
            >
              Crush interview
            </AccentButton>
            <Box className={classes.actions__images}>
              <Logo1 />
              <span></span>
              <Logo2 />
            </Box>
          </Box>
        </Box>
        <Box className={classes.image}>
          <img src={image} alt="Crushing Interview" width={684} />
        </Box>
      </Box>
    </Container>
  );
}
