import { Box, Typography } from "@mui/material";
import { Accordion, Summary, Details } from "../Accordion/FAQAccordion";
import Header from "./Header";
import Content from "./Content";
import useAccordion from "../../../../hooks/useAccordion";
import classes from "./index.module.scss";

export default function FAQ({ faq }) {
  const { expanded, handleChange } = useAccordion();
  if (!faq || !faq.length) {
    return null
  }
  return (
    <Box className={classes.faq}>
      <Typography component="h2" variant="h2" className={classes.title}>
        FAQ
      </Typography>
      <Box className={classes.wrapper}>
        {faq.map((item, index) => (
          <Accordion
            expanded={expanded}
            handleChange={handleChange}
            index={index}
            key={item.id}
          >
            <Summary index={index}>
              <Header
                title={item.title}
                question={item.question}
              />
            </Summary>
            <Details>
              <Content answer={item.answer} />
            </Details>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
}
