import { Box, Typography } from "@mui/material";
import { ReactComponent as Icon2 } from "./images/amazon.svg";
import { ReactComponent as Icon1 } from "./images/apple.svg";
import { ReactComponent as Icon4 } from "./images/facebook.svg";
import { ReactComponent as Icon5 } from "./images/google.svg";
import { ReactComponent as Icon6 } from "./images/grammarly.svg";
import { ReactComponent as Icon3 } from "./images/microsoft.svg";
import classes from "./index.module.scss";

export default function Companies() {
  return (
    <Box component={"section"} className={classes.body}>
      <Typography component={"h3"} className={classes.title}>
        Our alumni work at:
      </Typography>
      <Box component={"ul"} className={classes.list}>
        <Box component={"li"} className={classes.list__item}>
          <Icon1 />
        </Box>
        <Box component={"li"} className={classes.list__item}>
          <Icon2 />
        </Box>
        <Box component={"li"} className={classes.list__item}>
          <Icon3 />
        </Box>
        <Box component={"li"} className={classes.list__item}>
          <Icon4 />
        </Box>
        <Box component={"li"} className={classes.list__item}>
          <Icon5 />
        </Box>
        <Box component={"li"} className={classes.list__item}>
          <Icon6 />
        </Box>
      </Box>
    </Box>
  );
}
