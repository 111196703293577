import React from "react";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../../instances/axiosInstances";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Common/Loader";
import { Container } from "@mui/material";
import { getYoutubePlaylistVideos } from "../../../services/youtube";
import BuyCourseModal from "../../../components/modal/BuyCourseModal";
import Details from "./Details";
import { LandingLayout, PlatformLayout } from "../../../components/Layouts";

import classes from "./index.module.scss";

export default function CourseDetails({ isAuth, isAuthLoading }) {
  const { id } = useParams();

  const { data, isLoading } = useQuery(
    isAuth ? ["course", "registered", id] : ["course", "unregistered", id],
    () =>
      api.get(
        `${
          isAuth
            ? process.env.REACT_APP_SINGLE_COURSE_PATH
            : process.env.REACT_APP_SINGLE_COURSE_UNREGISTERED_PATH
        }/${id}`
      ),
    {
      enabled: !isAuthLoading,
      staleTime: 0,
    }
  );

  const { data: playlist } = useQuery(
    ["playlist", data?.data.courses.relatedMaterialsPlaylistUrl],
    () =>
      getYoutubePlaylistVideos(
        data?.data.courses.relatedMaterialsPlaylistUrl,
        process.env.REACT_APP_YOUTUBE_API_KEY
      ),
    {
      enabled:
        !isAuthLoading &&
        !isLoading &&
        !!data?.data.courses.relatedMaterialsPlaylistUrl,
    }
  );

  return (
    <>
      {isAuth ? (
        <PlatformLayout>
          {isLoading ? <Loader /> : null}
          {data?.data.courses ? (
            <Details data={data.data.courses} playlist={playlist} />
          ) : null}
        </PlatformLayout>
      ) : (
        <LandingLayout>
          <Container
            className={classes.wrapper}
            maxWidth={false}
            disableGutters
          >
            {isLoading ? <Loader /> : null}
            <Container className={classes.container} maxWidth="lg">
              {data?.data.courses ? (
                <Details data={data.data.courses} playlist={playlist} />
              ) : null}
            </Container>
          </Container>
        </LandingLayout>
      )}
      <BuyCourseModal course={data?.data?.courses} />
    </>
  );
}
