import { Box } from "@mui/system";
import { useContext, useDeferredValue } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactComponent as RobotDesktop } from "../../../../assets/images/robot-desktop.svg";
import { AuthContext } from "../../../../context/AuthContext";
import { Link } from "react-router-dom";
import { scrollToSection } from "../../../../helpers/scrollToSection";
import { Container, Typography } from "@mui/material";
import { AccentButton } from "../../../../components/MUI/buttons/button";
import { HEADER_HEIGHT } from "../../../../constants/layout";
import { mixpanelLandingWhyJoinUs } from "../../../../constants/mixpanel";
import useMixpanel from "../../../../hooks/useMixpanel";
import classes from "./index.module.scss";

export default function WhyEngeniousUniversity() {
  const { auth } = useContext(AuthContext);

  const { handleEventTrack } = useMixpanel();
  const { startForFreeLink, startForFreeAnchor } = mixpanelLandingWhyJoinUs;

  const deferredTimeline = useDeferredValue([
    {
      title: "Learn by doing:",
      text: "Our coding bootcamp emphasizes a practical, hands-on approach that gets you coding from day one. Say goodbye to boring lectures and hello to active learning",
    },
    {
      title: "Real-world projects:",
      text: "Work on actual coding projects that simulate real-world challenges. You'll gain experience with the tools and techniques used by professional developers",
    },
    {
      title: "Expert instructors:",
      text: "Our instructors are practicing coders who work full-time in the industry. They bring real-world experience and practical knowledge to the classroom",
    },
    {
      title: "Vibrant community:",
      text: "Join our Discord community to connect with fellow students, instructors, and industry professionals. Get answers to your coding questions in real-time and share your experiences with others",
    },
    {
      title: "Internship opportunities:",
      text: "We offer the chance to participate in internships where you can apply your skills in a real-world setting. Build your resume and gain valuable experience that can help you launch your coding career",
    },
  ]);

  return (
    <Container maxWidth={"xl"}>
      <Box className={classes.body} component={"section"}>
        <Typography variant={"h2"} component={"h2"} className={classes.title}>
          📌 Why join us{" "}
        </Typography>
        <Box className={classes.content}>
          <Box component={"ul"} className={classes.list}>
            {deferredTimeline.map((item, index) => (
              <Box component={"li"} className={classes.item} key={index}>
                <Typography component={"h3"} className={classes.item__title}>
                  {item.title}
                </Typography>
                <Typography component={"h3"} className={classes.item__text}>
                  {item.text}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box className={classes.actions}>
            {auth ? (
              <AccentButton
                variant="contained"
                endIcon={<ChevronRightIcon />}
                component={Link}
                to="/courses"
                onClick={(e) => handleEventTrack(e, startForFreeLink)}
              >
                Start for Free
              </AccentButton>
            ) : (
              <AccentButton
                variant="contained"
                color="primary"
                endIcon={<ChevronRightIcon />}
                component={"a"}
                href={`#courses`}
                onClick={(e) => {
                  handleEventTrack(e, startForFreeAnchor)
                  scrollToSection(e, "courses", HEADER_HEIGHT);
                }}
              >
                Start for Free
              </AccentButton>
            )}
          </Box>
        </Box>
        <Box className={classes.image}>
          <RobotDesktop className={classes.robotDesktop} />
        </Box>
      </Box>
    </Container>
  );
}
