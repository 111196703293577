import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTheme } from "@emotion/react";
import { PlayButton } from "../../../../components/MUI/buttons";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import VideoModal from "../../../../components/Common/EmbedVideos/VideoWrapper";
import React, { useContext, useEffect } from "react";
import { ModalContext } from "../../../../context/ModalContext";
import { AuthContext } from "../../../../context/AuthContext";
import Chip from "./Chip";
import { formatDateTime } from "../../../../helpers/formatDateTime";
import { calculateDateDifferenceInMilliseconds } from "../../../../helpers/calculateDateDifferenceInMilliseconds";

import icon1 from "../../../../assets/images/zondicons_time.svg";
import icon2 from "../../../../assets/images/mdi_timer-sand-complete.svg";
import icon3 from "../../../../assets/images/carbon_user-speaker.svg";
import { ReactComponent as DiscordIcon } from "../../../../assets/images/ic_baseline-discord.svg";
import { ReactComponent as LinkedinIcon } from "../../../../assets/images/devicon_linkedin.svg";
import classes from "./index.module.scss";
import { AccentButton } from "../../../../components/MUI/buttons/button";
import { Link, useLocation } from "react-router-dom";
import useActivateFreeCourse from "../../../../hooks/useMutations";
import CouponDetails from "../../../../components/modal/BuyCourseModal/CouponDetails";
import { formatTimeMilliseconds } from "../../../../helpers/formatTime";

export default function CourseCardLive({ data }) {
  const theme = useTheme();
  const { auth, setIsPayment, setIsFreePayment, isFreePayment } =
    React.useContext(AuthContext);
  const { pathname } = useLocation();
  const { handleModalOpen } = useContext(ModalContext);
  const localstorageFreePayment = localStorage.getItem("isFreePayment");

  const {
    mutate: activateFreeCourseMutation,
    isLoading: activateFreeCourseLoading,
  } = useActivateFreeCourse(data.id);

  const showVideoModal = ({ title, url }) => {
    handleModalOpen(<VideoModal title={title} url={url} />);
  };

  const handleActivateFreeCourse = (id) => {
    let body = JSON.stringify({
      courseId: id,
    });

    activateFreeCourseMutation(body);
  };

  useEffect(() => {
    !activateFreeCourseLoading &&
      auth &&
      !!data &&
      !data.isPaid &&
      Number(data.price) === 0 &&
      (isFreePayment || localstorageFreePayment) &&
      handleActivateFreeCourse(data.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth,
    data,
    isFreePayment,
    activateFreeCourseLoading,
    localstorageFreePayment,
  ]);

  if (!data) {
    return null;
  }

  const startDate = formatDateTime(data.liveSchedule[0].from);
  const lectionsCount = data.liveSchedule.length;
  const durationInMilliseconds = data.liveSchedule.reduce((acc, lecture) => {
    const diff =
      new Date(lecture.to).getTime() - new Date(lecture.from).getTime();
    return acc + diff;
  }, 0);
  const lecturesDuration = formatTimeMilliseconds(durationInMilliseconds);

  return (
    <Box className={classes.container}>
      <Card className={classes.card}>
        <Box className={classes.card__wrapper}>
          <CardHeader className={classes.card__header} avatar={<Chip />} />
          <CardContent className={classes.card__content}>
            {data?.paths && data?.paths.length ? (
              <Typography
                className={classes.card__subtitle}
                color={theme.palette["accent-06"]}
              >
                Part of{" "}
                {data?.paths.map((path, index, self) => (
                  <React.Fragment key={path.id}>
                    <span>{path.title} Path</span>
                    {index + 1 !== self.length ? `, ` : ""}
                  </React.Fragment>
                ))}
              </Typography>
            ) : null}
            {data.title ? (
              <Typography
                component="h1"
                variant="h1"
                color={theme.palette.dark}
                className={classes.card__title}
              >
                {data.title}
              </Typography>
            ) : null}
            {data.shortDescription ? (
              <Typography
                color={theme.palette["dark-06"]}
                className={classes.card__description}
              >
                {data.shortDescription}
              </Typography>
            ) : null}
            <Box className={classes.info}>
              <Typography className={classes.info__text}>
                <img src={icon1} alt="Start date" width={16} /> Start date:{" "}
                {startDate.formattedDate + ", " + startDate.time}
              </Typography>
              <Typography className={classes.info__text}>
                <img src={icon2} alt="Duration" width={16} /> Duration:{" "}
                {lectionsCount} {lectionsCount === 1 ? "lecture" : "lectures"} (
                {lecturesDuration})
              </Typography>

              <Typography className={classes.info__text}>
                <img src={icon3} alt="Instructors" width={16} /> Instructors:{" "}
                {data.instructors
                  ? data.instructors.map((instructor, index, self) => {
                      return (
                        <React.Fragment key={instructor.id}>
                          {instructor?.linkedinLink ? (
                            <a
                              href={instructor.linkedinLink}
                              target="_blank"
                              rel="noreferrer"
                              className={classes.info__link}
                            >
                              <LinkedinIcon />
                              {instructor.firstName + " " + instructor.lastName}
                            </a>
                          ) : (
                            instructor.firstName + " " + instructor.lastName
                          )}
                          {index === self.length - 1 ? null : ", "}
                        </React.Fragment>
                      );
                    })
                  : "No information"}
              </Typography>
            </Box>
          </CardContent>
          {Number(data?.price) !== 0 &&
          data?.discountedPrice &&
          data?.coupon ? (
            <Box className={classes.coupon} sx={{ marginBottom: "1rem" }}>
              <CouponDetails
                coupon={data?.coupon}
                discountedPrice={data?.discountedPrice}
                defaultPrice={data?.price}
                isPriceVisible={true}
              />
            </Box>
          ) : null}
          <Typography className={classes.support}>
            <DiscordIcon />
            Free support in Discord included in the course price
          </Typography>
          <CardActions className={classes.card__actions}>
            {!data.isPaid && Number(data.price) === 0 ? (
              <>
                {auth ? (
                  <AccentButton
                    isBig={false}
                    variant="contained"
                    endIcon={<ChevronRightIcon />}
                    onClick={() => {
                      setIsFreePayment(true);
                      localStorage.setItem("isFreePayment", true);
                    }}
                    disabled={activateFreeCourseLoading}
                  >
                    Free Registration
                  </AccentButton>
                ) : (
                  <AccentButton
                    isBig={false}
                    variant="contained"
                    endIcon={<ChevronRightIcon />}
                    component={Link}
                    to={"/registration"}
                    onClick={() => {
                      localStorage.setItem("redirectPath", pathname);
                      localStorage.setItem("isFreePayment", true);
                      setIsFreePayment(true);
                    }}
                    disabled={activateFreeCourseLoading}
                  >
                    Free Registration
                  </AccentButton>
                )}
              </>
            ) : null}
            {!data.isPaid && Number(data?.price) ? (
              <>
                {auth ? (
                  <AccentButton
                    isBig={false}
                    variant={"contained"}
                    endIcon={<ChevronRightIcon />}
                    onClick={() => {
                      localStorage.setItem("isPayment", true);
                      setIsPayment(true);
                    }}
                    disabled={activateFreeCourseLoading}
                  >
                    Buy for $
                    {data?.discountedPrice ? data?.discountedPrice : data.price}
                  </AccentButton>
                ) : (
                  <AccentButton
                    isBig={false}
                    variant="contained"
                    endIcon={<ChevronRightIcon />}
                    component={Link}
                    to={"/registration"}
                    onClick={() => {
                      localStorage.setItem("redirectPath", pathname);
                      localStorage.setItem("isPayment", true);
                      setIsPayment(true);
                    }}
                    disabled={activateFreeCourseLoading}
                  >
                    Buy for $
                    {data?.discountedPrice ? data?.discountedPrice : data.price}
                  </AccentButton>
                )}
              </>
            ) : null}

            {auth && data?.isPaid ? (
              <>
                <AccentButton
                  isBig={false}
                  variant={"contained"}
                  endIcon={<ChevronRightIcon />}
                  target="_blank"
                  component={"a"}
                  href={data?.zoomLink}
                >
                  Online lectures
                </AccentButton>
              </>
            ) : null}
          </CardActions>
        </Box>
        <Box className={classes.mediaWrapper}>
          <CardMedia className={classes.card__media}>
            <img src={data.promoImageUrl} alt={data.title} width="496" />
            {data.promoVideoUrl ? (
              <PlayButton
                aria-label="play"
                onClick={() =>
                  showVideoModal({
                    title: data.title,
                    url: data.promoVideoUrl,
                  })
                }
              >
                <PlayArrowIcon />
              </PlayButton>
            ) : null}
          </CardMedia>
        </Box>
      </Card>
    </Box>
  );
}
